import { Component, inject } from '@angular/core';
import { FilterService } from '../../filters/filters.service';
import { ConfigService } from '../../services/config.service';
import { PopupService } from '../../services/popup.service';
import { UserModel } from '../../../model/user.model';

@Component({
  selector: 'sem-current-filters-displayer',
  templateUrl: './current-filters-displayer.component.html',
  styleUrls: ['./current-filters-displayer.component.scss'],
})
export class CurrentFiltersDisplayerComponent {
  public configService = inject(ConfigService);
  protected contributorsList: UserModel[] = [];
  private filterService = inject(FilterService);
  currentFilters$ = this.filterService.filters$;
  private popupService = inject(PopupService);

  openFilterPopup() {
    if (!this.configService.config.tools.filter) {
      return;
    }
    this.popupService.openFilterManagerPopup().subscribe();
  }

  clear() {
    this.filterService.resetFilters();
  }
}
