import { sumBy } from 'lodash';
import { ItemModel } from '../item.model';

export interface AgregationInterface {
  calculate(items: ItemModel[], column: string): number;
}

export class AgregationSum {
  calculate(items: ItemModel[], column: string, fractionDigits: number = 2): number {
    return parseFloat(sumBy(items, (item) => parseFloat(item[column as keyof typeof item] as string)).toFixed(fractionDigits));
  }
}

export class AgregationAVG {
  calculate(items: ItemModel[], column: string): number {
    let sum = 0;
    items.forEach((item) => (sum += item[column as keyof typeof item] as number));
    return Math.round((sum / items.length) * 100) / 100;
  }
}

export interface AgregationDataModel {
  [key: string]: number;
}
