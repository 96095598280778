import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartLineChooserForm } from '../chart-line-chooser/chart-line-chooser-form';
import { ChartMapper } from './chart-mapper';

export type ChartAggregation = 'daily' | 'weekly' | 'monthly';

export enum ChartIndicators {
  CLICKS = 'clicks',
  AVERAGE_CPC = 'average_cpc',
  IMPRESSIONS = 'impressions',
  CPA = 'cpa',
  SALES = 'sales',
  ROAS = 'roas',
  CONVERSIONS = 'conversions',
  CONVERSION_RATE = 'conversion_rate',
  CTR = 'ctr',
  COST = 'cost',
}

export enum ChartType {
  msline = 'msline',
  multiaxisline = 'multiaxisline',
  msarea = 'msarea',
  msbar2d = 'msbar2d',
  mscombidy2d = 'mscombidy2d',
  timeseries = 'stackedcolumn2d',
  line = 'line',
  area = 'area',
  donut = 'donut',
  bar = 'bar',
  column = 'column',
}

export const SEM_CHART_CONFIG = new InjectionToken<SemChartConfig>('SemChartConfig');
export const SEM_CHART_MAPPER_DIRECTOR = new InjectionToken<SemChartDirector>('SEM_CHART_MAPPER_DIRECTOR');
export const SEM_CHART_Y_LINE_FORM = new InjectionToken<ChartLineChooserForm>('SEM_CHART_Y_LINE_FORM');
export const SEM_CHART_DATA_STREAM = new InjectionToken<BehaviorSubject<any>[]>('SEM_CHART_DATA_STREAM');

export interface SemChartConfig {
  width?: string;
  height?: string;
  seriesName?: string;
  yLine?: string[];
  title?: string;
  xAxisName?: string;
  theme: 'apex';
  uniqueId?: string;
  stacked?: boolean;
  staticIndicators?: string[];
  aggregation?: 'daily' | 'weekly' | 'monthly';
}

export const Y_LINES_COLORS = ['#2C6ECB', '#5FC696', '#E11E03', '#FFB800'];
export const DOUGHNUT_CHART_COLORS = ['#2C6ECB', '#5FC696', '#E11E03', '#FFB800'];

export interface SemChartDirector<TData = any, TOptions = any> {
  construct: () => ChartMapper<any>;
  generateChartOptions?: (dataSource: TData) => Partial<TOptions>;
  toggleStacking?: () => void;
}

export const defaultSemChartConfig: SemChartConfig = {
  yLine: [
    ChartIndicators.CLICKS,
    ChartIndicators.AVERAGE_CPC,
    ChartIndicators.IMPRESSIONS,
    ChartIndicators.CPA,
    ChartIndicators.SALES,
    ChartIndicators.ROAS,
  ],
  height: '350',
  width: '100%',
  theme: 'apex',
};
