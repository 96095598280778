<mat-form-field appearance="outline">
  <mat-label>{{ 'sem_table.find' | translate }}</mat-label>
  <input [(ngModel)]="action.value.from" matInput name="from" placeholder="{{ 'sem_table.find' | translate }}" required />
</mat-form-field>

<mat-form-field appearance="outline">
  <mat-label>{{ 'sem_table.replace' | translate }}</mat-label>
  <input [(ngModel)]="action.value.to" matInput name="to" placeholder="{{ 'sem_table.replace' | translate }}" />
</mat-form-field>
<mat-checkbox [(ngModel)]="action.value.caseSensitive" name="caseSensitive">{{ 'sem_table.case_sensitive' | translate }} </mat-checkbox>
<mat-checkbox [(ngModel)]="action.value.fullMatch" name="fullMatch">{{ 'sem_table.full_word_match' | translate }} </mat-checkbox>
