import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ProjectService } from '../../../../dashboard/project/project.service';
import { TasksService } from '../../../../dashboard/tasks/tasks.service';
import { UserModel } from '../../../model/user.model';
import { appearAnimation } from '../../animation/tool';
import { AutocompleteEventModel } from '../../models/autocompleteEvent.model';
import { ColumnTypesEnum, ColumnValueTypesEnum } from '../../table-display/ColumnTypesEnum';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';
import { SelectColumn } from '../../table-display/columns-switch/columns/select-column/SelectColumn';
import { FilterTypesEnum } from '../FilterTypesEnum';

@Component({
  selector: 'sem-filter-form-switch',
  templateUrl: './filter-form-switch.component.html',
  styleUrls: ['./filter-form-switch.component.scss'],
  animations: [appearAnimation],
})
export class FilterFormSwitchComponent {
  @Input() filterType!: FilterTypesEnum;
  @Input() column!: ColumnModel;
  @Input() selectColumn!: SelectColumn;
  @Input() valueCtrl!: UntypedFormControl;
  @Output() autocompleteChanged = new EventEmitter<AutocompleteEventModel>();
  types = FilterTypesEnum;
  columnValueTypes = ColumnValueTypesEnum;
  columnTypes = ColumnTypesEnum;

  selectedUsers: UserModel[] = [];
  readonly users$ = this.tasksService.getContributors(null, this.projectService.activeProject$.getValue()!.id);

  constructor(
    private tasksService: TasksService,
    private projectService: ProjectService,
  ) {}

  get dataCtrl(): UntypedFormControl {
    return this.valueCtrl.get('data') as UntypedFormControl;
  }

  get configCtrl(): UntypedFormControl {
    return this.valueCtrl.get('config') as UntypedFormControl;
  }

  setValueInFilter(value: any) {
    this.dataCtrl.setValue(value);
  }

  setDateValue(date: { value: Date[] }) {
    this.dataCtrl.setValue(date.value);
  }

  autocompleteEmitChange(changedEvent: AutocompleteEventModel) {
    this.autocompleteChanged.emit(changedEvent);
  }

  selectUser(event: { users: UserModel[]; isChanged: boolean }) {
    this.selectedUsers = event.users;

    const selectedUserIds = this.selectedUsers.map((user) => user.id);
    this.setValueInFilter(selectedUserIds);
  }
}
