import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnModel } from '../sem-table/table-display/columns-switch/columns/column.model';

@Pipe({
  name: 'mapColumnValue',
})
export class MapColumnValuePipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(value: any, column: ColumnModel): any {
    let val = column.map(value);

    if (column.isValueToTranslate) {
      val = this.translateService.instant(val);
    }

    return val;
  }
}
