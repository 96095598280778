<div *ngIf="allLeftTabs[0] || rightTabs[0]" class="container">
  <div *ngFor="let tab of leftTabs" [ngClass]="{ active: tab.active }" class="tab">
    <span (click)="useTab(tab)">{{ tab.name | titlecase }}</span>
  </div>
  <div *ngIf="rightTabs[0]" class="divider"></div>
  <div *ngFor="let tab of rightTabs" [ngClass]="{ active: tab.active }" class="tab">
    <span (click)="useTab(tab)">{{ tab.name | titlecase }}</span>
  </div>
  <button *ngIf="allLeftTabs[0]" [matMenuTriggerFor]="tabsMenu" mat-button>
    <mat-icon>add</mat-icon>
  </button>
</div>

<mat-menu #tabsMenu="matMenu" xPosition="after">
  <ng-template matMenuContent>
    <ng-container *ngTemplateOutlet="menuItems; context: { tabs: allLeftTabs }"></ng-container>
    <ng-container *ngTemplateOutlet="menuItems; context: { tabs: rightTabs }"></ng-container>
  </ng-template>
</mat-menu>

<ng-template #menuItems let-tabs="tabs">
  <div *ngFor="let tab of tabs" class="menu-item-wrapper">
    <button (click)="changeTabState(tab)" [ngClass]="{ active: tab.visible }" mat-menu-item>
      {{ tab.name }}
    </button>
    <button (click)="confirmDelete(tab)" *ngIf="!isDefaultTab(tab.name)" mat-icon-button>
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</ng-template>
