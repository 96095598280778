<div class="container">
  @if (!isLoading) {
    <mat-card appearance="outlined" class="card">
      @if (isNoPermission) {
        <mat-card-header>
          <mat-card-title>{{ 'payments.plan.no_permission.title' | translate }}</mat-card-title>
          <mat-card-subtitle>{{ 'payments.plan.no_permission.description' | translate }}</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="change">
          <button (click)="changeAccount()" class="btn" color="primary" mat-raised-button>
            {{ 'payments.plan.no_permission.btn_txt' | translate }}
          </button>
        </mat-card-content>
      } @else {
        <mat-card-header>
          <mat-card-title class="title-container">
            <h1 class="plan-title">{{ 'payments.plan.title' | translate }}</h1>
            <div class="country-box">
              <div class="country-selector">
                <mat-form-field class="full-width" [ngClass]="{ 'visibility-hidden': isAnonymous || !isCountryMissing }">
                  <mat-label>{{ 'payments.plan.country' | translate }}</mat-label>
                  <mat-select (selectionChange)="onCountryChange($event.value)" [(value)]="selectedCountry">
                    @for (country of countries; track $index) {
                      <mat-option [value]="country.code">
                        {{ country.name }}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              @if (isAnonymous) {
                <app-currency-chooser
                  (currencyChange)="currencyChange($event)"
                  [(currency)]="currency"
                  class="currency-chooser"
                ></app-currency-chooser>
              }
            </div>
          </mat-card-title>
          <mat-card-subtitle class="subtitle">
            @if (plan.support) {
              <span>{{ 'payments.plan.subtitle' | translate }}</span>
            }
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="support-container">
          <div class="plan-content">
            <div class="restrictions">
              @if (selectedSupportProduct) {
                <div class="support">
                  <div class="boxes">
                    <app-box
                      (selected)="selectSupport($event)"
                      (showCss)="setShowCss()"
                      (showLimits)="setShowLimits()"
                      [basicPlan]="plan.basic ?? null"
                      [currency]="plan.currency"
                      [isSelected]="selectedSupportProduct && selectedSupportProduct.key === paymentPlanSupportEnum.free"
                      [isTrial]="isTrialAvailable"
                      [key]="paymentPlanSupportEnum.free"
                      [showSub]="plan.support ? true : false"
                      class="box"
                    ></app-box>
                    @if (plan.support && plan.currency === CurrencyEnum.pln) {
                      <app-box
                        (selected)="selectSupport($event)"
                        [basicPlan]="plan.basic ?? null"
                        [currency]="plan.currency"
                        [data]="plan.support.active_service"
                        [isSelected]="selectedSupportProduct && selectedSupportProduct.key === paymentPlanSupportEnum.activeService"
                        [isTrial]="isTrialAvailable"
                        [key]="paymentPlanSupportEnum.activeService"
                        class="box"
                      ></app-box>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </mat-card-content>
      }
    </mat-card>
  } @else {
    <div class="spinner-wrapper">
      <mat-spinner [diameter]="40" mode="indeterminate"></mat-spinner>
    </div>
  }

  @if (!isLoading && (!isNoPermission || isAnonymous) && plan.sembot && showLimits) {
    <mat-card appearance="outlined" id="limits">
      <mat-card-content class="limits-container">
        <div class="plan-content">
          <div class="restrictions">
            <div class="limits">
              <h2>{{ 'payments.plan.limit.title' | translate }}</h2>
              <p class="desc">{{ 'payments.plan.limit.description' | translate }}</p>

              @for (key of sembotOrder; track key) {
                <div class="slider">
                  @if (getFormControl(key)) {
                    <div class="label">{{ 'payments.plan.limit.items.' + key + '.label' | translate }}:</div>

                    <div class="value">
                      <mat-slider [max]="sembotConfig[key].max" [min]="sembotConfig[key].min" [step]="sembotConfig[key].step" discrete>
                        <input (valueChange)="getFormControl(key).setValue($event)" [value]="getFormControl(key).value" matSliderThumb />
                      </mat-slider>

                      <div class="field">
                        <mat-icon (click)="subtractOneStep(key)">do_not_disturb_on</mat-icon>
                        <mat-form-field appearance="outline" subscriptSizing="dynamic">
                          <input [formControl]="getFormControl(key)" matInput maxlength="6" />
                          <app-form-field-error-display matError></app-form-field-error-display>
                        </mat-form-field>
                        <mat-icon (click)="addOneStep(key)">add_circle</mat-icon>
                      </div>
                    </div>
                  }
                </div>
              }

              <a (click)="setDefaultLimits()" class="underlined-btn">{{ 'payments.plan.set_defaults' | translate }}</a>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  }

  @if (!isLoading && (!isNoPermission || isAnonymous) && selectedCssProduct && getFormControl(paymentPlanCssEnum.whitelabel).value !== 0) {
    <mat-card appearance="outlined" id="css">
      <mat-card-content class="limits-container">
        <div class="plan-content">
          <div class="restrictions">
            @if (selectedCssProduct) {
              <div class="limits">
                <span class="css-title">{{ 'payments.plan.css.title' | translate }}</span>
                <p class="desc">{{ 'payments.plan.css.description' | translate }}</p>
                <div class="tw-flex tw-justify-between tw-mt-4">
                  <div class="price">
                    <div class="price-description">
                      <p class="!tw-mb-4">
                        {{ 'payments.plan.support.items.free.price_description' | translate }}
                      </p>
                      <span class="price-info"
                        >{{ 'general.from' | translate }}
                        <span class="css-price">{{
                          selectedCssWhitelabelPackage && selectedCssWhitelabelPackage!.price
                            ? selectedCssWhitelabelPackage!.price / 100
                            : getWhitelabelCssProductByValue(1)?.price! / 100
                        }}</span>
                        <span class="currency"
                          >{{ 'payments.plan.currencies.' + plan.currency | translate }} /{{ 'payments.plan.month' | translate }}</span
                        >
                      </span>
                    </div>
                    @if (selectedCssProduct && selectedCssWhitelabelPackage?.setup) {
                      <div class="price-description extra">
                        <p class="!tw-mb-4">
                          {{ 'payments.plan.support.items.active_service.start_fee' | translate }}
                          <mat-icon [matTooltip]="'payments.plan.support.items.active_service.tooltip' | translate"> info </mat-icon>
                        </p>
                        <span class="price-info"
                          ><span class="css-price"
                            >+{{
                              selectedCssWhitelabelPackage?.setup?.price
                                ? selectedCssWhitelabelPackage?.setup?.price! / 100
                                : getWhitelabelCssProductByValue(1)?.setup?.price! / 100
                            }}</span
                          >
                          <span class="currency">{{ 'payments.plan.currencies.' + plan.currency | translate }}</span></span
                        >
                      </div>
                    }
                  </div>
                </div>
                @if (getFormControl(paymentPlanCssEnum.whitelabel).value !== 0) {
                  <div>
                    <div class="slider">
                      @if (getFormControl(paymentPlanCssEnum.whitelabel)) {
                        <div>
                          <div class="label">{{ 'payments.plan.css.markets' | translate }}:</div>

                          <div class="value">
                            <mat-slider
                              [displayWith]="formatLabel.bind(this)"
                              [max]="cssConfig.max"
                              [min]="cssConfig.min"
                              [step]="cssConfig.step"
                              discrete
                            >
                              <input
                                (valueChange)="getFormControl(paymentPlanCssEnum.whitelabel).setValue($event)"
                                [value]="getFormControl(paymentPlanCssEnum.whitelabel).value"
                                matSliderThumb
                              />
                            </mat-slider>

                            <div class="field">
                              <mat-icon (click)="subtractOneStep(paymentPlanCssEnum.whitelabel, true)"> do_not_disturb_on </mat-icon>
                              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                                <input
                                  (input)="valueChange($event)"
                                  [formControl]="getFormControl(paymentPlanCssEnum.whitelabel)"
                                  [value]="formatLabel(getFormControl(paymentPlanCssEnum.whitelabel).value)"
                                  matInput
                                />
                              </mat-form-field>
                              <mat-icon (click)="addOneStep(paymentPlanCssEnum.whitelabel, true)">add_circle</mat-icon>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <a (click)="deleteCss()" class="underlined-btn">{{ 'payments.plan.css.delete' | translate }}</a>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  }

  @if (!isLoading && (!isNoPermission || isAnonymous)) {
    <div class="summary">
      @if (price) {
        <app-summary (buy)="buy()" [isLoadingPlan]="isLoadingPlan" [isTrial]="isTrialAvailable" [price]="price"></app-summary>
      }
    </div>
  }
</div>
