<div *ngIf="filteredDisplayedColumns$ | async as displayedColumns" class="container">
  <table class="mat-table">
    <tr class="header">
      @if (config.tools.expandable) {
        <th class="expandable-cell"></th>
      }
      <th
        #resizableCell
        *ngFor="let columnName of displayedColumns; let i = index"
        [class]="columnName + 'Cell'"
        [ngClass]="{
          'inner-cell': columnName === innerColumnName,
          'navigation-cell': config.columns[columnName]._isNavigationColumn,
        }"
      >
        <sem-headers-displayer
          [column]="config.columns[columnName]"
          [config]="config"
          [index]="i"
          [tableCells]="tableCells"
        ></sem-headers-displayer>
      </th>

      <th *ngIf="config.actionColumn">
        <sem-headers-displayer [column]="actionColumn" [index]="displayedColumns.length + 1"></sem-headers-displayer>
      </th>
    </tr>

    <ng-container [ngTemplateOutlet]="newItemRow" [ngTemplateOutletContext]="{ config, isNewItemRowVisible }"></ng-container>

    <ng-template #rowTemplate let-item let-isChild="isChild">
      <tr
        #tableRows
        [id]="item.id ? rowIdPrefix + item.id : ''"
        [matTooltipDisabled]="!$any(item)[config.inactiveStateDependencyColumn!]"
        [matTooltip]="'sem_table.inactive_state' | translate"
        [ngClass]="{ 'is-child': isChild }"
        matTooltipClass="tooltip"
      >
        @if (config.tools.expandable) {
          <td class="expandable-column icon-button-density-minus-5" [ngStyle]="getStylesForItem(item)">
            @if (item | canExpand) {
              @let marginOffsetBase = 40;
              @let marginOffset = (item | getDepth) * marginOffsetBase;
              <div class="tw-flex tw-justify-center" [ngStyle]="{ 'margin-left': marginOffset + 'px' }">
                <button mat-icon-button (click)="expansionClick($event, item)">
                  <mat-icon>{{ (item | isExpanded | async) ? 'arrow_drop_down' : 'arrow_right' }}</mat-icon>
                </button>
              </div>
            }
          </td>
        }
        <td
          #cell
          *ngFor="let columnName of displayedColumns; let i = index"
          [class]="columnName + 'Cell' + (config.columns[columnName].styleClass ? ' ' + config.columns[columnName].styleClass : '')"
          [ngStyle]="getStylesForItem(item)"
          [ngClass]="{
            'wait-for-update': actionService.globalActions[0] || helperService.isInArray(item, (changesService.toUpdate$ | async)!),
            'inner-column': columnName === innerColumnName,
            'navigation-column': config.columns[columnName]._isNavigationColumn && !item?._isNotNavigationValue,
            inactive: $any(item)[config.inactiveStateDependencyColumn!],
          }"
        >
          <sem-columns-switch
            #columnsSwitch
            (actionPanelOpened)="columnsSwitch.openPanel(item)"
            (aiGeneratedClicked)="handleAiGeneratedClicked(item, columnName)"
            (customButtonClicked)="handleCustomButtonClicked($event, item, columnName)"
            (emitCustomEventOnColumn)="handleCustomEventOnColumn($event, item)"
            (hideColumnChange)="onHideColumnChange($event)"
            (infoCellClicked)="onCellInfoClick(item, columnName)"
            (infoItemClicked)="onItemInfoClick(item)"
            (initFilter)="filterByClick(item, columnName)"
            (navigationClicked)="handleNavigationClicked(item, columnName)"
            (nextColumn)="columnsMovingService.nextColumn(item, columnName, dataToDisplay!.data, this)"
            (nextRow)="columnsMovingService.nextRow(item, columnName, dataToDisplay!.data, this)"
            (openPhotoFromColumn)="openPhoto($event, item)"
            (prevColumn)="columnsMovingService.prevColumn(item, columnName, dataToDisplay!.data, this)"
            (prevRow)="columnsMovingService.prevRow(item, columnName, dataToDisplay!.data, this)"
            (selectWithShift)="selectWithShift(item)"
            (selected)="select(item)"
            (showSnackBar)="showSnackBar($event)"
            (unSelected)="unSelect(item)"
            (valueChanged)="catchChange($event, item, columnName)"
            [actionBtnDisplay]="config.tools.actionButtons! | toolActivenessManager: item"
            [checked]="(selectAllGlobal$ | async) || helperService.isInArray(item, (selectedItems$ | async)!)"
            [colorCell]="item | cellColor: columnName"
            [colorItem]="$any(item)[mainTableColorItem]"
            [column]="config.columns[columnName]"
            [config]="config"
            [customButtons]="config.columns[columnName] | returnColumnCustomButtons: item"
            [id]="item.id"
            [index]="i"
            [isEditable]="!config.readOnly && (config.columns[columnName].editable! | toolActivenessManager: item)"
            [isNavigationValue]="config.columns[columnName]._isNavigationColumn! && !item?._isNotNavigationValue"
            [item]="item"
            [labelCell]="item | cellLabel: columnName"
            [labelItem]="$any(item)[mainTableLabelItem]"
            [shouldCheckEmpty]="shouldCheckEmpty"
            [value]="$any(item)[columnName]"
          ></sem-columns-switch>
        </td>

        <td #cell #resizableCell *ngIf="config.actionColumn" class="action-cell">
          <sem-action-column [id]="item.id"></sem-action-column>
        </td>

        <ng-container *ngIf="item._isLoading">
          <div class="row-loading-layer">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template #loopTemplate let-data let-isChild="isChild">
      @for (item of data; track $index) {
        <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: item, isChild }"></ng-container>

        @if (config.tools.expandable) {
          @if (item | isExpanded | async) {
            @let expanded = item?._type !== 'system' ? ((item.id | getExpandedRows | async) ?? []) : [];

            <ng-container
              [ngTemplateOutlet]="newItemRow"
              [ngTemplateOutletContext]="{ config, isNewItemRowVisible, listing: expanded, parentId: item.id }"
            ></ng-container>

            <ng-container *ngTemplateOutlet="loopTemplate; context: { $implicit: expanded, isChild: true }"></ng-container>

            @for (template of expansionRowService?.expandedRowExtraTemplates(); track $index) {
              <tr>
                <td colspan="1000">
                  <ng-container *ngTemplateOutlet="template; context: { $implicit: item }"></ng-container>
                </td>
              </tr>
            }
          }
        }
      }
    </ng-template>
    <ng-container *ngTemplateOutlet="loopTemplate; context: { $implicit: dataToDisplay!.data }"></ng-container>

    <!--------------------           footer selected Aggregation               ---------------------->
    <tr *ngIf="config.tools.aggregationSelected && (selectedItems$ | async)![0]" class="footer-row">
      <ng-container *ngFor="let columnName of displayedColumns; let i = index; let firstColumn = first">
        <ng-container *ngIf="firstColumn; else footerAggregate">
          <td class="footerAgregationTitle">Select</td>
        </ng-container>

        <ng-template #footerAggregate>
          <td>
            <span
              *ngIf="
                config.columns[columnName] && config.columns[columnName].aggregateBySelect && (selectedItems$ | async)![0];
                else placeholderFooter
              "
            >
              {{ agregationService.selectedData[columnName] }}
            </span>
          </td>
        </ng-template>
      </ng-container>
    </tr>

    <!--------------------           footer total Aggregation               ---------------------->
    <tr *ngIf="config.tools.aggregationTotal" class="footer-row">
      <ng-container *ngFor="let columnName of displayedColumns; let i = index; let firstColumn = first">
        <td *ngIf="firstColumn; else footerAggregate" class="footerAgregationTitle">Total</td>

        <ng-template #footerAggregate>
          <td>
            <span *ngIf="agregationService.totalAgregationData[columnName] as value; else placeholderFooter">
              {{ value }}
            </span>
          </td>
        </ng-template>
      </ng-container>
    </tr>
  </table>
</div>

<ng-template #newItemRow let-config="config" let-isNewItemRowVisible="isNewItemRowVisible" let-listing="listing" let-parentId="parentId">
  @if (config && config.newItemRow) {
    @let columnName = config.newItemRow.columnName || null;
    @let isAddingByColumnName = !!(columnName && config.columns[columnName]);

    <tr class="new-row" [ngClass]="{ 'sub-row': parentId }">
      <td colspan="100" (click)="newItemRowClick(isAddingByColumnName, listing, parentId)">
        <div>
          <mat-icon class="material-symbols-outlined">add_circle</mat-icon>
          @if (isAddingByColumnName && isNewItemRowVisible) {
            <sem-columns-switch
              (appOutsideClick)="isNewItemRowVisible && setNewItemRowVisible(false)"
              (valueChanged)="newItemRowChange($event, columnName)"
              [column]="config.columns[columnName]"
              [config]="config"
              [isEditable]="true"
              [isNewItemRow]="true"
            ></sem-columns-switch>
          } @else {
            <span class="placeholder">{{ config.newItemRow!.translateKey[parentId ? 'subLevel' : 'mainLevel'] | translate }}</span>
          }
        </div>
      </td>
    </tr>
  }
</ng-template>

<!--------------------           pagination                 ---------------------->
<sem-pagination
  *ngIf="(dataToDisplay?.data)!.length > 0"
  [isGoToPageHidden]="paginator?.isGoToPageHidden || false"
  [isItemsPerPageHidden]="paginator?.isItemsPerPageHidden || false"
></sem-pagination>

<ng-template #placeholderFooter>
  <span class="placeholder-footer">-</span>
</ng-template>
