import { animate, group, style, transition, trigger } from '@angular/animations';

export const toolAnimation = trigger('toolAnimation', [
  transition(':enter', [
    group([
      style({
        opacity: 0,
      }),
    ]),
    group([
      animate(
        '300ms cubic-bezier(0.250, 0.460, 0.450, 0.940)',
        style({
          opacity: 1,
        }),
      ),
    ]),
  ]),
  transition(':leave', [
    group([
      style({
        opacity: 1,
        'transform-origin': 'top',
      }),
    ]),
    group([
      animate(
        '300ms cubic-bezier(0.250, 0.460, 0.450, 0.940)',
        style({
          opacity: 0,
        }),
      ),
    ]),
  ]),
]);

export const appearAnimation = trigger('appearAnimation', [
  transition(':enter', [
    group([
      style([
        {
          opacity: 0,
        },
      ]),
    ]),
    group([
      animate(
        '600ms cubic-bezier(0.250, 0.460, 0.450, 0.940)',
        style({
          opacity: 1,
        }),
      ),
    ]),
  ]),
]);
