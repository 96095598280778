<!-- <sem-autocomplete-column
  class="columnComponent"
  (autocompleteChanged)="changeAutocomplete($event)"
  (valueChanged)="action.value.from = $event"
  [placeholderForce]="'Find'"
  [column]="column"
  [value]="null"
>
</sem-autocomplete-column>
<sem-autocomplete-column
  class="columnComponent"
  (autocompleteChanged)="changeAutocomplete($event)"
  (valueChanged)="action.value.to = $event"
  [placeholderForce]="'Replace'"
  [column]="column"
  [value]="null"
>
</sem-autocomplete-column> -->

<!-- TODO: add autocomplete here -->
<mat-form-field appearance="outline" class="columnComponent">
  <mat-label>Find</mat-label>
  <input [(ngModel)]="action.value.from" matInput name="Find" placeholder="Value" required />
</mat-form-field>

<mat-form-field appearance="outline" class="columnComponent">
  <mat-label>Replace</mat-label>
  <input [(ngModel)]="action.value.to" matInput name="Replace" placeholder="Value" required />
</mat-form-field>

<mat-checkbox [(ngModel)]="action.value.caseSensitive" name="caseSensitive">{{ 'sem_table.case_sensitive' | translate }} </mat-checkbox>
<mat-checkbox [(ngModel)]="action.value.fullMatch" name="fullMatch">{{ 'sem_table.full_word_match' | translate }} </mat-checkbox>
