import { Pipe, PipeTransform } from '@angular/core';
import { mainTableLabelCellPrefix } from '../../enums';
import { ItemModel } from '../../item.model';

@Pipe({
  name: 'cellLabel',
})
export class CellLabelPipe implements PipeTransform {
  transform(item: ItemModel, param: string): string {
    return item[(mainTableLabelCellPrefix + param) as keyof typeof item] as string;
  }
}
