import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material-module/material.module';
import { SemTableSharedModule } from '../sem-table-shared/sem-table-shared.module';
import { TableDisplayModule } from '../table-display/table-display.module';
import { ActionFormComponent } from './action-form/action-form.component';
import { ActionPopupComponent } from './action-popup/action-popup.component';
import { ActionSwitchComponent } from './action-switch/action-switch.component';
import { AddFoundFromFieldComponent } from './action-switch/add-found-from-field/add-found-from-field.component';
import { LinkBuilderComponent } from './action-switch/link-builder/link-builder.component';
import { ActionTypeAliasPipe } from './action-switch/pipes/action-type-alias.pipe';
import { ActionTypeTooltipPipe } from './action-switch/pipes/action-type-tooltip.pipe';
import { RegexpReplaceComponent } from './action-switch/regexp-replace/regexp-replace.component';
import { ReplaceAutocompleteComponent } from './action-switch/replace-autocomplete/replace-autocomplete.component';
import { ReplaceComponent } from './action-switch/replace/replace.component';
import { ValueGeneratorComponent } from './action-switch/value-generator/value-generator.component';
import { FiltersModule } from '../filters/filters.module';
import { TrimSpacesDirective } from '../../directives/trim-spaces.directive';

@NgModule({
  declarations: [
    ActionPopupComponent,
    ValueGeneratorComponent,
    ActionTypeAliasPipe,
    ActionTypeTooltipPipe,
    LinkBuilderComponent,
    AddFoundFromFieldComponent,
    ActionSwitchComponent,
    ReplaceComponent,
    ReplaceAutocompleteComponent,
    ActionFormComponent,
    RegexpReplaceComponent,
  ],
  imports: [
    SemTableSharedModule,
    MaterialModule,
    FiltersModule,
    TableDisplayModule,
    SemTableSharedModule,
    TranslateModule,
    TrimSpacesDirective,
  ],
  exports: [ActionTypeAliasPipe, ActionFormComponent],
})
export class ActionModule {}
