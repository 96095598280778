import { Component, Inject, signal } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';

@Component({
  selector: 'sem-duplicate-by',
  templateUrl: './duplicate-by.component.html',
  styleUrls: ['./duplicate-by.component.scss'],
})
export class DuplicateByComponent {
  readonly values = signal<string[]>([]);

  constructor(
    public dialogRef: MatDialogRef<DuplicateByComponent>,
    @Inject(MAT_DIALOG_DATA) public column: ColumnModel,
  ) {}

  add(event: MatChipInputEvent): void {
    const { value, chipInput } = event;
    if (value && !this.values().includes(value)) {
      this.values.set([...this.values(), value]);
    }
    if (chipInput) {
      chipInput.inputElement.value = '';
    }
  }

  remove(query: string): void {
    this.values.set(this.values().filter((x) => x !== query));
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    const { clipboardData } = event;
    const pastedData = this.mapValueFromString(clipboardData?.getData('Text') ?? '');
    const valuesToAdd = pastedData.filter((x) => !this.values().includes(x));
    this.values.set([...this.values(), ...valuesToAdd]);
  }

  private mapValueFromString(value: string): string[] {
    return value.split(/\r?\n|,|;/);
  }
}
