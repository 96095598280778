import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActionService } from '../../action/action.service';
import { mainTableColorItem, mainTableLabelItem } from '../../enums';
import { FilterLogicOperatorEnum } from '../../filters/FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../../filters/FilterTypesEnum';
import { FilterService } from '../../filters/filters.service';
import { FilterFactoryService } from '../../filters/filtersFactory.service';
import { ItemModel } from '../../item.model';
import { CustomButtonPerItemInterface } from '../../models/CustomButton.model';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { PaginatorInterface } from '../../models/TableConfigurationInterface.model';
import { PaginationInterface } from '../../models/entryPagination.model';
import { AgregationService } from '../../services/agregation.service';
import { ChangesService } from '../../services/changes.service';
import { ColumnsService } from '../../services/columns.service';
import { CommunicationService } from '../../services/communication.service';
import { ConfigService } from '../../services/config.service';
import { ExpansionRowService } from '../../services/expansion-row.service';
import { GroupService } from '../../services/group.service';
import { HelperService } from '../../services/helper.service';
import { PopupService } from '../../services/popup.service';
import { SelectedService } from '../../services/selected.service';
import { RowItemModel, RowItemsService } from '../../services/row-items.service';
import { SortingService } from '../../services/sorting.service';
import { ColumnTypesEnum, ColumnValueTypesEnum, innerColumnName } from '../ColumnTypesEnum';
import { ColumnsMovingService } from '../columns-moving.service';
import { ColumnsSwitchComponent } from '../columns-switch/columns-switch.component';
import { ActionColumn } from '../columns-switch/columns/action-column/ActionColumn';
import { ImageColumn } from '../columns-switch/columns/image-column/ImageColumn';

export const TABLE_ROW_ID_PREFIX = 'sem-table-row-';

@Component({
  selector: 'sem-table-displayer',
  templateUrl: './table-displayer.component.html',
  styleUrls: ['./table-displayer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDisplayerComponent implements OnDestroy {
  @Input() dataToDisplay: PaginationInterface<ItemModel> | null = null;
  @Input() paginator: PaginatorInterface | null = null;
  @Input() shouldCheckEmpty: boolean = true;

  @Output() newItemRow: EventEmitter<number | null> = new EventEmitter();

  @ViewChildren('columnsSwitch') columnReferences!: QueryList<ColumnsSwitchComponent>;
  @ViewChildren('resizableCell') tableCells!: QueryList<ElementRef<HTMLTableCellElement>>;
  @ViewChildren('tableRows', { read: ElementRef }) rowsReferences!: QueryList<ElementRef<HTMLTableRowElement>>;

  actionColumn: ActionColumn;
  config!: TableConfigurationModel;
  displayedColumns$ = this.columnsService.displayedColumns$;
  hideColumn = false;
  innerColumnName = innerColumnName;
  isNewItemRowVisible = false;
  mainTableColorItem = mainTableColorItem;
  mainTableLabelItem = mainTableLabelItem;
  selectAllGlobal$ = this.selectedService.selectedGlobally$;
  selectedItems$ = this.selectedService.selectedItems$;
  sorting$ = this.sortingService.sorting$;
  filteredDisplayedColumns$ = this.displayedColumns$.pipe(
    map((columns) => columns.filter((column) => !(column === this.innerColumnName && this.hideColumn))),
  );

  readonly rowIdPrefix = TABLE_ROW_ID_PREFIX;
  private onDestroy$: Subject<void> = new Subject();
  protected readonly expansionRowService = inject(ExpansionRowService, { optional: true });
  protected readonly rowsService = inject(RowItemsService, { optional: true });

  constructor(
    private communicationService: CommunicationService,
    private configService: ConfigService,
    private popupService: PopupService,
    private selectedService: SelectedService,
    private snackBar: MatSnackBar,
    private sortingService: SortingService,
    private filterFactoryService: FilterFactoryService,
    private filterService: FilterService,
    public actionService: ActionService,
    public agregationService: AgregationService,
    public changesService: ChangesService,
    public columnsMovingService: ColumnsMovingService,
    public columnsService: ColumnsService,
    public groupService: GroupService,
    public helperService: HelperService,
  ) {
    this.actionColumn = new ActionColumn();
    this.setConfig(this.configService.config);
    this.configService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => this.setConfig(config));
    this.rowsService?.scrollToRowItem$.pipe(takeUntil(this.onDestroy$)).subscribe((item: ItemModel) => this.scrollToRowItem(item));
    this.rowsService?.editModeForItemCell$.pipe(takeUntil(this.onDestroy$)).subscribe((data: RowItemModel) => {
      const { columnName, item } = data;

      if (item && columnName) {
        this.setFocusOnColumn(item, columnName);
        this.enterEditModeOnColumn(item, columnName);
        data.scrollTo && this.scrollToRowItem(item);
      }
    });
  }

  onHideColumnChange(hide: boolean): void {
    this.hideColumn = hide;
    this.filteredDisplayedColumns$ = this.displayedColumns$.pipe(
      map((columns) => columns.filter((column) => !(column === this.innerColumnName && this.hideColumn))),
    );
  }

  catchChange(value: any, item: ItemModel | null, columnName: string): void {
    if (item) {
      if (this.config.columns[columnName].editable?.singleChange) {
        const change = this.changesService.createSingleChange(item, columnName, value);
        this.changesService.singleChange$.next(change);
      } else {
        const change = this.changesService.createChange([item], columnName, [value]);
        this.changesService.newChange$.next(change);
      }
    }
  }

  enterEditModeOnColumn(item: ItemModel, columnName: string) {
    const target = this.findColumnInTable(item, columnName);
    target && target.enterEditMode();
  }

  getStylesForItem(item: ItemModel): { [klass: string]: any } | null {
    return this.config.itemRowStyle?.(item) || null;
  }

  newItemRowChange(value: any, columnName: string): void {
    const change = this.changesService.createSingleChange(null, columnName, value);
    change.isNewItemRow = true;
    this.changesService.singleChange$.next(change);
    this.setNewItemRowVisible(false);
  }

  newItemRowClick(isAddingByColumnName: boolean, data: unknown[] | null = null, parentId: number | null = null) {
    const listing = (data || this.dataToDisplay?.data) as ItemModel[];

    if (listing!.some((item) => item._isLoading)) {
      return false;
    }

    if (isAddingByColumnName) {
      this.setNewItemRowVisible(true);
    } else {
      this.newItemRow.emit(parentId);
      listing!.unshift({ id: 0, _isLoading: true });
    }
  }

  setFocusOnColumn(item: ItemModel, columnName: string) {
    const target = this.findColumnInTable(item, columnName);
    target && target.setFocus();
  }

  select(item: ItemModel) {
    this.selectedService.selectItem(item);
  }

  setNewItemRowVisible(isVisible: boolean) {
    this.isNewItemRowVisible = isVisible;
  }

  unSelect(item: ItemModel) {
    this.selectedService.unselectItem(item);
  }

  selectWithShift(clickedItem: ItemModel) {
    let clickedIndex: number = 0;
    let anySelectedOnPage = false;
    let firstSelectedIndex = 9999999;
    let lastSelectedIndex = 0;
    this.dataToDisplay!.data.forEach((item, index) => {
      if (item.id === clickedItem.id) {
        clickedIndex = index;
      } else if (this.helperService.isInArray(item, this.selectedService.selectedItems)) {
        firstSelectedIndex = firstSelectedIndex < index ? firstSelectedIndex : index;
        lastSelectedIndex = lastSelectedIndex > index ? lastSelectedIndex : index;
        anySelectedOnPage = true;
      }
    });

    let arr: ItemModel[] = [];
    if (anySelectedOnPage && clickedIndex < firstSelectedIndex) {
      arr = this.dataToDisplay!.data.slice(clickedIndex, firstSelectedIndex);
    } else if (anySelectedOnPage && clickedIndex > lastSelectedIndex) {
      arr = this.dataToDisplay!.data.slice(lastSelectedIndex + 1, clickedIndex + 1);
    } else {
      this.select(this.dataToDisplay!.data[clickedIndex]);
    }

    arr.forEach((c) => this.select(c));
  }

  onCellInfoClick(item: ItemModel, columnName: string) {
    this.communicationService.cellClicked$.next({ item, columnName });
  }

  onItemInfoClick(item: ItemModel) {
    this.communicationService.itemInfoClicked$.next(item);
  }

  trackByFn(_index: number, item: ItemModel) {
    return item.id;
  }

  expansionClick(event: MouseEvent, item: ItemModel) {
    this.expansionRowService?.expand({ event, item });
  }

  filterByClick(item: ItemModel, columnName: string) {
    const itemColumnName = columnName as keyof ItemModel;
    const column = this.config.columns[columnName];
    const isBoolean = column.type === ColumnTypesEnum.BOOLEAN;
    let type: FilterTypesEnum;
    let value: any;
    if (column.type === ColumnTypesEnum.IMAGE) {
      value = null;
      type = item[itemColumnName] ? FilterTypesEnum.not_empty : FilterTypesEnum.is_empty;
    } else if (column.type === ColumnTypesEnum.SIMPLE && column.valueType === ColumnValueTypesEnum.number) {
      value = item[itemColumnName];
      type = value ? FilterTypesEnum.num_equal : FilterTypesEnum.is_empty;
    } else {
      value = item[itemColumnName];
      type = value || isBoolean ? FilterTypesEnum.equal : FilterTypesEnum.is_empty;
    }
    const filterObj = this.filterFactoryService.createFilter(column, type, value, FilterLogicOperatorEnum.and, false);

    this.filterService.addFilterToLastGroup(filterObj);
  }

  openPhoto(columnName: string, item: ItemModel) {
    const column: ImageColumn = this.config.columns[columnName] as ImageColumn;
    if (column.editable && !column.editInCustomLogic && !this.config.readOnly) {
      this.popupService
        .openPhotoEditPopup(columnName, item)
        .subscribe((mainImage: any) => this.catchChange(mainImage.thumb_path, item, columnName));
    } else if (column.editable && column.editInCustomLogic && !this.config.readOnly) {
      this.communicationService.openPhotoCustomEdit$.next({ item, columnName });
    } else {
      this.popupService.openPhotoDisplayPopup(columnName, item);
    }
  }

  handleCustomEventOnColumn(data: { columnName: string; mode: 'edit' | 'display' }, item: ItemModel) {
    this.communicationService.customEventOnColumn$.next({ ...data, item });
  }

  handleCustomButtonClicked(event: CustomButtonPerItemInterface, item: ItemModel, columnName: string) {
    this.communicationService.customButtonClicked$.next({ type: event.actionType, data: item, columnName });
  }

  handleAiGeneratedClicked(item: ItemModel, columnName: string) {
    this.communicationService.aiGeneratedClicked$.next({ item, columnName });
  }

  handleNavigationClicked(item: ItemModel, columnName: string) {
    !item._isNotNavigationValue && this.communicationService.navigationClicked$.next({ item, columnName });
  }

  showSnackBar(text: string) {
    this.snackBar.open(text, undefined, {
      duration: 3000,
      horizontalPosition: 'center',
      panelClass: 'table-col-snack-bar',
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private scrollToRowItem(item: ItemModel): void {
    const rowElem = this.findRowInTable(item);
    rowElem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  private setConfig(config: TableConfigurationModel) {
    if (config?.navigationColumn && config.columns && config.columns[config.navigationColumn]) {
      config.columns[config.navigationColumn]._isNavigationColumn = true;
    }

    this.config = config;
  }

  private findColumnInTable(item: ItemModel, columnName: string): ColumnsSwitchComponent {
    const target = this.columnReferences.find((component) => +component.id === item.id && component.column.param === columnName);

    return target!;
  }

  private findRowInTable(item: ItemModel): ElementRef<HTMLTableRowElement> {
    const itemId = item.id;
    let target;

    if (itemId) {
      const rowId = `${this.rowIdPrefix}${itemId}`.toString();
      target = this.rowsReferences.find((row) => row.nativeElement.id === rowId);
    }

    return target!;
  }
}
