import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigGeneratorService } from '../../configGenerator.service';
import { FilterService } from '../../filters/filters.service';
import { AutocompleteEventModel, FilterGroupModel, TableConfigurationInterface } from '../../public-api';
import { AutocompleteService } from '../../services/autocomplete.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'sem-filters-for-columns',
  templateUrl: './filters-for-columns.component.html',
})
export class FiltersForColumnsComponent implements OnInit, OnDestroy {
  @Output() filtersChanged = new EventEmitter<FilterGroupModel[]>();
  @Output() autocompleteChange: EventEmitter<AutocompleteEventModel> = new EventEmitter();
  @Input() config!: TableConfigurationInterface;

  public readonly autocompleteService = inject(AutocompleteService);
  public readonly configGeneratorService = inject(ConfigGeneratorService);
  public readonly configService = inject(ConfigService);

  private filterService = inject(FilterService);
  private onDestroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.config && this.configService.saveConfig(this.configGeneratorService.getParsedConfig(this.config));

    this.autocompleteService.initAutocompleteChange$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => this.autocompleteChange.emit(value));

    this.filterService.filters$.pipe(takeUntil(this.onDestroy$)).subscribe((filters) => {
      this.config.activeFilters = filters;
      this.filtersChanged.emit(filters.filterGroups);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
