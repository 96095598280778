import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { ColumnsComponentInterface } from '../editable-column.component';
import { BooleanColumn } from './BooleanColumn';

@Component({
  selector: 'sem-boolean-column',
  templateUrl: './boolean-column.component.html',
  styleUrls: ['./boolean-column.component.scss', '../shared-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanColumnComponent implements ColumnsComponentInterface, OnDestroy {
  actualValue!: boolean;
  @Input() displayEditable!: boolean;
  @Input() displayNotEditable!: boolean;
  @Input() column!: BooleanColumn;
  @Input() disabled!: boolean;
  editState!: boolean;
  @Output() editStateEntered: EventEmitter<null> = new EventEmitter();
  @Output() editStateExited: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('container', { read: ElementRef }) containerRef!: ElementRef;
  @ViewChild('reference', { read: MatSelect }) selectReference!: MatSelect;
  @ViewChild('input', { read: MatSelect }) input!: MatSelect;
  private onDestroy$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private eRef: ElementRef,
  ) {}

  @Input() set value(data: any) {
    this.actualValue = Boolean(data);
  }

  @HostListener('dblclick', ['$event']) dbClick(event: any) {
    event.stopPropagation();
    this.enterEditableMode();
  }

  @HostListener('keydown', ['$event']) keydown(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Escape' || event.keyCode === ENTER) {
      this.exitEditableState();
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (this.editState && !this.eRef.nativeElement.contains(event.target)) {
      this.exitEditableState();
    }
  }

  click() {}

  exitEditableState(): void {
    this.editState = false;
    this.selectReference.close();
    this.editStateExited.emit();
    if (this.actualValue !== this.selectReference.value) {
      const val = this.selectReference.value === 'null' ? null : this.selectReference.value;
      this.valueChanged.emit(val);
    }
  }

  enterEditableMode() {
    if (!this.column.editable || this.displayNotEditable) {
      return;
    }
    // this.editableActive = true;
    this.editStateEntered.emit();
    this.editState = true;
    this.cd.detectChanges();
    this.selectReference.open();
    setTimeout(() => this.selectReference.focus(), 10);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
