import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ItemModel } from '../item.model';
import { TableDisplayerComponent } from './table-displayer/table-displayer.component';
import { ColumnsService } from '../services/columns.service';
import { HelperService } from '../services/helper.service';

@Injectable({
  providedIn: 'root',
})
export class ColumnsMovingService implements OnDestroy {
  private onDestroy$ = new Subject<void>();

  constructor(
    public helperService: HelperService,
    public columnsService: ColumnsService,
  ) {}

  prevColumn(item: ItemModel, columnName: string, dataArray: ItemModel[], component: TableDisplayerComponent) {
    const index = this.columnsService.columnsAbleToHighlight.indexOf(columnName);
    const prevIndex = index > 0 ? index - 1 : index;

    try {
      component.setFocusOnColumn(item, this.columnsService.columnsAbleToHighlight[prevIndex]);
    } catch (e) {
      const lastIndex = this.columnsService.columnsAbleToHighlight.length - 1;
      this.prevRow(item, this.columnsService.columnsAbleToHighlight[lastIndex], dataArray, component);
    }
  }

  nextColumn(item: ItemModel, columnName: string, dataArray: ItemModel[], component: TableDisplayerComponent) {
    const index = this.columnsService.columnsAbleToHighlight.indexOf(columnName);
    const nextIndex = index > -1 ? index + 1 : index;

    try {
      component.setFocusOnColumn(item, this.columnsService.columnsAbleToHighlight[nextIndex]);
    } catch (e) {
      this.nextRow(item, this.columnsService.columnsAbleToHighlight[0], dataArray, component);
    }
  }

  prevRow(item: ItemModel, columnName: string, dataArray: ItemModel[], component: TableDisplayerComponent) {
    const prevIndex = this.helperService.returnIndexInArray(item, dataArray) - 1;
    const prevItem = dataArray[prevIndex] ? dataArray[prevIndex] : null;
    if (!prevItem) {
      return;
    }
    component.setFocusOnColumn(prevItem, columnName);
  }

  nextRow(item: ItemModel, columnName: string, dataArray: ItemModel[], component: TableDisplayerComponent) {
    const nextIndex = this.helperService.returnIndexInArray(item, dataArray) + 1;
    const nextItem = dataArray[nextIndex] ? dataArray[nextIndex] : null;
    if (!nextItem) {
      return;
    }
    component.setFocusOnColumn(nextItem, columnName);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
