import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ItemModel } from '../item.model';
import { TableConfigurationModel } from '../models/TableConfiguration.model';
import { AgregationDataModel } from '../models/agregation.model';
import { ColumnModel } from '../table-display/columns-switch/columns/column.model';
import { ColumnsService } from './columns.service';
import { ConfigService } from './config.service';
import { SelectedService } from './selected.service';

@Injectable()
export class AgregationService implements OnDestroy {
  configuration!: TableConfigurationModel;
  selectedData$: Subject<AgregationDataModel> = new Subject();
  selectedData: AgregationDataModel = {};
  totalAgregationData$: Subject<AgregationDataModel> = new Subject();
  totalAgregationData: AgregationDataModel = {};
  unSub$: Subject<void> = new Subject();

  constructor(
    private selectedService: SelectedService,
    private columnService: ColumnsService,
    private configService: ConfigService,
  ) {
    this.configService.config$.pipe(first()).subscribe((config) => this.initSelectedData(config));

    this.totalAgregationData$
      .pipe(
        // tap(data => console.log(data)),
        takeUntil(this.unSub$),
      )
      .subscribe((data) => (this.totalAgregationData = data));
    this.selectedData$
      .pipe(
        // tap(data => console.log(data)),
        takeUntil(this.unSub$),
      )
      .subscribe((data) => (this.selectedData = data));
  }

  initSelectedData(config: TableConfigurationModel) {
    const { columns: columConfigs } = config;
    Object.keys(columConfigs).forEach((columnName) => {
      if (columConfigs[columnName].aggregateBySelect) {
        this.selectedData[columnName] = 0;
      }
    });

    this.selectedService.selectedItems$
      .pipe(takeUntil(this.unSub$))
      .subscribe((selectedItems) => this.agregateSelected(selectedItems, this.columnService.columnChosenToDisplay));
  }

  agregateSelected(itemsSelected: ItemModel[], columnsDisplayed: string[]) {
    const { columns } = this.configService.config;

    columnsDisplayed.forEach((columnName) => {
      const column: ColumnModel = columns[columnName];
      if (column.aggregateBySelect) {
        this.selectedData[columnName] = column.aggregateBySelect.calculate(itemsSelected, columnName);
      }
    });

    this.selectedData$.next(this.selectedData);
  }

  ngOnDestroy(): void {
    this.unSub$.next();
  }
}
