import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService, Translation } from '@ngx-translate/core';
import { filter } from 'lodash';
import { PaymentPlanCssEnum, PaymentPlanSupportEnum } from '../../models/payments.enum';
import { PaymentPlanProductItemModel, PaymentPlanProductModel, SelectedProductModel } from '../../models/payments.model';
import commonUrlsConfig from '../../../../configs/common-urls.config';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
})
export class BoxComponent implements OnInit {
  @Input() currency!: string;
  @Input() data: PaymentPlanProductModel | undefined;
  @Input() basicPlan: PaymentPlanProductItemModel | null = null;
  @Input() isSelected!: boolean;
  @Input() isTrial!: boolean;
  @Input() key!: PaymentPlanSupportEnum | PaymentPlanCssEnum;
  @Input() showSub: boolean = true;

  @Output() selected = new EventEmitter<SelectedProductModel>();
  @Output() showLimits = new EventEmitter<SelectedProductModel>();
  @Output() showCss = new EventEmitter<SelectedProductModel>();

  translator: Translation;

  currenItem!: PaymentPlanProductItemModel;
  restrictions!: PaymentPlanProductItemModel[];
  isActiveService: boolean = false;
  commonUrlsConfig = commonUrlsConfig;

  marketingData: { price: string; subscriptionPrice: string } = {
    price: '2499',
    subscriptionPrice: '249',
  };

  protected translateService = inject(TranslateService);

  ngOnInit(): void {
    this.translator = this.translateService.instant(`payments.plan.support.items.${this.key}`);
    this.isActiveService = this.key === PaymentPlanSupportEnum.activeService;
    if (this.data && this.data.list) {
      const customCurrentItem = this.data.list.find((item) => item.isSelected);
      if (customCurrentItem) {
        this.currenItem = this.data.list.find((item) => item.isSelected)!;
      } else {
        this.currenItem = this.data.list[0];
      }

      this.restrictions = filter(this.data.list, 'restrictions'); // `restrictions.${this.restrictionKey}`
    }
  }

  select() {
    if (this.isActiveService) {
      window.top!.location.href = commonUrlsConfig.agencyWebsite;
    } else {
      this.selected.emit({
        key: this.key,
        product: this.currenItem,
      });
    }
  }

  displayLimits() {
    this.showLimits.emit();
  }

  displayCss() {
    this.showCss.emit();
  }
}
