import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';

@Component({
  selector: 'sem-column-list-display',
  templateUrl: './column-list-display.component.html',
  styleUrls: ['./column-list-display.component.scss'],
})
export class ColumnListDisplayComponent implements OnInit, OnDestroy {
  @ViewChild('input') inputRef!: any;
  @Input() closeAfterChoose = true;
  @Input() columns!: string[];
  @Input() config!: TableConfigurationModel;
  @Output() columnClicked: EventEmitter<string> = new EventEmitter();
  @Output() columnHovered: EventEmitter<void> = new EventEmitter();

  onDestroy$: Subject<void> = new Subject();
  searchForm!: UntypedFormGroup;
  allColumnsModels!: ColumnModel[];
  columnsModels!: ColumnModel[];

  private readonly fb = inject(UntypedFormBuilder);
  private readonly changeDetector = inject(ChangeDetectorRef);

  get search() {
    return this.searchForm.get('search') as UntypedFormControl;
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      search: new UntypedFormControl(''),
    });

    this.search.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(500)).subscribe((value) => this.searchColumn(value));

    if (this.columns && this.config) {
      const columnsModels = this.columns.map((column) => this.config.columns[column]);
      this.allColumnsModels = columnsModels;
      this.columnsModels = columnsModels;
    }
  }

  searchColumn(string: string) {
    this.columnsModels = this.allColumnsModels.filter((column) => this.filter(column.alias, string));
    this.changeDetector.detectChanges();
  }

  filter(string: string, textToSearch: string): boolean {
    const reg = new RegExp(textToSearch, 'gi');
    return string.search(reg) !== -1;
  }

  click(event: MouseEvent) {
    event.stopPropagation();
  }

  clickOnColumn(column: ColumnModel, event: MouseEvent) {
    if (!this.closeAfterChoose) {
      this.search.setValue(this.search.value);
      this.inputRef.nativeElement.focus();
      event.preventDefault();
      event.stopPropagation();
    }

    this.columnClicked.emit(column.param);
    this.allColumnsModels = this.allColumnsModels.filter((col) => col.param !== column.param);
    this.searchColumn(this.search.value);

    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
