import { Component, Input, OnDestroy, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';
import { share, takeUntil, tap } from 'rxjs/operators';
import { CommunicationService } from '../../services/communication.service';
import { Pagination, PaginationService } from './pagination.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sem-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnDestroy {
  @Input() isGoToPageHidden: boolean = false;
  @Input() isItemsPerPageHidden: boolean = false;

  goToPageCtrl = new UntypedFormControl();
  current: { pagesCount: number; currentPageIndex: number } = { currentPageIndex: 0, pagesCount: 0 };
  private onDestroy$ = new Subject<void>();
  private paginationService = inject(PaginationService);
  pageSizeOptions = this.paginationService.pageSizeOptions;
  page$: Observable<Pagination> = this.paginationService.page$.pipe(
    tap((page) => {
      this.goToPageCtrl.setValue(page.currentPageIndex, { emitEvent: true });
      this.current = { currentPageIndex: page.currentPageIndex, pagesCount: page.pagesCount };
    }),
    takeUntil(this.onDestroy$),
    share(),
  );

  private communicationService = inject(CommunicationService);
  private translateService = inject(TranslateService);

  changePageSize(event: MatSelectChange) {
    this.paginationService.changePageSize(event.value);
  }

  goToFirst() {
    this.paginationService.goToFirst();
  }

  goBack() {
    this.paginationService.goBack();
  }

  goNext() {
    this.paginationService.goNext();
  }

  goToLast() {
    this.paginationService.goToLast();
  }

  goToPage(pageToChange?: number) {
    const { current } = this;
    const page = pageToChange !== undefined ? pageToChange : this.goToPageCtrl.value;

    if (page > current.pagesCount) {
      this.goToPageCtrl.setValue(current.currentPageIndex, { emitEvent: false });
      const message = this.translateService.instant('sem_table.pagination_page_is_out_of_range');
      this.communicationService.customMessage$.next({ type: 'error', case: 'DEFAULT', message });
      return;
    }
    if (page <= 0) {
      this.goToPageCtrl.setValue(current.currentPageIndex, { emitEvent: false });
      const message = this.translateService.instant('sem_table.pagination_page_greater_than_0');
      this.communicationService.customMessage$.next({ type: 'error', case: 'DEFAULT', message });
      return;
    }
    this.paginationService.goToPage(page);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
