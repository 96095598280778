import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sem-filesize-column',
  templateUrl: './filesize-column.component.html',
  styleUrls: ['./filesize-column.component.scss'],
})
export class FilesizeColumnComponent implements OnInit {
  @Input() value!: number;
  displayValue!: string;

  ngOnInit() {
    this.displayValue = this.bytesToMb(this.value);
  }

  bytesToMb(bytes: number, precision: number = 2): string {
    if (bytes === null || bytes === undefined || isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '-';
    }
    if (bytes === 0) {
      return '0 MB';
    }
    const unit = 1024;
    const megabytes = bytes / (unit * unit);
    return `${megabytes.toFixed(precision)} MB`;
  }
}
