import { Component, Input } from '@angular/core';
import { ReplaceAction } from '../../models/ReplaceAction';

@Component({
  selector: 'sem-replace',
  templateUrl: './replace.component.html',
  styleUrls: ['./replace.component.scss'],
})
export class ReplaceComponent {
  @Input() action!: ReplaceAction;
}
