import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterLogicOperatorEnum } from '../FilterLogicOperatorEnum';

@Component({
  selector: 'sem-filter-operator',
  templateUrl: './filter-operator.component.html',
})
export class FilterOperatorComponent {
  @Input() operatorCtrl!: UntypedFormControl;

  setOrOperator() {
    this.operatorCtrl.setValue(FilterLogicOperatorEnum.or);
  }

  setAndOperator() {
    this.operatorCtrl.setValue(FilterLogicOperatorEnum.and);
  }
}
