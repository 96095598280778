<div class="variant-header">
    <div class="img-wrapper">
      <img src="assets/images/{{isActiveService ? 'agency' : 'logo-black'}}.png" alt="Variant Image">
    </div>
</div>
<mat-card appearance="outlined" [ngClass]="{ selected: isSelected }">
  <mat-card-header>
    <mat-card-title>{{ translator.title }}</mat-card-title>
    <mat-card-subtitle *ngIf="showSub" class="subtitle">{{ translator.subtitle }}</mat-card-subtitle>
    <mat-card-subtitle class="description">{{ translator.description }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="tw-block md:tw-flex tw-justify-between tw-mt-4">
      <div class="price-description">
        <p class="!tw-mb-4">
          {{ 'payments.plan.support.items.' + (isActiveService ? 'active_service' : 'free') + '.price_description' | translate }}
        </p>
          <span class="price-info">
            {{ 'general.from' | translate }}
              <span class="price">{{ isActiveService ? marketingData.price : basicPlan!.price! / 100 }}</span>
            <span class="currency">{{ 'payments.plan.currencies.' + currency | translate }}
                /{{ 'payments.plan.month' | translate }}</span>
          </span>
      </div>
      <div *ngIf="isActiveService && currenItem.setup!" class="price-description extra">
        <p class="!tw-mb-4">
            +{{ 'payments.plan.support.items.active_service.subscription' | translate }}
        </p>
          <span class="price-info">
            <span class="from">{{ 'general.from' | translate }}</span>
            <span class="price">{{ marketingData.subscriptionPrice }}</span>
            <span class="currency">
              {{ 'payments.plan.currencies.' + currency | translate }}/{{ 'payments.plan.month' | translate }}
            </span>
          </span>
      </div>
    </div>

    <div *ngIf="translator.detailed">
      <div *ngFor="let detail of translator.detailed" class="items">
        <h4 *ngIf="detail.title">{{ detail.title }}:</h4>
        <ul *ngIf="detail.list">
          <li *ngFor="let item of detail.list">
            <mat-icon>check_circle</mat-icon>
            {{ item }}
          </li>
          <li *ngFor="let item of detail.list_beta">
            <mat-icon>check_circle</mat-icon>
            {{ item }} <app-beta-label [isStatic]="true"></app-beta-label>
          </li>
          <span *ngIf="detail.limits_btn">
              <button class="limits-btn" (click)="displayLimits()" mat-stroked-button>{{ detail.limits_btn }}</button>
            </span>
          <span *ngIf="detail.css">
              <li>
                <mat-icon>check_circle</mat-icon>
                  {{ detail.css }} <span class="free">{{ 'payments.plan.support.gratis' | translate }}</span
              ><a class="css-display" (click)="displayCss()">{{ detail.css_display }}</a>
              </li>
            </span>
        </ul>
      </div>
    </div>

    <span *ngIf="data && isTrial && data.isTrial" class="trial-label">{{ 'payments.plan.free_version' | translate }}</span>

  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="select()">
      {{ 'payments.plan.support.items.' + (isActiveService ? 'active_service' : 'free') + '.select_btn' | translate }}
    </button>
    <ng-container *ngIf="isActiveService">
      <span class="tw-mx-[15px] tw-my-0">{{ 'dashboard.grid.or' | translate }}</span>
        <a mat-raised-button [href]="commonUrlsConfig.contactWebsite" target="_blank">
        {{ 'payments.plan.support.items.active_service.make_an_appointment' | translate }}
      </a>
    </ng-container>
  </mat-card-actions>
</mat-card>
