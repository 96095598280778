import { Component, EventEmitter, inject, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep, get, groupBy, intersection, mapValues } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/notification.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LocalStorageService, storageKey } from 'src/app/shared/service/local-storage.service';
import { routerConfig, RouterService } from 'src/app/shared/service/router.service';
import { LangEnum, TranslationService } from 'src/app/shared/service/translation.service';
import { RangeValidation } from 'src/app/shared/validators/range-validation.service';
import { AccountChangePopupComponent } from '../../navigation/modals/account-change-popup/account-change-popup.component';
import { PaymentPlanCssEnum, PaymentPlanSembotEnum, PaymentPlanSupportEnum } from '../models/payments.enum';
import { CurrencyEnum, PaymentPlanModel, PaymentPlanProductItemModel, PaymentPlanProductsModel, Restrictions, SelectedProductModel, SubscriptionPriceModel, } from '../models/payments.model';
import { PaymentsService } from '../payments.service';

const DEFAULT_CURRENCY = CurrencyEnum.eur;
const DEFAULT_COUNTRY = 'pl';
const MAX_VALUE = 999999;
const SUPPORTED_CURRENCIES = [CurrencyEnum.pln, CurrencyEnum.usd, CurrencyEnum.gbp, CurrencyEnum.eur];

interface SembotConfigParam { basic: number; max: number; min: number; step: number }
interface SembotConfig {
  output_products: SembotConfigParam;
  monitor_products: SembotConfigParam;
  monitor_phrases: SembotConfigParam;
  projects: SembotConfigParam;
}

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit, OnDestroy {
  @Output() fault: EventEmitter<boolean> = new EventEmitter();
  @Output() submitted: EventEmitter<boolean> = new EventEmitter();
  // currentPlan: CurrentPackage; // TODO: change to CurrentPlanModel
  additionalProducts: { id: number; count: number; key: string; name: string; value: number }[] = [];
  currency: CurrencyEnum = DEFAULT_CURRENCY;
  currentValues!: {};
  form!: UntypedFormGroup;
  isAnonymous: boolean = false;
  isLoading: boolean;
  isNoPermission: boolean = false;
  plan!: PaymentPlanModel;
  price!: SubscriptionPriceModel;
  selectedCssProduct: SelectedProductModel = { key: PaymentPlanCssEnum.whitelabel };
  selectedSupportProduct: SelectedProductModel = { key: PaymentPlanSupportEnum.free };
  subscriptionProductid!: number;
  showLimits = false;
  paymentPlanCssEnum = PaymentPlanCssEnum;
  paymentPlanSupportEnum = PaymentPlanSupportEnum;
  countries: { code: string; name: string }[] = [];
  // PaymentPlanSupportEnum.allInOne - @TMP: temporary removal of All in one: #asana-1207432193547201
  protected sembotOrder = [
    PaymentPlanSembotEnum.outputProducts,
    PaymentPlanSembotEnum.monitorProducts,
    PaymentPlanSembotEnum.monitorPhrases,
    PaymentPlanSembotEnum.projects,
  ];

  protected sembotConfig: SembotConfig = {
    [PaymentPlanSembotEnum.outputProducts]: {
      basic: 0,
      max: 100000,
      min: 1000,
      step: 1000,
    },
    [PaymentPlanSembotEnum.monitorProducts]: {
      basic: 0,
      max: 10000,
      min: 100,
      step: 100,
    },
    [PaymentPlanSembotEnum.monitorPhrases]: {
      basic: 0,
      max: 10000,
      min: 100,
      step: 100,
    },
    [PaymentPlanSembotEnum.projects]: {
      basic: 0,
      max: 200,
      min: 1,
      step: 1,
    },
  };

  protected selectedCssWhitelabelPackage: PaymentPlanProductItemModel | undefined;
  protected cssConfig: SembotConfigParam = {
    basic: 0,
    max: 4,
    min: 0,
    step: 1,
  };

  protected isLoadingPlan!: boolean;
  protected isCountryMissing: boolean = false;
  protected selectedCountry: string | null = null;
  private destroy$ = new Subject<void>();

  private activatedRoute = inject(ActivatedRoute);
  private authService = inject(AuthService);
  private dialog = inject(MatDialog);
  private notificationService = inject(NotificationService);
  private paymentsService = inject(PaymentsService);
  private routerService = inject(RouterService);
  private storageHelperService = inject(LocalStorageService);
  private translationService = inject(TranslationService);

  CurrencyEnum = CurrencyEnum;

  constructor() {
    this.isLoading = true;
  }

  get isTrialAvailable(): boolean {
    return this.plan! && this.plan.is_trial_available! && this.price! && this.price.is_trial_available!;
  }

  ngOnInit(): void {
    this.price = { amount: 0, currency: this.currency };

    if (this.authService.authUser) {
      const { permissions, country } = this.authService.authUser;
      if (permissions!.includes('manage payment')) {
        this.paymentsService.isCountryMissing().subscribe((missing) => {
          this.isCountryMissing = missing;
          this.loadCountries(country);

          if (this.isCountryMissing) {
            const browserCountryCandidate = this.getBrowserCountryCandidate();
            this.selectedCountry = browserCountryCandidate || DEFAULT_COUNTRY;
          } else {
            this.selectedCountry = country || DEFAULT_COUNTRY;
          }
          this.loadCountries(country);
        });
      } else {
        this.isNoPermission = true;
        this.isLoading = false;
      }
    } else {
      const { currency } = this.activatedRoute.snapshot.queryParams;
      currency && SUPPORTED_CURRENCIES.includes(currency) && (this.currency = currency);

      this.setLang();
      this.isAnonymous = true;

      const browserCountryCandidate = this.getBrowserCountryCandidate();
      this.selectedCountry = browserCountryCandidate || DEFAULT_COUNTRY;

      this.loadCountries();
    }
  }

  loadCountries(userCountry?: string): void {
    this.paymentsService.getCountries().subscribe((countries) => {
      this.countries = countries;

      if (!this.countries.find((c) => c.code === this.selectedCountry)) {
        if (userCountry && this.countries.find((c) => c.code === userCountry)) {
          this.selectedCountry = userCountry;
        } else {
          this.selectedCountry = DEFAULT_COUNTRY;
        }
      }

      this.getPlanParams();
    });
  }

  addOneStep(key: PaymentPlanSembotEnum | PaymentPlanCssEnum, isCss = false) {
    this.setOneStepValue(key, true, isCss);
  }

  subtractOneStep(key: PaymentPlanSembotEnum | PaymentPlanCssEnum, isCss = false) {
    this.setOneStepValue(key, false, isCss);
  }

  changeAccount() {
    const config = {
      width: '400px',
    } as MatDialogConfig;
    this.dialog.open(AccountChangePopupComponent, config);
  }

  onCountryChange(selectedCountry: string): void {
    this.selectedCountry = selectedCountry;
    this.recalculate();
    this.getPlanParams();
  }

  getPlanParams() {
    this.isLoadingPlan = true;

    const currencyParam = this.currency;
    const countryParam = this.selectedCountry;

    this.paymentsService
      .getPlan(currencyParam, countryParam)
      .pipe(
        tap((plan) => {
          plan?.support?.all_in_one && delete plan.support.all_in_one;
          this.plan = plan;
          if (plan) {
            plan.basic?.restrictions && this.setRestrictions(plan.basic.restrictions);
            plan.sembot && this.createForm(plan.sembot, plan.css);
          } else {
            this.notificationService.error('general');
            this.fault.emit(true);
          }
        }),
      )
      .subscribe(
        () => {
          this.getCurrentPlan();
          this.isLoadingPlan = false;
        },
        () => {
          this.notificationService.error('general');
          this.fault.emit(true);
          this.isLoadingPlan = false;
        },
      );
  }

  buy() {
    if (this.form.invalid) {
      this.notificationService.error('subscription_buy_params');
      return false;
    }

    this.isLoading = true;
    this.savePlanParamsToStorage();

    if (this.isAnonymous) {
      this.routerService.navigateBlank(routerConfig.authRegister);
    } else {
      if (this.paymentsService.currentPackage$.getValue()?.stripe_status) {
        this.paymentsService.changePackage(this.subscriptionProductid, this.additionalProducts!).subscribe(
          () => {
            this.submitted.emit(true);
            this.notificationService.success('subscription_buy');
            setTimeout(() => this.routerService.navigate(routerConfig.payments), 1500);
          },
          () => {
            this.submitted.emit(false);
            this.notificationService.warning('subscription_buy');
            this.isLoading = false;
          },
        );
      } else {
        this.paymentsService.getPaymentCheckoutUrl(this.subscriptionProductid, this.additionalProducts!, this.selectedCountry).subscribe(
          (url: string) => {
            url && (window.location.href = url);
          },
          () => {
            this.submitted.emit(false);
            this.notificationService.warning('subscription_buy');
            this.isLoading = false;
          },
        );
      }
    }
  }

  getCurrentPlan(): void {
    if (this.isAnonymous) {
      this.getPlanParamsFromStorage();
      this.markAsLoaded();
    } else {
      this.paymentsService
        .getCurrentPackage()
        .pipe(
          tap((plan) => {
            const { items, restrictions } = plan;

            if (items && items.length && restrictions && Object.keys(restrictions).length) {
              const slectedProduct = (product: PaymentPlanProductItemModel | null, section: string) => {
                if (product && product.prod_name) {
                  const key = product.prod_name;
                  const currentItem = ((this.plan[section as keyof typeof this.plan] as any)[key] as any).list.find(
                    (item: any) => item.id === product.id,
                  );
                  currentItem && (currentItem.isSelected = true);

                  return { key, product };
                }

                return null;
              };

              const itemsByType = groupBy(items, 'type');
              const css = slectedProduct(get(itemsByType, 'css[0]', null) as any, 'css');
              const support = slectedProduct(get(itemsByType, 'support[0]', null) as any, 'support');

              css && (this.selectedCssProduct = css);
              if (css) {
                this.setShowCss(css.product.restrictions.css_markets);
              }
              support && (this.selectedSupportProduct = support);
              this.showLimits = true;
              this.setCurrentRestrictions(restrictions);
            } else {
              this.getPlanParamsFromStorage();
            }
          }),
        )
        .subscribe(
          () => this.markAsLoaded(),
          () => {
            this.isLoading = false;
          },
        );
    }
  }

  getFormControl(key: string) {
    return this.form.get(key) as UntypedFormControl;
  }

  selectSupport(data: SelectedProductModel) {
    if (data.key === PaymentPlanSupportEnum.free) {
      this.getFormControl(PaymentPlanCssEnum.whitelabel).setValue(0, { emitEvent: false });
      return this.setDefaultLimits();
    }
    this.selectedSupportProduct = data;
    this.recalculate();
  }

  selectCss(data: SelectedProductModel) {
    this.selectedCssProduct = data;
    this.recalculate();
  }

  valueChange(event: any) {
    const value = +event.data!;
    this.cssConfig && (value >= this.cssConfig.max || isNaN(value))
      ? this.getFormControl(PaymentPlanCssEnum.whitelabel).setValue(this.cssConfig.max)
      : this.getFormControl(PaymentPlanCssEnum.whitelabel).setValue(value);
  }

  setShowLimits() {
    this.showLimits = true;
    setTimeout(() => {
      const limitsEl = document.getElementById('limits');
      limitsEl &&
        limitsEl.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
    }, 500);
  }

  setShowCss(value: number = 1) {
    this.getFormControl(PaymentPlanCssEnum.whitelabel).setValue(value);
    setTimeout(() => {
      const cssEl = document.getElementById('css');
      cssEl &&
        cssEl.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
    }, 500);
  }

  setDefaultLimits() {
    this.sembotOrder.forEach((key) => {
      const control = this.getFormControl(key);
      control && control.setValue(this.sembotConfig[key].min, { emitEvent: false });
    });

    this.selectedSupportProduct = {
      key: PaymentPlanSupportEnum.free,
    };
    this.recalculate();
    this.showLimits = true;
  }

  deleteCss() {
    this.getFormControl(PaymentPlanCssEnum.whitelabel).setValue(0);
  }

  goToChatGptLanding() {
    const currentLang = this.translationService.storageLang;
    window.open(
      currentLang === LangEnum.pl ? 'https://pl.sembot.com/sembot-chat-gpt-plug-in' : 'https://sembot.com/sembot-chat-gpt-plug-in',
      '_blank',
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getWhitelabelCssProductByValue(value: number): PaymentPlanProductItemModel | undefined {
    const groups = Object.values(this.plan.css);

    for (const group of groups) {
      for (const product of group.list) {
        if (product.prod_name === PaymentPlanCssEnum.whitelabel) {
          if (value === this.cssConfig.max) {
            if (Array.isArray(product.restrictions) && product.restrictions.length === 0) {
              return product;
            }
          } else if (product.restrictions.css_markets === value) {
            return product;
          }
        }
      }
    }
    return undefined;
  }

  formatLabel(value: number): string {
    return this.cssConfig && value >= this.cssConfig.max ? 'All' : `${value}`;
  }

  protected currencyChange(currency: CurrencyEnum) {
    this.currency = currency;
    if (this.currency && SUPPORTED_CURRENCIES.includes(this.currency)) {
      this.setLang();
      this.isLoading = true;
      this.getPlanParams();
    }
  }

  private getBrowserCountryCandidate(): string {
    const userLanguage = navigator.language || (navigator.languages && navigator.languages[0]) || '';

    const parts = userLanguage.split('-');

    if (parts.length > 1) {
      return parts[1].toUpperCase();
    }

    const langToCountry: Record<string, string> = {
      da: 'DK',
      en: 'GB',
      sv: 'SE',
      ja: 'JP',
      zh: 'CN',
      ko: 'KR',
      ar: 'SA',
      he: 'IL',
      cs: 'CZ',
      ga: 'IE',
      et: 'EE',
      sq: 'AL',
      el: 'GR',
      uk: 'UA',
      sr: 'RS',
      bs: 'BA',
      sl: 'SI',
      be: 'BY',
    };

    const langCode = parts[0].toLowerCase();
    return langToCountry[langCode] || DEFAULT_COUNTRY.toUpperCase();
  }

  private setupValueChanges() {
    this.sembotOrder.forEach((key) => {
      const control = this.getFormControl(key);

      control &&
        control.valueChanges.pipe(debounceTime(800), takeUntil(this.destroy$)).subscribe((newValue) => {
          this.handleControlValueChange(key, newValue);
        });
    });
  }

  private handleControlValueChange(key: PaymentPlanSembotEnum, newValue: string) {
    const numericValue = Number(newValue);
    if (!isNaN(numericValue) && !this.isMultipleOfStep(key, numericValue)) {
      const stepSize = this.sembotConfig[key].step;
      const roundedValue = Math.ceil(numericValue / stepSize) * stepSize;
      this.getFormControl(key).setValue(roundedValue, { emitEvent: false });
      this.recalculate();
    }
  }

  private isMultipleOfStep(key: PaymentPlanSembotEnum, value: number): boolean {
    const { step } = this.sembotConfig[key];
    return value % step === 0;
  }

  private calculateCounter(value: number, key: PaymentPlanSembotEnum | string): number {
    const config = this.sembotConfig[key as keyof typeof this.sembotConfig];
    const step = this.sembotConfig[key as keyof typeof this.sembotConfig].step || 1;

    let diffValue = value - (config.basic || 0);
    diffValue = diffValue < 0 ? 0 : diffValue;

    const divided = diffValue % step;
    if (divided > 0) {
      diffValue = diffValue - divided;
    }

    return diffValue / step;
  }

  private createForm(products: PaymentPlanProductsModel, css: PaymentPlanProductsModel) {
    const formGroupControls: Record<string, AbstractControl> = {};
    Object.keys(products).forEach((key: PaymentPlanSupportEnum | any) => {
      if (Object.keys(this.sembotConfig).includes(key)) {
        const product = products[key].list[0];
        const restriction = product.restrictions[key as keyof typeof product.restrictions] as number;

        if (restriction) {
          const config = (this.sembotConfig as any)[key];
          const minValue = config.basic && config.basic > restriction ? config.basic : restriction;

          config.min = minValue;
          config.step = restriction;
          const range = { min: config.min, max: MAX_VALUE };

          formGroupControls[key] = new UntypedFormControl(minValue, RangeValidation.range(range));

          (formGroupControls[key] as any)['_productId'] = product.id;
        }
      }
    });

    const cssOption = css.css_whitelabel.list[0];
    const restriction = cssOption.restrictions.css_markets as number;

    if (restriction) {
      const { min, max } = this.cssConfig;
      formGroupControls.css_whitelabel = new UntypedFormControl(min, RangeValidation.range({ min, max }));
    }

    this.form = new UntypedFormGroup(formGroupControls);
    this.recalculate();

    this.form.valueChanges.subscribe(() => this.recalculate());
    this.setupValueChanges();
  }

  private getPlanParamsFromStorage() {
    const restrictionsFromStorage = this.storageHelperService.get(storageKey.paymentPlanParams);

    if (!restrictionsFromStorage || !this.plan) {
      this.isLoading = false;
      return;
    }

    if (restrictionsFromStorage) {
      const key = (plan: PaymentPlanProductsModel) => {
        if (!plan) return null;
        const commonKeys = intersection(Object.keys(plan), Object.keys(restrictionsFromStorage));
        let resp = null;

        if (commonKeys && commonKeys[0] && restrictionsFromStorage[commonKeys[0]].id) {
          const firstKey = commonKeys[0];
          const product = plan[firstKey].list.find((item) => item.id === restrictionsFromStorage[firstKey].id);

          if (product) {
            product.isSelected = true;
            resp = { key: firstKey, product };
          }
        }
        return resp;
      };

      const css = key(this.plan.css);
      const support = key(this.plan.support!);

      css && (this.selectedCssProduct = css);
      support && (this.selectedSupportProduct = support);

      this.setCurrentRestrictions(mapValues(restrictionsFromStorage, 'val') as Restrictions);
      this.form.markAllAsTouched();
    }
  }

  private markAsLoaded() {
    setTimeout(() => (this.isLoading = false), 2000);
  }

  private setLang(lang = this.currency === CurrencyEnum.pln ? LangEnum.pl : LangEnum.en) {
    this.translationService.setCustomLang(lang);
  }

  private recalculate() {
    this.isLoadingPlan = true;
    this.additionalProducts = [];
    const basicSubscription = this.plan.basic;
    this.subscriptionProductid = basicSubscription!.id ? basicSubscription!.id : null!;
    let products: {
      id: number;
      count?: number;
    }[] = this.subscriptionProductid ? [{ id: this.subscriptionProductid }] : [];

    for (const key in this.form.controls) {
      const control = this.getFormControl(key);
      const value = Number(control.value);

      let projectId = Number((control as any)['_productId']);

      if (!projectId && key === PaymentPlanCssEnum.whitelabel) {
        const cssProduct = this.getWhitelabelCssProductByValue(value);
        projectId = cssProduct?.id!;
        if (projectId) {
          this.selectedCssWhitelabelPackage = cssProduct;
        } else {
          this.selectedCssWhitelabelPackage = undefined;
        }
        projectId && this.setProduct(key, projectId, 1, value);
        continue;
      }
      const count = this.calculateCounter(value, key);
      projectId && count && this.setProduct(key, projectId, count, value);
    }

    const setPreparedProduct = (selectedProduct: SelectedProductModel, restrictionKey: string = '') => {
      const { key, product } = selectedProduct;
      const { id = 0, restrictions = {} } = product || {};
      const restrictionValue =
        restrictionKey && restrictions[restrictionKey as keyof typeof restrictions]
          ? restrictions[restrictionKey as keyof typeof restrictions]
          : null!;
      this.setProduct(key, id, 1, restrictionValue, restrictionKey);
    };

    setPreparedProduct(this.selectedSupportProduct, 'support_minutes');

    products = products.concat(this.additionalProducts);

    (this.isAnonymous
      ? this.paymentsService.getSubscriptionGuestPrice(this.currency, null, products, this.selectedCountry)
      : this.paymentsService.getSubscriptionPrice(null, products, this.selectedCountry)
    ).subscribe(
      (res) => {
        this.price = res;
        this.isLoadingPlan = false;
      },
      () => {
        this.notificationService.warning('subscription_buy');
        this.isLoadingPlan = false;
      },
    );
  }

  private savePlanParamsToStorage() {
    const products = {};

    cloneDeep(this.additionalProducts).forEach(
      (product) =>
        ((products[product.key as keyof typeof products] as any) = {
          id: product.id,
          val: product.value,
        }),
    );

    Object.keys(products).length && this.storageHelperService.save(storageKey.paymentPlanParams, products);
  }

  private setOneStepValue(key: PaymentPlanSembotEnum | PaymentPlanCssEnum, isAdd: boolean, isCss: boolean) {
    let config = null;

    if (isCss) {
      config = this.cssConfig;
    } else {
      config = this.sembotConfig[key as keyof SembotConfig];
    }

    if (config) {
      const step = config.step || null;
      const currentValue = Number(this.getFormControl(key).value);

      if (currentValue && step) {
        const newValue = isAdd ? currentValue + step : currentValue - step;

        if (newValue >= config.min && newValue <= config.max) {
          this.getFormControl(key).setValue(newValue);
        }
      } else {
        this.getFormControl(key).setValue(config.min || 1);
      }
    }
  }

  private setProduct(key: string, projectId: number, projectCount: number, value: number, name: string | null = null) {
    const id = Number(projectId);
    const count = Number(projectCount);

    if (id && count) {
      this.additionalProducts.push({ id, count, key, name: name!, value });
    }
  }

  private setRestrictions(restrictions: Restrictions) {
    Object.keys(restrictions).forEach((key: PaymentPlanSembotEnum | any) => {
      if (this.sembotConfig[key as keyof typeof this.sembotConfig]) {
        const basic = restrictions[key as keyof typeof restrictions] || 0;
        (this.sembotConfig[key as keyof typeof this.sembotConfig] as any).basic = basic;
      }
    });
  }

  private setCurrentRestrictions(restrictions: Restrictions) {
    const formGroupControls = {};
    Object.keys(restrictions).forEach(
      (key: PaymentPlanSembotEnum | any) =>
        this.getFormControl(key) &&
        ((formGroupControls[key as keyof typeof formGroupControls] as any) = restrictions[key as keyof typeof restrictions]),
    );

    this.form.patchValue(formGroupControls);
    this.form.markAllAsTouched();
  }
}
