import { Clipboard } from '@angular/cdk/clipboard';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FilterService } from '../../filters/filters.service';
import { ItemModel } from '../../item.model';
import { CustomButtonPerItemInterface } from '../../models/CustomButton.model';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { AutocompleteService } from '../../services/autocomplete.service';
import { ChangesService } from '../../services/changes.service';
import { ColumnsService } from '../../services/columns.service';
import { CommunicationService } from '../../services/communication.service';
import { HelperService } from '../../services/helper.service';
import { PopupService } from '../../services/popup.service';
import { SelectedService } from '../../services/selected.service';
import { ColumnTypesEnum } from '../ColumnTypesEnum';
import { ColumnModel } from './columns/column.model';
import { ColumnsComponentInterface } from './columns/editable-column.component';
import { InnerColumnComponent } from './columns/inner-column/inner-column.component';

@Component({
  selector: 'sem-columns-switch',
  templateUrl: './columns-switch.component.html',
  styleUrls: ['./columns-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsSwitchComponent implements AfterViewInit {
  @ViewChild('container') containerRef!: ElementRef;
  @ViewChild('columnReference') columnReference!: ColumnsComponentInterface;
  @ViewChild('innerColumn') innerColumnReference!: InnerColumnComponent;

  @Input() actionBtnDisplay!: boolean;
  @Input() checked!: boolean;
  @Input() colorCell!: string;
  @Input() colorItem!: string;
  @Input() column!: ColumnModel;
  @Input() config!: TableConfigurationModel;
  @Input() customButtons!: CustomButtonPerItemInterface[];
  @Input() id!: number;
  @Input() index!: number;
  @Input() isEditable!: boolean;
  @Input() isNavigationValue!: boolean;
  @Input() isNewItemRow: boolean = false;
  @Input() item: any;
  @Input() labelCell!: string;
  @Input() labelItem!: string[];
  @Input() shouldCheckEmpty: boolean = true;
  @Input() value: any;

  @Output() actionPanelOpened: EventEmitter<void> = new EventEmitter();
  @Output() aiGeneratedClicked: EventEmitter<void> = new EventEmitter();
  @Output() customButtonClicked: EventEmitter<CustomButtonPerItemInterface> = new EventEmitter();
  @Output() emitCustomEventOnColumn: EventEmitter<{ columnName: string; mode: 'edit' | 'display' }> = new EventEmitter();
  @Output() hideColumnChange: EventEmitter<boolean> = new EventEmitter();
  @Output() infoCellClicked: EventEmitter<void> = new EventEmitter();
  @Output() infoItemClicked: EventEmitter<void> = new EventEmitter();
  @Output() initFilter: EventEmitter<void> = new EventEmitter();
  @Output() navigationClicked: EventEmitter<void> = new EventEmitter();
  @Output() nextColumn: EventEmitter<void> = new EventEmitter();
  @Output() nextRow: EventEmitter<void> = new EventEmitter();
  @Output() openPhotoFromColumn: EventEmitter<string> = new EventEmitter();
  @Output() prevColumn: EventEmitter<void> = new EventEmitter();
  @Output() prevRow: EventEmitter<void> = new EventEmitter();
  @Output() selectWithShift: EventEmitter<ItemModel> = new EventEmitter();
  @Output() selected: EventEmitter<ItemModel> = new EventEmitter();
  @Output() showSnackBar: EventEmitter<string> = new EventEmitter();
  @Output() unSelected: EventEmitter<ItemModel> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  public popupService = inject(PopupService);
  public changesService = inject(ChangesService);
  public helperService = inject(HelperService);
  public columnsService = inject(ColumnsService);
  public selectedService = inject(SelectedService);
  public autocompleteService = inject(AutocompleteService);
  public filterService = inject(FilterService);
  public communicationService = inject(CommunicationService);
  private clipboard = inject(Clipboard);

  columnTypes = ColumnTypesEnum;
  isEditableMode: boolean = false;

  private clickCount = 0;

  @HostListener('keydown', ['$event']) c(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      this.enterEditMode();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      this.enterEditMode();
    } else if (event.key === 'Tab') {
      event.preventDefault();
      this.nextColumn.emit();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.prevRow.emit();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.nextRow.emit();
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.prevColumn.emit();
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.nextColumn.emit();
    }
  }

  ngAfterViewInit(): void {
    if (this.isNewItemRow) {
      setTimeout(() => {
        // @TODO: Zastąpić timeouta jakąś alternatywą..
        this.isEditableMode = true;
        this.enterForcedEditableMode();
      }, 0);
    }
  }

  emitCustomButtonEvent(button: CustomButtonPerItemInterface) {
    this.customButtonClicked.emit(button);
  }

  onClickContainer() {
    this.clickCount++;

    setTimeout(() => {
      this.clickCount === 1 ? this.onSingleClick() : this.onDoubleClick();
      this.clickCount = 0;
    }, 250);
  }

  openPanel(item: ItemModel) {
    this.innerColumnReference.openPanel(item);
  }

  setFocus() {
    this.containerRef.nativeElement.focus();
  }

  setBlur() {
    this.containerRef.nativeElement.blur();
  }

  editableModeChanged(status: any) {
    // status ? this.setBlur() : this.setFocus(); // @TODO: sprawdzić czy ma to rację bytu
    this.isEditableMode = status;
  }

  enterEditMode() {
    this.columnReference && this.columnReference.enterEditableMode();
  }

  enterForcedEditableMode() {
    this.columnReference && this.columnReference.enterForcedEditableMode!();
  }

  copyContent() {
    this.showSnackBar.emit('Text from the cell has been copied');
    this.clipboard.copy(this.value);
  }

  onHideColumnChange(hide: boolean): void {
    this.hideColumnChange.emit(hide);
  }

  private onDoubleClick() {
    this.enterEditMode();
  }

  private onSingleClick() {
    this.isNavigationValue && this.navigationClicked.emit();
  }
}
