import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from '../../../services/config.service';
import { ColumnTypesEnum } from '../../../table-display/ColumnTypesEnum';

@Component({
  selector: 'sem-field-no-include-field',
  templateUrl: './field-no-include-field.component.html',
  styleUrls: ['./field-no-include-field.component.scss'],
})
export class FieldNoIncludeFieldComponent implements OnInit, OnDestroy {
  valueCtrl: UntypedFormControl = new UntypedFormControl('', Validators.required);
  columnsAvailable!: string[];
  columnsToDisplay!: string[];
  @Input() configService!: ConfigService;
  @Input() value!: string;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  private onDestroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.columnsAvailable = Object.values(this.configService.config.columns)
      .filter((column) => column.type !== ColumnTypesEnum.INNER && column.type !== ColumnTypesEnum.IMAGE)
      .map((column) => column.param);

    this.columnsToDisplay = this.columnsAvailable;

    this.valueCtrl.setValue(this.value || '');
    this.valueCtrl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => this.valueChanged.emit('')),
      )
      .subscribe((value) => this.searchColumn(value));
  }

  valueChangedByUser(value: MatAutocompleteSelectedEvent) {
    this.valueChanged.emit(value.option.value);
  }

  searchColumn(string: string) {
    this.columnsToDisplay = this.columnsAvailable.filter((column) => this.filter(column, string));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private filter(string: string, textToSearch: string): boolean {
    const reg = new RegExp(textToSearch, 'gi');
    return string.search(reg) !== -1;
  }
}
