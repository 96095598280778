<app-dialog [dialogRef]="dialogRef">{{ 'sem_table.duplicate_by_column' | translate: { column: column.alias | titlecase } }} </app-dialog>
<mat-dialog-content [cdkTrapFocusAutoCapture]="true" cdkTrapFocus>
  <div class="info-container">
    <div>{{ 'sem_table.duplicate_by_desc1' | translate }}</div>
  </div>

  <div class="info-container">
    <mat-icon>warning</mat-icon>
    <div>{{ 'sem_table.duplicate_by_desc2' | translate }}</div>
  </div>

  <mat-form-field class="tw-w-full">
    <mat-chip-grid #queryRef aria-label="values" color="primary">
      <input
        (matChipInputTokenEnd)="add($event)"
        [matChipInputAddOnBlur]="true"
        [matChipInputFor]="queryRef"
        [matChipInputSeparatorKeyCodes]="[13, 186]"
        cdkFocusInitial
        placeholder="{{ column.alias | titlecase }}"
        (paste)="onPaste($event)"
      />
    </mat-chip-grid>
  </mat-form-field>

  <mat-chip-listbox class="tw-max-w-[1500px]">
    @for (value of values(); track value) {
      <mat-chip-option (removed)="remove(value)" [removable]="true" [selectable]="true">
        {{ value }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }
  </mat-chip-listbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="values()" [disabled]="!values().length" class="submit" color="primary" mat-raised-button>
    {{ 'sem_table.apply' | translate }}
  </button>
</mat-dialog-actions>
