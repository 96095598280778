import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { cloneDeep } from 'lodash';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { debounceTime, filter, skip, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { CUSTOM_MAT_FORM_FIELD_DEFAULT_OPTIONS } from 'src/app/app.module';
import { ActionService } from '../action/action.service';
import { ActionModel } from '../action/models/ActionModel';
import { ConfigGeneratorService } from '../configGenerator.service';
import { FilterLogicOperatorEnum } from '../filters/FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../filters/FilterTypesEnum';
import { FilterService } from '../filters/filters.service';
import { FilterFactoryService } from '../filters/filtersFactory.service';
import { FilterGroupModel, FiltersModel } from '../filters/models/filter.model';
import { QuickFilterService } from '../filters/quick-filters/quick-filter.service';
import { MainToolbarService } from '../main-toolbar/main-toolbar.service';
import { SelectToolMenuItemsProvider } from '../main-toolbar/tool-displayer/select-tool/select-tool-menu-items-provider';
import { ItemEmitterModel, ItemModel } from '../item.model';
import { ApiResponseInterface } from '../models/ApiResponse.model';
import { CustomButtonClickedEvent } from '../models/CustomButtonEvent.model';
import { CustomMessageFromTable } from '../models/CustomMessageFromTable';
import { DuplicateByDataEmittedModel } from '../models/DuplicateByDataEmitted.model';
import { TableChangeDataEmittedInterface } from '../models/TableChangeDataEmitted.model';
import { TableConfigurationModel } from '../models/TableConfiguration.model';
import { TableConfigurationInterface } from '../models/TableConfigurationInterface.model';
import { AutocompleteEventModel } from '../models/autocompleteEvent.model';
import { SingleChangeInterface } from '../models/change.model';
import { PaginationInterface } from '../models/entryPagination.model';
import { RuleModel } from '../models/rule.model';
import { AgregationService } from '../services/agregation.service';
import { AutocompleteService } from '../services/autocomplete.service';
import { ChangesService } from '../services/changes.service';
import { ColumnsService } from '../services/columns.service';
import { CommunicationService } from '../services/communication.service';
import { ConfigService } from '../services/config.service';
import { ExpandModel, ExpansionRowService } from '../services/expansion-row.service';
import { GroupService } from '../services/group.service';
import { HelperService } from '../services/helper.service';
import { PopupService } from '../services/popup.service';
import { SelectedService } from '../services/selected.service';
import { SequenceService } from '../services/sequence.service';
import { SortingService } from '../services/sorting.service';
import { ColumnTypesEnum, ColumnValueTypesEnum } from '../table-display/ColumnTypesEnum';
import { ColumnsMovingService } from '../table-display/columns-moving.service';
import { ActionColumnItemInterface, SelectedActionInterface } from '../table-display/columns-switch/columns/action-column/ActionColumn';
import { ActionColumnService } from '../table-display/columns-switch/columns/action-column/action-column.service';
import { ColumnComponentEventInterface } from '../table-display/columns-switch/columns/editable-column.component';
import { PaginationService } from '../table-display/pagination/pagination.service';

@Component({
  selector: 'sem-table',
  templateUrl: './sem-table.component.html',
  styleUrls: ['./sem-table.component.scss'],
  providers: [
    ActionColumnService,
    ActionService,
    AgregationService,
    AutocompleteService,
    ChangesService,
    ColumnsMovingService,
    ColumnsService,
    CommunicationService,
    ConfigService,
    FilterFactoryService,
    FilterService,
    GroupService,
    PaginationService,
    PopupService,
    QuickFilterService,
    SelectedService,
    SequenceService,
    SortingService,
    MainToolbarService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { ...CUSTOM_MAT_FORM_FIELD_DEFAULT_OPTIONS, subscriptSizing: 'dynamic' } as MatFormFieldDefaultOptions,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SemTableComponent implements OnInit, OnDestroy {
  config!: TableConfigurationModel;
  dataToDisplay!: PaginationInterface<ItemModel>;
  @Output() actionEmitted: EventEmitter<ActionModel> = new EventEmitter();
  @Output() aiGeneratedClicked: EventEmitter<ItemEmitterModel> = new EventEmitter();
  @Output() autocompleteChange: EventEmitter<AutocompleteEventModel> = new EventEmitter();
  @Output() cellClicked: EventEmitter<ItemEmitterModel> = new EventEmitter();
  @Output() changedData: EventEmitter<TableChangeDataEmittedInterface> = new EventEmitter();
  @Output() columnComponentEvent: EventEmitter<ColumnComponentEventInterface> = new EventEmitter();
  @Output() configChanged: EventEmitter<TableConfigurationModel> = new EventEmitter();
  @Output() customButtonClicked: EventEmitter<CustomButtonClickedEvent> = new EventEmitter();
  @Output() customEventOnColumn: EventEmitter<ItemEmitterModel> = new EventEmitter();
  @Output() customMessageEmitted: EventEmitter<CustomMessageFromTable> = new EventEmitter();
  @Output() customPhotoEditorOpened: EventEmitter<ItemEmitterModel> = new EventEmitter();
  @Output() dataToAdd: EventEmitter<ItemModel> = new EventEmitter();
  @Output() dataToDuplicateBy: EventEmitter<DuplicateByDataEmittedModel> = new EventEmitter();
  @Output() dataToRemove: EventEmitter<ItemModel[]> = new EventEmitter();
  @Output() dataToRemoveByFilters: EventEmitter<FilterGroupModel[]> = new EventEmitter();
  @Output() dataToUpdate: EventEmitter<ItemModel[]> = new EventEmitter();
  @Output() dropdownItemClick = new EventEmitter<{ name: string }>();
  @Output() editionStateStart: EventEmitter<void> = new EventEmitter();
  @Output() editionStateStop: EventEmitter<void> = new EventEmitter();
  @Output() filtersChanged: EventEmitter<FiltersModel> = new EventEmitter();
  @Output() groupedByChanged: EventEmitter<string[]> = new EventEmitter();
  @Output() itemDuplicated: EventEmitter<ItemModel> = new EventEmitter();
  @Output() itemInfoClicked: EventEmitter<ItemModel> = new EventEmitter();
  @Output() itemsMultiDuplicated: EventEmitter<ItemModel[]> = new EventEmitter();
  @Output() navigateTo: EventEmitter<ItemModel> = new EventEmitter();
  @Output() navigationClicked: EventEmitter<ItemEmitterModel> = new EventEmitter();
  @Output() newItemRow: EventEmitter<number | null> = new EventEmitter();
  @Output() rowExpanded = new EventEmitter<ExpandModel>();
  @Output() ruleEmitted: EventEmitter<RuleModel> = new EventEmitter();
  @Output() selectedAction: EventEmitter<SelectedActionInterface> = new EventEmitter();
  @Output() selectedItemsChange: EventEmitter<ItemModel[]> = new EventEmitter();
  @Output() sequenceEmitted: EventEmitter<Array<ActionModel[] | ItemModel[]>> = new EventEmitter();
  @Output() singleFieldChanged: EventEmitter<SingleChangeInterface> = new EventEmitter();
  @ViewChild('top') topElement!: ElementRef;
  changePageOnPhotoPopup = false;
  operators = FilterLogicOperatorEnum;
  symbols = FilterTypesEnum;
  columnValueTypes = ColumnValueTypesEnum;
  columnTypes = ColumnTypesEnum;
  loadingState = false;
  preventAutocompleteEnterEvent = false;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() storage: any;
  @Input() actionsList!: (id: number) => Observable<ActionColumnItemInterface[]>;
  @Input() shouldCheckEmpty: boolean = true;
  private onDestroy$ = new Subject<void>();
  private originalData!: PaginationInterface<ItemModel>;
  private newDataReceived: Subject<ItemModel[]> = new Subject();
  private readonly expansionRowService = inject(ExpansionRowService, { optional: true });
  private readonly selectToolMenuItemsProvider = inject(SelectToolMenuItemsProvider, { optional: true });

  constructor(
    private actionColumnService: ActionColumnService,
    private configGeneratorService: ConfigGeneratorService,
    private columnsService: ColumnsService,
    private sortingService: SortingService,
    private agregationService: AgregationService,
    private paginationService: PaginationService,
    private changeDetector: ChangeDetectorRef,
    private helperService: HelperService,
    private sequenceService: SequenceService,
    private groupService: GroupService,
    private popupService: PopupService,
    public autocompleteService: AutocompleteService,
    public selectedService: SelectedService,
    public changesService: ChangesService,
    public configService: ConfigService,
    public actionService: ActionService,
    public communicationService: CommunicationService,
    public filterService: FilterService,
  ) {}

  @Input() set configurate(data: TableConfigurationInterface) {
    this.configService.saveConfig(this.configGeneratorService.getParsedConfig(data));
  }

  @Input() set agreagationData(data: { [key: string]: number }) {
    this.agregationService.totalAgregationData$.next(data);
  }

  @Input() set setGroupBy(data: string) {
    this.groupService.groupedBy$.next([data]);
  }

  @Input() set openInPhotoPopup(data: { item: ItemModel; photoColumn: string }) {
    const { item, photoColumn } = data;
    this.popupService.openPhotoDisplayPopup(photoColumn, item);
  }

  @Input() set newFilters(data: FiltersModel) {
    if (data.filterGroups && data.filterGroups[0]?.filters[0]) {
      data.filterGroups[0].filters[0].operator = FilterLogicOperatorEnum.none;
      this.filterService.setFilters(data);
    } else {
      this.filterService.resetFilters();
    }
  }

  @Input() set data(data: PaginationInterface<any> | null) {
    if (!data) {
      return;
    }
    this.originalData = data;
    if (this.originalData) {
      this.parseItemsToDisplayedVersion();
      this.paginationService.configAfterDataLoad(data);
    }
    if (data && !this.columnsService.isConfigurated && this.config) {
      this.columnsService.configurate(this.config, this.dataToDisplay.data);
    }
    if (data) {
      this.selectedService.recalculate(data.data, data.total!);
      this.newDataReceived.next(data.data);
    } else {
      this.dataToDisplay = { data: [] };
    }
    this.changeDetector.markForCheck();
  }

  @Input() unselectAll() {
    this.selectedService.unselectAll();
  }

  @Input() addDisplayedColumn(column: string) {
    if (!this.columnsService.columnChosenToDisplay.includes(column)) {
      this.columnsService.addColumn(column);
    }
  }

  @Input() removeDisplayedColumn(column: string) {
    if (this.columnsService.columnChosenToDisplay.includes(column)) {
      this.columnsService.removeColumn(column);
    }
  }

  @Input() readOnlyDisable() {
    this.configService.config.readOnly = false;
    this.configService.saveConfig(this.configService.config);
  }

  @Input() readOnlyEnable() {
    this.configService.config.readOnly = true;
    this.configService.saveConfig(this.configService.config);
  }

  @Input() markForCheck() {
    this.changeDetector.markForCheck();
  }

  @Input() enableLoadingState() {
    this.loadingState = true;
    this.changeDetector.markForCheck();
  }

  @Input() disableLoadingState() {
    this.loadingState = false;
    this.changeDetector.markForCheck();
  }

  @Input() saveRule(action?: ActionModel) {
    // TODO: move to parent
    this.popupService.openSaveRulePopup(action);
  }

  @Input() openAddItemPopup() {
    this.popupService.openAddItem(true).subscribe((newItem) => this.dataToAdd.emit(newItem));
  }

  @Input() apiResponsed(response: ApiResponseInterface) {
    this.communicationService.apiResponse$.next(response);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!event.shiftKey && (event.ctrlKey || event.metaKey) && event.code === 'KeyZ') {
      this.changesService.revertChange();
    } else if (event.shiftKey && (event.ctrlKey || event.metaKey) && event.code === 'KeyZ') {
      this.changesService.restoreChange();
    }
  }

  ngOnInit() {
    const comunicationService = this.communicationService;
    merge(this.filterService.filtersInStorage$, this.columnsService.storageColumns$, this.communicationService.tabsChanged$)
      .pipe(takeUntil(this.onDestroy$), throttleTime(100), skip(1))
      .subscribe(() => this.configChanged.emit(this.configService.config));
    comunicationService.navigateTo$.pipe(takeUntil(this.onDestroy$)).subscribe((item) => this.navigateTo.emit(item));

    comunicationService.cellClicked$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => this.cellClicked.emit(data));
    comunicationService.itemInfoClicked$.pipe(takeUntil(this.onDestroy$)).subscribe((item) => this.itemInfoClicked.emit(item));

    comunicationService.itemAdded$.pipe(takeUntil(this.onDestroy$)).subscribe((item) => this.dataToAdd.emit(item));

    comunicationService.ruleEmitted$.pipe(takeUntil(this.onDestroy$)).subscribe((rule) => this.ruleEmitted.emit(rule));

    comunicationService.customButtonClicked$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => this.customButtonClicked.emit(data));
    comunicationService.columnComponentEvent$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => this.columnComponentEvent.emit(data));
    comunicationService.customEventOnColumn$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => this.customEventOnColumn.emit(data));

    comunicationService.aiGeneratedClicked$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => this.aiGeneratedClicked.emit(data));
    comunicationService.navigationClicked$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => this.navigationClicked.emit(data));

    comunicationService.initRemove$.pipe(takeUntil(this.onDestroy$)).subscribe((items) => this.dataToRemove.emit(items));
    comunicationService.initRemoveByFilters$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((filters) => this.dataToRemoveByFilters.emit(filters));

    comunicationService.customButtonOpened$.next(!!this.configService.config?.mainToolbarSlot![0]);

    comunicationService.initDuplicate$.pipe(takeUntil(this.onDestroy$)).subscribe((items) => this.itemDuplicated.emit(items));
    comunicationService.initMultiDuplicate$.pipe(takeUntil(this.onDestroy$)).subscribe((items) => this.itemsMultiDuplicated.emit(items));
    comunicationService.initDuplicateBy$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => this.dataToDuplicateBy.emit(data));
    comunicationService.openPhotoCustomEdit$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => this.customPhotoEditorOpened.emit({ item: data.item, columnName: data.columnName }));

    comunicationService.customMessage$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => this.customMessageEmitted.emit(data));

    this.configService.config$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((config) => (this.config = config)),
        tap(() => this.filterService.saveRecentFiltersInMemory()),
        skip(1),
        tap((config) => this.configChanged.emit(config)),
      )
      .subscribe();

    combineLatest(
      this.filterService.filters$.pipe(tap((filters) => this.filtersChanged.emit(filters))),
      this.sortingService.sorting$.pipe(tap((sorting) => this.configService.changeSorting(sorting))),
      this.paginationService.page$,
      this.groupService.groupedBy$
        .pipe
        // tap((groupedBy) => this.configService.changeGroupBy(groupedBy)),
        // tap((groupedBy) => this.groupedByChanged.emit(groupedBy)),
        (),
    )
      .pipe(takeUntil(this.onDestroy$), debounceTime(20))
      .subscribe((data) => {
        this.changedData.emit({
          filterGroups: data[0].filterGroups,
          sorting: data[1] ? data[1] : { columnName: null, direction: null },
          itemsPerPage: data[2].itemsPerPage,
          currentPage: data[2].currentPageIndex,
          groupedBy: data[3],
          filterManualChanges: data[0].filterManualChanges,
        });
      });

    this.changesService.changeList$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(() => !!this.config),
        skip(1),
        tap((changes) => {
          if (changes.length) {
            this.editionStateStart.emit();
          } else {
            this.editionStateStop.emit();
          }
        }),
      )
      .subscribe();

    this.changesService.singleChange$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(() => !!this.config),
        // skip(1), // Po kiego ten skip tutaj?! @TODO: do sprawdzenia czy coś sie nie zrypało
        tap((change) => {
          if (change.item || change.isNewItemRow) {
            this.singleFieldChanged.emit(change);
          }
        }),
      )
      .subscribe();

    this.actionService.globalActions$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.parseItemsToDisplayedVersion());
    this.changesService.clearUpdates$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.parseItemsToDisplayedVersion());
    comunicationService.revertAction$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.parseItemsToDisplayedVersion());
    comunicationService.restoreAction$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.parseItemsToDisplayedVersion());

    comunicationService.itemChanged$.pipe(takeUntil(this.onDestroy$)).subscribe((item) => this.changeItemsInTable([item]));

    this.autocompleteService.initAutocompleteChange$
      .pipe
      // debounceTime(500),
      ()
      .subscribe((value) => this.autocompleteChange.emit(value));

    this.selectedService.selectedItems$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((selectedItems) => this.selectedItemsChange.emit(selectedItems));

    this.actionColumnService.openActionsList$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (taskId) =>
          taskId &&
          this.actionsList(taskId as number).subscribe((actionsList: ActionColumnItemInterface[]) =>
            this.actionColumnService.setActionsList(actionsList),
          ),
      );

    this.actionColumnService.selectedAction$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((action: SelectedActionInterface) => this.selectedAction.emit(action));

    this.expansionRowService?.expansion$.pipe(takeUntil(this.onDestroy$)).subscribe((expansion) => this.rowExpanded.emit(expansion));
    this.selectToolMenuItemsProvider?.itemClicked$.pipe(takeUntil(this.onDestroy$)).subscribe((item) => this.dropdownItemClick.emit(item));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private parseItemsToDisplayedVersion() {
    const result = cloneDeep(this.originalData);
    this.sequenceService.sequence.forEach((element) => {
      if (this.sequenceService.sequenceWithGlobalAction(element)) {
        const action = element[0] as ActionModel;
        result.data.map((item) => {
          const dataChanged = this.actionService.returnDataChangedByAction(item, action);
          return ((item[action.columnName as keyof typeof item] as any) = dataChanged[action.columnName as keyof typeof dataChanged]);
        });
      } else {
        const items = cloneDeep(element as ItemModel[]);
        items.forEach((itemUpdated) => {
          if (this.helperService.isInArray(itemUpdated, result.data)) {
            const i = this.helperService.returnIndexInArray(itemUpdated, result.data);
            result.data[i] = itemUpdated;
          }
        });
      }
    });

    if (result && result.data && this.dataToDisplay && this.dataToDisplay.data) {
      this.changeItemsInTable(result.data);
    }
    this.dataToDisplay = result || null;
  }

  private changeItemsInTable(items: ItemModel[]) {
    items.forEach((item) => {
      const i = this.helperService.returnIndexInArray(item, this.dataToDisplay.data);
      this.dataToDisplay.data[i] = { ...item };
      this.dataToDisplay.data = [...this.dataToDisplay.data];
      this.selectedService.changeItemInTable(item);
    });
  }
}
