import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { DynamicValue } from '../../models/DynamicValue.model';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { HelperService } from '../../services/helper.service';
import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';

@Component({
  selector: 'sem-value-chooser',
  templateUrl: './value-chooser.component.html',
  styleUrls: ['./value-chooser.component.scss'],
})
export class ValueChooserComponent implements OnInit, OnDestroy {
  @Input() config!: TableConfigurationModel;
  @Output() valueEmitted: EventEmitter<DynamicValue> = new EventEmitter();
  propertiesToChoose!: ColumnModel[];
  propertySearchCtrl = new UntypedFormControl();
  propertyCtrl = new UntypedFormControl();
  private onDestroy$: Subject<void> = new Subject();
  private allColumns!: ColumnModel[];
  @ViewChild('input') input!: ElementRef;

  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.getAllColumns();

    this.propertySearchCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$), debounceTime(400), startWith(''))
      .subscribe((query) => (this.propertiesToChoose = this.findColumn(query)));

    this.propertyCtrl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((property) => this.chooseColumn(property));
  }

  chooseValue(value: string) {
    this.valueEmitted.emit({ fromItem: false, value });
    this.input.nativeElement.value = '';
  }

  private getAllColumns() {
    const { columns } = this.config;

    this.allColumns = Object.keys(columns)
      .filter((key) => columns[key].editable && columns[key].type !== ColumnTypesEnum.IMAGE)
      .map((key) => columns[key]);
  }

  private findColumn(query: string | null = null): ColumnModel[] {
    return this.helperService.search(query, this.allColumns, 'alias');
  }

  private chooseColumn(column: ColumnModel) {
    this.valueEmitted.emit({ fromItem: true, itemPropertyName: column.param, value: column.param });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
