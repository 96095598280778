import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { HelperService } from '../../services/helper.service';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';

@Component({
  selector: 'sem-column-chooser-input',
  templateUrl: './column-chooser-input.component.html',
  styleUrls: ['./column-chooser-input.component.scss'],
})
export class ColumnChooserInputComponent implements OnInit {
  columnSearchCtrl = new UntypedFormControl('');
  columnsToChoose!: ColumnModel[];
  @Input() columnCtrl!: UntypedFormControl;
  @Input() columns!: ColumnModel[];
  private onDestroy$ = new Subject<void>();

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {
    this.columnSearchCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$), debounceTime(400), startWith(''))
      .subscribe((query) => (this.columnsToChoose = this.findColumn(query)));
  }

  compareByAlias(a?: ColumnModel, b?: ColumnModel): boolean {
    return a?.alias === b?.alias;
  }

  private findColumn(query: string | null = null): ColumnModel[] {
    return this.helperService.search(query, this.columns, 'alias');
  }
}
