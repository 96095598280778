import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { isPresent } from 'src/app/shared/helpers/utils';
import { FilterService } from '../../filters/filters.service';
import { FilterGroupModel } from '../../filters/models/filter.model';
import { DynamicValue } from '../../models/DynamicValue.model';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { AutocompleteService } from '../../services/autocomplete.service';
import { SelectedService } from '../../services/selected.service';
import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionSwitchComponent } from '../action-switch/action-switch.component';
import { ActionService } from '../action.service';
import { ActionModel } from '../models/ActionModel';
import { ActionFormEmittedDataInterface } from '../models/ActionPopupEmittedData.interface';
import { MatDialog } from '@angular/material/dialog';
import { ManualChangesWarningComponent } from './manual-changes-warning/manual-changes-warning.component';
import { LocalStorageService, storageKey } from 'src/app/shared/service/local-storage.service';

@Component({
  selector: 'sem-action-form',
  templateUrl: './action-form.component.html',
  styleUrls: ['./action-form.component.scss'],
})
export class ActionFormComponent implements OnInit, OnDestroy {
  filterGroups: FilterGroupModel[] | null = null;
  columnCtrl = new UntypedFormControl(null, Validators.required);
  columns!: ColumnModel[];
  typeChosenCtrl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  actionTypes = ActionTypesEnum;
  @ViewChild(ActionSwitchComponent) actionSwitchComponent!: ActionSwitchComponent;
  @Input() action: ActionModel | null = null;
  @Input() actionService!: ActionService;
  @Input() config!: TableConfigurationModel;
  @Input() filterService!: FilterService;
  @Input() autocompleteService!: AutocompleteService;
  @Input() selectService!: SelectedService;
  @Output() actionCreated: EventEmitter<ActionFormEmittedDataInterface> = new EventEmitter();
  @Input() state!: {
    saveAndUse: boolean;
    saveRule: boolean;
    useAction: boolean;
  };

  @Input() set columnValue(column: ColumnModel) {
    this.columnCtrl.setValue(column);
  }

  @Input() set typeChoosenValue(typeChoosenValue: ActionTypesEnum) {
    this.typeChosenCtrl.enable();
    this.typeChosenCtrl.setValue(typeChoosenValue);
  }

  readonly dialog = inject(MatDialog);
  readonly localStorageService = inject(LocalStorageService);

  private onDestroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.getAllColumns();

    this.filterService.filters$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((filters) => (this.filterGroups = filters.filterGroups)),
      )
      .subscribe();

    this.columnCtrl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((column) => this.changeColumn(column));
    this.typeChosenCtrl.valueChanges.pipe(filter(isPresent), takeUntil(this.onDestroy$)).subscribe((type) => this.changeAction(type));
  }

  remove(value: DynamicValue): void {
    const index = this.actionSwitchComponent.valueGeneratorValues.indexOf(value);
    if (index >= 0) {
      this.actionSwitchComponent.valueGeneratorValues.splice(index, 1);
    }
  }

  resetFilters() {
    this.filterService.resetFilters();
    this.initData();
  }

  drop(event: CdkDragDrop<DynamicValue[]>) {
    moveItemInArray(this.actionSwitchComponent.valueGeneratorValues, event.previousIndex, event.currentIndex);
    this.actionSwitchComponent.setValueInAction(this.actionSwitchComponent.valueGeneratorValues);
  }

  removeAllDynamicValues() {
    if (!this.actionSwitchComponent) {
      return;
    }
    this.actionSwitchComponent.removeAllDynamicValues();
  }

  changeAction(type: ActionTypesEnum) {
    this.removeAllDynamicValues();
    this.action = this.actionService.createAction(type, this.columnCtrl.value, this.filterService.groups);
  }

  setDataOnInit(initAction: ActionModel) {
    this.action = initAction;
    this.filterGroups = initAction.filterGroups;
    this.columnCtrl.setValue(this.config.columns[this.action.columnName]);
  }

  submit(isRule: boolean, isAction: boolean) {
    if (this.action && this.action.isValid()) {
      this.actionCreated.emit({ action: this.action, isAction, isRule });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openDialog(action: ActionModel): void {
    const isManualWarningSkipped = this.localStorageService.get(storageKey.manualChangesWarningSkip) === 'true';
    const isAddAction = this.action?.type === ActionTypesEnum.addEnd || this.action?.type === ActionTypesEnum.addStart;

    if (isManualWarningSkipped || !isAddAction) {
      return;
    }

    const dialogRef = this.dialog.open(ManualChangesWarningComponent, {
      width: '700px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      action.override_manual_changes = result;
    });
  }

  private getAllColumns() {
    const { columns } = this.config;
    this.columns = Object.keys(columns)
      .filter((key) => columns[key].editable && columns[key].type !== ColumnTypesEnum.IMAGE)
      .map((key) => columns[key]);
  }

  private changeColumn(column: ColumnModel) {
    this.typeChosenCtrl.enable();
    this.typeChosenCtrl.setValue(column.actionsAvailableTypes[0]);
  }
}
