import { Component, Input, OnInit, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ColumnModel } from '../../../table-display/columns-switch/columns/column.model';
import { FilterLogicOperatorEnum } from '../../FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../../FilterTypesEnum';
import { FilterFactoryService } from '../../filtersFactory.service';
import { QuickFilterService } from '../quick-filter.service';

@Component({
  selector: 'sem-search-tool',
  templateUrl: './search-tool.component.html',
  styleUrls: ['./search-tool.component.scss'],
})
export class SearchToolComponent implements OnInit {
  form!: UntypedFormGroup;
  @Input() column!: ColumnModel;

  private readonly fb = inject(UntypedFormBuilder);
  private readonly filterFactoryService = inject(FilterFactoryService);
  private readonly quickFilterService = inject(QuickFilterService);

  get searchValue(): UntypedFormControl {
    return this.form.get('searchValue') as UntypedFormControl;
  }

  ngOnInit() {
    this.form = this.fb.group({
      searchValue: [],
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const filterIndexInLastGroup = this.quickFilterService.getFilterIndexInLastGroup(this.column);
    this.createFilter(this.searchValue.value, filterIndexInLastGroup);
    this.searchValue.reset();
  }

  private createFilter(data: string, filterIndexInLastGroup: number) {
    const { column } = this;
    const filterOperator = filterIndexInLastGroup > 0 ? FilterLogicOperatorEnum.and : FilterLogicOperatorEnum.none;
    const filter = this.filterFactoryService.createFilter(column, FilterTypesEnum.include, data, filterOperator, false);
    this.quickFilterService.setFilter(filterIndexInLastGroup, filter);
  }
}
