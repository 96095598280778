@if (dataset.length) {
  <apx-chart
    [chart]="chartOptions.chart"
    [colors]="chartOptions.colors"
    [fill]="chartOptions.fill"
    [labels]="chartOptions.labels"
    [plotOptions]="chartOptions.plotOptions"
    [series]="chartOptions.series"
    [tooltip]="chartOptions.tooltip"
  >
  </apx-chart>
}
