<!-- <button mat-stroked-button (click)="customButtonClicked(button)" [ngClass]="button.className" *ngFor="let button of mainToolbarButtons">
  <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon> {{ button.alias }}
</button> -->
<button #trigger="matMenuTrigger" *ngIf="mainToolbarButtons[0]" [matMenuTriggerFor]="buttonsMenu" class="trigger-btn" mat-stroked-button>
  <span class="action-button">
    {{ 'general.actions' | translate }}
    <mat-icon>arrow_drop_down</mat-icon>
  </span>
</button>

<mat-menu #buttonsMenu="matMenu">
  <ng-template matMenuContent>
    <button (click)="customButtonClicked(button)" *ngFor="let button of mainToolbarButtons" [ngClass]="button.className" mat-menu-item>
      <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
      {{ button.alias }}
    </button>
  </ng-template>
</mat-menu>
