<div class="container">
  <div *ngFor="let column of columnsToFiler" class="filter">
    <ng-container [ngSwitch]="column.type">
      <p *ngSwitchCase="columnTypes.SIMPLE" class="form-field">
        <sem-search-tool [column]="column"></sem-search-tool>
      </p>
      <p *ngSwitchDefault class="form-field">
        <sem-quick-filter-with-select [column]="column"></sem-quick-filter-with-select>
      </p>
    </ng-container>
  </div>
  <button (click)="openFilters()" *ngIf="areFiltersAvailable" class="form-field" mat-stroked-button>
    <mat-icon>add</mat-icon>
    {{ 'sem_table.more_filters' | translate }}
  </button>
</div>
