<sem-filter-operator *ngIf="index > 0" [operatorCtrl]="operatorCtrl"></sem-filter-operator>

<div class="container">
  <div class="header-container">
    <span class="group-name"> {{ 'sem_table.filter_group' | translate | titlecase }} #{{ index + 1 }} </span>
    <button (click)="removeGroup()" class="remove-icon" mat-icon-button>
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div class="filters-container">
    <div *ngIf="filtersArray.length === 0">{{ 'sem_table.empty_filters_group' | translate }}</div>
    <sem-single-filter-form
      (removed)="catchFilterRemove(index)"
      *ngFor="let filter of filtersArray.controls; let isFirst = first; let index = index"
      [autocompleteService]="autocompleteService"
      [columns]="columns"
      [filterFactoryService]="filterFactoryService"
      [filterForm]="filter"
      [filter]="filter"
      [isFirst]="isFirst"
    ></sem-single-filter-form>
  </div>

  <div class="next-filter-btn">
    <button (click)="addNextFilter()" color="primary" mat-raised-button>
      <mat-icon>add</mat-icon>
      <span>{{ 'sem_table.add_next_filter' | translate }}</span>
    </button>
  </div>
</div>
