import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';

@Component({
  selector: 'sem-contains',
  templateUrl: './contains.component.html',
  styleUrls: ['./contains.component.scss'],
})
export class ContainsComponent implements OnDestroy, OnInit {
  @Input() valueCtrl: UntypedFormControl = new UntypedFormControl();
  inputCtrl: UntypedFormControl = new UntypedFormControl();
  value!: string[];
  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
  private onDestroy$: Subject<void> = new Subject();

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    setTimeout(() => this.prepareFilter());
  }

  keyup(event: KeyboardEvent) {
    if (!this.separatorKeysCodes.includes(event.keyCode)) {
      return;
    }
    const res = this.prepareValueToSet(this.inputCtrl.value);

    this.setValue(res);
    this.clearInput();
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    const { clipboardData } = event;
    const pastedData = this.mapValueFromString(clipboardData!.getData('Text'));
    const values = this.valueCtrl.value;
    pastedData.forEach((data) => data && !values.includes(data) && values.push(data));
    this.setValue(values);
  }

  add(event: MatChipInputEvent) {
    const { value } = event;

    const res = this.prepareValueToSet(value);
    this.clearInput();
    this.setValue(res);
  }

  onBlur() {
    const res = this.prepareValueToSet(this.inputCtrl.value);
    this.clearInput();
    this.setValue(res);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private prepareFilter() {
    if (!this.valueCtrl.value) {
      this.value = [];
      this.valueCtrl.setValue(this.value);
    } else if (typeof this.valueCtrl.value === 'string') {
      this.value = this.mapValueFromString(this.valueCtrl.value);
      this.valueCtrl.setValue(this.value);
    } else if (Array.isArray(this.valueCtrl.value)) {
      this.value = this.valueCtrl.value;
    }
  }

  private mapValueFromString(value: string): string[] {
    return value.split(/\r?\n|,|;/);
  }

  private prepareValueToSet(value: string): string[] {
    const values = this.valueCtrl.value ? cloneDeep(this.valueCtrl.value) : [];
    if ((value || '').trim() && !values.includes(value)) {
      values.push(value.trim());
    }
    return values;
  }

  private setValue(value: string[]) {
    this.valueCtrl.setValue(value);
  }

  private clearInput() {
    this.inputCtrl.setValue('');
  }
}
