<mat-tab-group>
  <mat-tab label="Static value">
    <form (ngSubmit)="chooseValue(input.value)">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Type value</mat-label>
        <input
          cdkTrapFocus
          [cdkTrapFocusAutoCapture]="true"
          #input
          matInput
          autocomplete="off"
          required
          placeholder="Type value"
          name="value"
        />
      </mat-form-field>
    </form>
  </mat-tab>
  <mat-tab label="Property">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Type value</mat-label>
      <mat-select [formControl]="propertyCtrl">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="propertySearchCtrl"
            [placeholderLabel]="'search'"
            [noEntriesFoundLabel]="'not found'"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let column of propertiesToChoose" [value]="column">{{ column.alias }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-tab>
</mat-tab-group>
