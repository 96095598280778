import { Injectable } from '@angular/core';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';
import { FilterService } from '../filters.service';
import { FilterModel } from '../models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class QuickFilterService {
  constructor(private filterService: FilterService) {}

  getLastGroupIndex() {
    const lastGroup = this.filterService.groups[this.filterService.groups.length - 1];
    return lastGroup;
  }

  getFilterIndexInLastGroup(column: ColumnModel): number {
    const lastGroup = this.getLastGroupIndex();
    let filterIndexInLastGroup = -1;

    if (lastGroup) {
      filterIndexInLastGroup = lastGroup.filters.findIndex((f) => f.param === column.param);
    }

    return filterIndexInLastGroup;
  }

  setFilter(filterIndexInLastGroup: number, filter: FilterModel) {
    if (filterIndexInLastGroup > -1) {
      this.filterService.groups[this.filterService.groups.length - 1].filters[filterIndexInLastGroup] = filter;
      this.filterService.setFilters({
        filterGroups: this.filterService.groups,
        filterManualChanges: this.filterService.filterManualChanges,
      });
    } else {
      this.filterService.addFilterToLastGroup(filter);
    }
  }
}
