import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutocompleteEventModel } from '../../../models/autocompleteEvent.model';
import { ColumnModel } from '../../../table-display/columns-switch/columns/column.model';
import { ReplaceAction } from '../../models/ReplaceAction';

@Component({
  selector: 'sem-replace-autocomplete',
  templateUrl: './replace-autocomplete.component.html',
  styleUrls: ['./replace-autocomplete.component.scss'],
})
export class ReplaceAutocompleteComponent {
  @Input() action!: ReplaceAction;
  @Input() column!: ColumnModel;
  @Output() autocompleteChanged: EventEmitter<AutocompleteEventModel> = new EventEmitter();

  changeAutocomplete(changedEvent: AutocompleteEventModel) {
    this.autocompleteChanged.emit(changedEvent);
  }
}
