import { Pipe, PipeTransform } from '@angular/core';
import { mainTableColorCellPrefix } from '../../enums';
import { ItemModel } from '../../item.model';

@Pipe({
  name: 'cellColor',
})
export class CellColorPipe implements PipeTransform {
  transform(item: ItemModel, param: string): string {
    return item[(mainTableColorCellPrefix + param) as keyof typeof item] as string;
  }
}
