<!--<span-->
<!--  *ngIf="index >= config.indexFirstResizingElement"-->
<!--  [displayedColumns]="columnsService.displayedColumns$ | async"-->
<!--  [index]="index"-->
<!--  class="resize-element"-->
<!--&gt;-->
<!--</span>-->

<div
  #container
  (click)="onClickContainer()"
  [ngClass]="{ inner: column.type === columnTypes.INNER, notEditable: !column.editable, 'navigation-column': isNavigationValue }"
  [tabindex]="column.type === columnTypes.INNER ? -1 : 0"
  class="container"
>
  <!--------------------           icons inside columns                 ---------------------->

  <ng-container *ngIf="column.type !== columnTypes.INNER; else emptyIcons">
    <div class="item-info">
      <mat-icon
        (click)="infoCellClicked.next()"
        *ngIf="labelCell"
        [matTooltip]="labelCell"
        [ngStyle]="{ color: colorCell }"
        matTooltipClass="label-tooltip"
        matTooltipPosition="right"
        >info
      </mat-icon>
    </div>
  </ng-container>

  <ng-template #emptyIcons>
    <div></div>
  </ng-template>

  <div
    (click)="infoCellClicked.next()"
    *ngIf="labelCell"
    [matTooltip]="labelCell"
    [ngStyle]="{ 'border-top-color': colorCell }"
    class="label-cell"
    matTooltipClass="label-tooltip"
    matTooltipPosition="right"
  ></div>
  <!--------------------           columns Switch                 ---------------------->
  <ng-container *ngIf="!column.component; else componentColumn" [ngSwitch]="column.type">
    <!--------------------           INNER column                 ---------------------->
    <span *ngSwitchCase="columnTypes.INNER">
      <sem-inner-column
        #innerColumn
        (actionPanelOpened)="actionPanelOpened.emit()"
        (emitCustomButtonEvent)="emitCustomButtonEvent($event)"
        (hideColumn)="onHideColumnChange($event)"
        (infoItemClicked)="infoItemClicked.emit()"
        (selectWithShift)="selectWithShift.emit()"
        (selected)="selected.emit()"
        (unSelected)="unSelected.emit()"
        [actionBtnDisplay]="actionBtnDisplay"
        [checked]="checked"
        [colorItem]="colorItem"
        [column]="$any(column)"
        [config]="config"
        [item]="item"
        [labelItem]="labelItem"
        [shouldCheckEmpty]="shouldCheckEmpty"
      ></sem-inner-column>
    </span>

    <!--------------------           image column                 ---------------------->
    <span *ngSwitchCase="columnTypes.IMAGE" class="value-container image">
      <sem-image-column
        #columnReference
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (openPhotoFromColumn)="openPhotoFromColumn.emit($event)"
        (valueChanged)="valueChanged.emit($event)"
        [column]="$any(column)"
        [displayNotEditable]="!isEditable"
        [value]="value"
        class="column-component"
      >
      </sem-image-column>
    </span>

    <!--------------------           boolean columns                 ---------------------->
    <span *ngSwitchCase="columnTypes.BOOLEAN" class="value-container">
      <sem-boolean-column
        #columnReference
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (valueChanged)="valueChanged.emit($event)"
        [column]="$any(column)"
        [displayNotEditable]="!isEditable"
        [value]="value"
        class="column-component"
      >
      </sem-boolean-column>
    </span>

    <!--------------------           autocomplete columns                 ---------------------->
    <span *ngSwitchCase="columnTypes.AUTOCOMPLETE" class="value-container">
      <sem-autocomplete-column
        #columnReference
        (autocompleteChanged)="autocompleteService.initAutocompleteChange$.next($event)"
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (valueChanged)="valueChanged.emit($event)"
        [column]="$any(column)"
        [displayNotEditable]="!isEditable"
        [value]="value"
        class="column-component"
      >
      </sem-autocomplete-column>
    </span>

    <!--------------------           custom columns                 ---------------------->
    <span *ngSwitchCase="columnTypes.CUSTOM" class="value-container">
      <sem-custom-column
        #columnReference
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (emitCustomEvent)="emitCustomEventOnColumn.emit($event)"
        (valueChanged)="valueChanged.emit($event)"
        [autocompleteService]="autocompleteService"
        [column]="$any(column)"
        [displayNotEditable]="!isEditable"
        [popupService]="popupService"
        [value]="value"
        class="column-component"
      >
      </sem-custom-column>
    </span>

    <!--------------------           Select columns                 ---------------------->
    <span *ngSwitchCase="columnTypes.SELECT" class="value-container">
      <sem-select-column
        #columnReference
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (valueChanged)="valueChanged.emit($event)"
        [column]="$any(column)"
        [displayNotEditable]="!isEditable"
        [value]="value"
        [withNullValue]="true"
        class="column-component"
      >
      </sem-select-column>
    </span>

    <!--------------------           Date columns                 ---------------------->
    <span *ngSwitchCase="columnTypes.DATE" class="value-container">
      <sem-date-column
        #columnReference
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (valueChanged)="valueChanged.emit($event)"
        [column]="$any(column)"
        [displayNotEditable]="!isEditable"
        [value]="value"
        class="column-component"
      >
      </sem-date-column>
    </span>

    <!--------------------           Simple columns                 ---------------------->
    <span *ngSwitchCase="columnTypes.SIMPLE" class="value-container can-selected">
      <sem-simple-column
        #columnReference
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (navigationColumn)="navigationClicked.emit()"
        (valueChanged)="valueChanged.emit($event)"
        [column]="$any(column)"
        [displayNotEditable]="!isEditable"
        [value]="value"
        class="column-component"
      >
      </sem-simple-column>
    </span>

    <span *ngSwitchCase="columnTypes.FILESIZE" class="value-container can-selected">
      <sem-filesize-column #columnReference [value]="value" class="column-component"> </sem-filesize-column>
    </span>
  </ng-container>

  <!--------------------           Component columns                 ---------------------->
  <ng-template #componentColumn>
    <span class="value-container component-column">
      <sem-component-column
        #columnReference
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (valueChanged)="valueChanged.emit($event)"
        [column]="column"
        [component]="$any(column.component)"
        [data]="{ item, value }"
        class="column-component"
      ></sem-component-column>
    </span>
  </ng-template>

  <ng-container *ngIf="!isEditableMode">
    <div *ngIf="isNavigationValue; else helpingIcons" class="navigation-btn helping-icons">
      <div class="content">
        <mat-icon (click)="$event.stopPropagation(); enterEditMode()" *ngIf="isEditable">edit</mat-icon>
        <mat-icon class="strong">chevron_right</mat-icon>
      </div>
    </div>

    <ng-template #helpingIcons>
      <div
        *ngIf="
          column.type !== columnTypes.INNER && ((customButtons && customButtons.length > 0) || (column.ableToFilter && value) || isEditable)
        "
        class="helping-icons"
      >
        <div class="content">
          <mat-icon
            (click)="emitCustomButtonEvent(button)"
            *ngFor="let button of customButtons"
            [svgIcon]="button.svgIcon || null!"
            class="icon"
            >{{ button.icon || '' }}
          </mat-icon>
          <mat-icon (click)="copyContent()" *ngIf="column.canCopy || column.type === columnTypes.SIMPLE">content_copy </mat-icon>
          <mat-icon (click)="aiGeneratedClicked.emit()" *ngIf="column.canAiGenerated" class="icon" svgIcon="ai"></mat-icon>
          <mat-icon (click)="initFilter.emit()" *ngIf="column.ableToFilter && value && !column.disableDirectFilter"> filter_alt </mat-icon>
          <mat-icon (click)="$event.stopPropagation(); enterEditMode()" *ngIf="isEditable">edit</mat-icon>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <div class="empty"></div>
</div>
