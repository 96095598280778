import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AutocompleteEventModel } from '../models/autocompleteEvent.model';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  initAutocompleteChange$: Subject<AutocompleteEventModel> = new Subject();
}
