import { Observable, Subject } from 'rxjs';

export abstract class SelectToolMenuItemsProvider {
  private readonly clickSubject = new Subject<{ name: string }>();

  readonly itemClicked$ = this.clickSubject.asObservable();

  abstract items$: Observable<{ name: string }[]>;

  itemClick(item: { name: string }): void {
    this.clickSubject.next(item);
  }
}
