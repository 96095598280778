<div [ngSwitch]="filterType" class="container">
  <div *ngSwitchCase="types.is_uppercase" @appearAnimation class="value-container"></div>

  <div *ngSwitchCase="types.is_empty" @appearAnimation class="value-container"></div>

  <div *ngSwitchCase="types.not_empty" @appearAnimation class="value-container"></div>

  <ng-container *ngSwitchCase="types.has_manual_changes">
    <div (click)="slide.toggle()" class="value-slide">{{ 'sem_table.false' | translate | titlecase }}</div>
    <mat-slide-toggle #slide [formControl]="dataCtrl" color="primary" matNativeControl name="value"></mat-slide-toggle>
    <div (click)="slide.toggle()" class="value-slide">{{ 'sem_table.true' | translate | titlecase }}</div>
  </ng-container>

  <!--------------------         not include field      -------------------------------->
  <!-- TODO: Sprawdzić czy jest to potrzebne [configService]="data.configService" -->
  <!--  [configService]="data.configService"-->

  <div *ngSwitchCase="types.field_not_include_field" @appearAnimation class="value-container">
    <sem-field-no-include-field (valueChanged)="setValueInFilter($event)" [value]="dataCtrl.value"></sem-field-no-include-field>
  </div>

  <!--------------------         exists_in / not_exists_in     -------------------------------->
  <div *ngSwitchCase="[types.exists_in, types.not_exists_in] | switchMultiCase: filterType" @appearAnimation class="value-container">
    <ng-container [ngSwitch]="column.type">
      <sem-contains *ngSwitchCase="columnTypes.SIMPLE" [valueCtrl]="dataCtrl"></sem-contains>
      <sem-contains *ngSwitchCase="columnTypes.AUTOCOMPLETE" [valueCtrl]="dataCtrl"></sem-contains>
      <div *ngSwitchDefault>not implemented for {{ column.type }}</div>
    </ng-container>
  </div>

  <div *ngSwitchCase="[types.in_array] | switchMultiCase: filterType" @appearAnimation class="value-container">
    <ng-container [ngSwitch]="column.type">
      <ng-container *ngSwitchCase="columnTypes.CHIP_SELECT">
        <sem-select-chip-column (userSelected)="selectUser($event)" [assignedUsers]="selectedUsers" [users]="(users$ | async) ?? []">
        </sem-select-chip-column>
      </ng-container>
    </ng-container>
  </div>

  <!--------------------         include_one_of     -------------------------------->
  <div *ngSwitchCase="types.include_one_of" @appearAnimation class="value-container">
    <sem-contains [valueCtrl]="dataCtrl"></sem-contains>
  </div>

  <div *ngSwitchDefault @appearAnimation [ngClass]="{ 'slide-container': column.type === columnTypes.BOOLEAN }" class="value-container">
    <ng-container [ngSwitch]="column.type">
      <ng-container *ngSwitchCase="columnTypes.AUTOCOMPLETE">
        <sem-autocomplete-column
          #columnReference
          (autocompleteChanged)="autocompleteEmitChange($event)"
          (valueChanged)="setValueInFilter($event)"
          [column]="$any(column)"
          [displayEditable]="true"
          [editableMode]="true"
          [value]="dataCtrl.value"
          class="columnComponent"
          formFieldAppearance="outline"
        >
        </sem-autocomplete-column>
      </ng-container>

      <ng-container *ngSwitchCase="columnTypes.SELECT">
        <mat-form-field appearance="outline">
          <mat-select (valueChanged)="setValueInFilter($event)" [formControl]="dataCtrl" class="columnComponent">
            <ng-container *ngFor="let option of $any(column).possibleValues">
              <mat-option *ngIf="option" [value]="option">{{ option | mapColumnValue: column }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="columnTypes.BOOLEAN">
        <div (click)="slide.toggle()" class="value-slide">{{ 'sem_table.false' | translate | titlecase }}</div>
        <mat-slide-toggle #slide [formControl]="dataCtrl" color="primary" matNativeControl name="value"></mat-slide-toggle>
        <div (click)="slide.toggle()" class="value-slide">{{ 'sem_table.true' | translate | titlecase }}</div>
      </ng-container>

      <!-- TODO: Add date picker [displayOnlyEditable]="true" -->
      <!--      [displayOnlyEditable]="true"-->

      <ng-container *ngSwitchCase="columnTypes.DATE">
        <input
          (dateTimeChange)="setDateValue($event)"
          [name]="column.param"
          [ngModel]="dataCtrl.value"
          [owlDateTimeTrigger]="dt1"
          [owlDateTime]="dt1"
          [placeholder]="column.alias"
          [selectMode]="'range'"
          class="data-picker"
        />
        <owl-date-time #dt1></owl-date-time>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <mat-form-field appearance="outline">
          <input
            [appOnlyNumber]="column.valueType === columnValueTypes.number"
            [formControl]="dataCtrl"
            [name]="column.param"
            [placeholder]="'sem_table.value' | translate | titlecase"
            matInput
            required
          />
        </mat-form-field>
      </ng-container>
    </ng-container>
  </div>
</div>

<sem-case-size-config
  *ngIf="
    filterType !== types.is_empty &&
    filterType !== types.field_not_include_field &&
    filterType !== types.not_empty &&
    filterType !== types.is_uppercase &&
    filterType !== types.include_one_of &&
    filterType !== types.exists_in &&
    filterType !== types.not_exists_in &&
    filterType !== types.regexp &&
    filterType !== types.in_array &&
    filterType !== types.has_manual_changes &&
    column.valueType === columnValueTypes.text
  "
  [caseCtrl]="configCtrl"
></sem-case-size-config>
