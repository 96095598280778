import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from '../../material-module/material.module';
import { FilterGroupDisplayPipe } from '../filters/modals/pipes/filterGroupDisplay.pipe';
import { ColumnChooserInputComponent } from './column-chooser-input/column-chooser-input.component';
import { ColumnListDisplayComponent } from './column-list-display/column-list-display.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { ValueChooserComponent } from './value-chooser/value-chooser.component';

@NgModule({
  declarations: [ColumnChooserInputComponent, ColumnListDisplayComponent, FilterGroupDisplayPipe, InfoBoxComponent, ValueChooserComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, NgxMatSelectSearchModule, TranslateModule],
  exports: [
    ColumnChooserInputComponent,
    ColumnListDisplayComponent,
    CommonModule,
    FilterGroupDisplayPipe,
    FormsModule,
    InfoBoxComponent,
    ReactiveFormsModule,
    ValueChooserComponent,
  ],
})
export class SemTableSharedModule {}
