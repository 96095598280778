<div class="container">
  {{ 'sem_table.column_tool' | translate | titlecase }}:
  <div class="tool-container">
    <div (cdkDropListDropped)="drop($event)" appVerticalScrollable cdkDropList cdkDropListOrientation="horizontal" class="column-container">
      <div *ngFor="let column of columnsService.columnChosenToDisplay" cdkDrag class="column">
        <span class="alias">{{ configService.config.columns[column].alias }}</span>
        <mat-icon (click)="removeColumn(column)" class="remove-icon">close</mat-icon>
      </div>
    </div>

    <ng-container *ngIf="columnsService.columnsToChoose$ | async as columnsToChoose">
      <button *ngIf="columnsToChoose.length" [matMenuTriggerFor]="addColumnMenu" class="add-new-column-btn" mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>

      <!------------          Menu for display column           ------------------->
      <mat-menu #addColumnMenu="matMenu" class="column-menu" xPosition="after">
        <ng-template matMenuContent>
          <sem-column-list-display
            (columnClicked)="addColumn($event)"
            [closeAfterChoose]="false"
            [columns]="columnsToChoose"
            [config]="configService.config"
          >
          </sem-column-list-display>
        </ng-template>
      </mat-menu>
    </ng-container>
  </div>

  <div class="btn-container">
    <!------------          storage           ------------------->
    <button [matMenuTriggerFor]="storageMenu" mat-button>
      {{ 'sem_table.memory' | translate | titlecase }}
    </button>
    <button (click)="closed.emit()" class="close-tool-btn" mat-button>
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <!------------          menu for storage           ------------------->
  <mat-menu #storageMenu="matMenu" class="column-menu" xPosition="after">
    <button
      (click)="useColumnSet(columnSet)"
      *ngFor="let columnSet of columnsService.storageColumns$ | async"
      [matTooltip]="columnSet.name.name"
      class="storage-btn-container"
      mat-menu-item
    >
      <div class="iconContainer">
        <mat-icon (click)="deleteColumnSet(columnSet, $event)">delete</mat-icon>
      </div>
      {{ columnSet?.name?.name | truncate: 15 }}
    </button>

    <button (click)="openSaveAs()" mat-menu-item>{{ 'sem_table.save_as' | translate }}</button>
  </mat-menu>
</div>
