<form>
  <sem-info-box *ngIf="selectService.selectedGlobally$ | async">
    {{ 'sem_table.info_action_delay' | translate }}
  </sem-info-box>

  <div class="container">
    <div class="info">{{ 'sem_table.filters' | translate }}:</div>

    <ng-container *ngIf="filterGroups![0] && filterGroups![0].filters?.length; else emptyFilter">
      <div class="filter-display active">
        <sem-filter-groups-displayer
          [config]="config"
          [fontSize]="12"
          [filters]="{ filterGroups: filterGroups!, filterManualChanges: false }"
        ></sem-filter-groups-displayer>

        <div class="btns">
          <mat-icon (click)="resetFilters()" *ngIf="filterGroups![0]">delete</mat-icon>
        </div>
      </div>
    </ng-container>

    <ng-template #emptyFilter>
      <div class="filter-display">
        <span>{{ 'sem_table.no_filter' | translate }}</span>
      </div>
    </ng-template>
  </div>

  <div class="container">
    <!---------------------             Choose Column          ------------------------------->
    <div class="info">{{ 'sem_table.change_on' | translate }}:</div>
    <sem-column-chooser-input [columnCtrl]="columnCtrl" [columns]="columns"></sem-column-chooser-input>

    <!---------------------             Choose action          ------------------------------->
    <ng-container>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'sem_table.use_action' | translate }}:</mat-label>
        <mat-select [formControl]="typeChosenCtrl">
          <ng-container *ngIf="columnCtrl.valid">
            <mat-option
              *ngFor="let type of columnCtrl.value.actionsAvailableTypes"
              [matTooltip]="type | actionTypeTooltip"
              [value]="type"
              matTooltipPosition="right"
              >{{ type | actionTypeAlias }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <!---------------------             logic of action          ------------------------------->

    <sem-action-switch
      #actionSwitch
      *ngIf="typeChosenCtrl.valid && columnCtrl.valid; else placeholder"
      [action]="action!"
      [autocompleteService]="autocompleteService"
      [column]="columnCtrl.value"
      [config]="config"
    ></sem-action-switch>

    <ng-template #placeholder>
      <div class="placeholder"></div>
    </ng-template>
  </div>

  <div *ngIf="action" class="container">
    <mat-checkbox [(ngModel)]="action.override_manual_changes" name="caseSensitive" (click)="openDialog(action)">
      <span class="tooltip-container">
        {{ 'sem_table.apply_manual_changes' | translate }}
        <mat-icon class="tooltip-icon" matTooltip="{{ 'sem_table.apply_manual_note' | translate }}">info</mat-icon>
      </span>
    </mat-checkbox>
  </div>
</form>

<ng-container *ngIf="typeChosenCtrl.value === actionTypes.generateValue">
  <mat-chip-listbox (cdkDropListDropped)="drop($event)" cdkDropList cdkDropListOrientation="horizontal">
    <mat-chip-option (removed)="remove(value)" *ngFor="let value of action!.value" cdkDrag>
      <ng-container *ngIf="value.fromItem">{{ config.columns[value.value].alias }} <span class="label">from item</span> </ng-container>
      <ng-container *ngIf="!value.fromItem">"{{ value.value }} {{ value.param }}""</ng-container>
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-option>
  </mat-chip-listbox>

  <button (click)="removeAllDynamicValues()" *ngIf="action!.value && action!.value.length > 0" class="clear-btn" mat-button type="button">
    {{ 'sem_table.clear_all' | translate }}
  </button>
</ng-container>
