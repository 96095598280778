<div class="dialog-title">
  <span class="title">{{ 'sem_table.are_you_sure' | translate }}</span>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>

<mat-dialog-content>
  <span class="description">
    {{ 'sem_table.manual_changes_warning' | translate }}
  </span>
</mat-dialog-content>
<div class="checkbox">
  <mat-checkbox (change)="toggleShow($event)">{{ 'sem_table.dont_show_again' | translate }}</mat-checkbox>
</div>

<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary" type="button">
    {{ 'general.apply' | translate }}
  </button>
</mat-dialog-actions>
