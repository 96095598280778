<mat-form-field appearance="outline">
  <mat-label>{{ 'sem_table.choose_column' | translate }}</mat-label>
  <mat-select [formControl]="columnCtrl" [compareWith]="compareByAlias">
    <mat-option>
      <ngx-mat-select-search
        [formControl]="columnSearchCtrl"
        [noEntriesFoundLabel]="'not found'"
        [placeholderLabel]="'search'"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let column of columnsToChoose" [value]="column">{{ column.alias }}</mat-option>
  </mat-select>
</mat-form-field>
