<div class="container">
  <mat-form-field appearance="outline">
    <input
      #trigger="matAutocompleteTrigger"
      [formControl]="valueCtrl"
      [matAutocomplete]="autocomplete2"
      [placeholder]="'sem_table.field' | translate | titlecase"
      matInput
      name="to"
      type="text"
    />
    <mat-autocomplete #autocomplete2="matAutocomplete" (optionSelected)="valueChangedByUser($event)" autoActiveFirstOption>
      <mat-option *ngFor="let value of columnsToDisplay" [value]="value">
        {{ value }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
