import { NgComponentOutlet } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output, ViewChild, inject } from '@angular/core';
import { CommunicationService } from '../../../services/communication.service';
import { ColumnComponentModel, ColumnModel } from './column.model';
import { EditableColumnsComponentInterface } from './editable-column.component';

// @TODO: v16 - jak przejdziemy na angulara >= 16.2 to prawdopodobnie będzie można zrezygnować z użytej tutaj libki `ng-dynamic-component`
// i użyć wbudowanego *ngComponentOutlet z prostym bindowaniem danych wejściowych

@Component({
  selector: 'sem-component-column',
  template: ` <div class="component-container">
    <ng-template
      #compo
      *ngIf="value"
      [ngComponentOutlet]="component.component"
      [ndcDynamicInputs]="value"
      [ndcDynamicOutputs]="outputs"
    ></ng-template>
  </div>`,
  styles: [
    `
      .component-container {
        margin: 0 2px;
      }
    `,
  ],
})
export class ComponentColumnComponent implements EditableColumnsComponentInterface {
  @Input() column!: ColumnModel;
  @Input() component!: ColumnComponentModel;
  @Output() columnComponentEvent: EventEmitter<any> = new EventEmitter();
  @Output() editStateEntered: EventEmitter<null> = new EventEmitter();
  @Output() editStateExited: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  private communicationService = inject(CommunicationService);

  get componentInstance() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return this.ngComponentOutlet['_componentRef'].instance!;
  }

  readonly outputs = {
    changed: (x: unknown) => this.emitToColumnComponentEvent(x),
    editStateEntered: () => this.editStateEntered.emit(),
    editStateExited: () => this.editStateExited.emit(),
    valueChanged: (x: unknown) => this.valueChanged.emit(x),
  };

  value!: any;
  @Input() set data(data: { item: any; value: any }) {
    const { item, value } = data;

    const preparedValue = (this.component.inputsMapping ? this.component.inputsMapping(value, item) : value) || [];

    const translateKey = this.component.valueMapping?.getByKey(value) || null;
    translateKey && (preparedValue.translateKey = translateKey);

    this.value = preparedValue;
  }

  @HostListener('dblclick', ['$event'])
  dbClick(event: any): void {
    event.stopPropagation();
    this.column.editable && this.enterEditableMode();
  }

  @ViewChild(NgComponentOutlet, { static: false }) ngComponentOutlet!: NgComponentOutlet;

  enterEditableMode(): void {
    this.editStateEntered.emit();
    this.componentInstance.enterEditableMode();
  }

  enterForcedEditableMode(): void {
    this.componentInstance.enterForcedEditableMode();
  }

  exitEditableState(): void {
    this.editStateExited.emit();
    this.componentInstance.exitEditableState();
  }

  private emitToColumnComponentEvent(data: any) {
    const columnName = this.column?.param;

    this.communicationService.columnComponentEvent$.next({ columnName, componentOutput: data });
  }
}
