import { Component, OnInit } from '@angular/core';
import { MainToolbarService, TOOLBAR_STATE } from '../main-toolbar.service';

@Component({
  selector: 'sem-tool-displayer',
  templateUrl: './tool-displayer.component.html',
  styleUrls: ['./tool-displayer.component.scss'],
})
export class ToolDisplayerComponent implements OnInit {
  possibleStates: typeof TOOLBAR_STATE = TOOLBAR_STATE;
  constructor(public mainToolbarService: MainToolbarService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.mainToolbarService.currentState = TOOLBAR_STATE.default;
  }

  disableColumnTool() {
    this.mainToolbarService.currentState = TOOLBAR_STATE.default;
  }
}
