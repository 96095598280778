import { Component, Input } from '@angular/core';
import { RegexpReplaceAction } from '../../models/RegexpReplaceAction';

@Component({
  selector: 'sem-regexp-replace',
  templateUrl: './regexp-replace.component.html',
  styleUrls: ['./regexp-replace.component.scss'],
})
export class RegexpReplaceComponent {
  @Input() action!: RegexpReplaceAction;
}
