/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { ItemModel } from '../../../../item.model';
import { CustomButtonInterface, CustomButtonPerItemInterface } from '../../../../models/CustomButton.model';
import { TableConfigurationModel } from '../../../../models/TableConfiguration.model';
import { ToolInterface } from '../../../../models/TableConfigurationInterface.model';
import { ChangeModel } from '../../../../models/change.model';
import { ChangesService } from '../../../../services/changes.service';
import { CommunicationService } from '../../../../services/communication.service';
import { PopupService } from '../../../../services/popup.service';
import { ColumnsComponentInterface } from '../editable-column.component';
import { InnerColumn } from '../InnerColumn';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '../../../../../service/breadcrumbs.service';
import { TaskInterface } from '../../../../../../dashboard/tasks/tasks.model';
import commonUrlsConfig from '../../../../../../configs/common-urls.config';

export enum ActionsTypeEnum {
  AdGroup = 'google-adgroups',
  SearchTerm = 'google-search-terms',
  ShoppingPerformance = 'google-shopping-performance',
  Keyword = 'google-keywords',
}

@Component({
  selector: 'sem-inner-column',
  templateUrl: './inner-column.component.html',
  styleUrls: ['./inner-column.component.scss', '../shared-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerColumnComponent implements ColumnsComponentInterface, OnInit {
  @Input() actionBtnDisplay!: boolean;
  @Input() checked!: boolean;
  @Input() colorItem?: string;
  @Input() column!: InnerColumn;
  @Input() config!: TableConfigurationModel;
  @Input() disabled!: boolean;
  @Input() item: any;
  @Input() labelItem?: string[];
  @Input() shouldCheckEmpty: boolean = true;
  @Output() actionPanelOpened: EventEmitter<void> = new EventEmitter();
  @Output() editStateExited: EventEmitter<void> = new EventEmitter();
  @Output() emitCustomButtonEvent: EventEmitter<CustomButtonPerItemInterface> = new EventEmitter();
  @Output() infoItemClicked: EventEmitter<void> = new EventEmitter();
  @Output() selectWithShift: EventEmitter<boolean> = new EventEmitter();
  @Output() selected: EventEmitter<boolean> = new EventEmitter();
  @Output() unSelected: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('menuTrigger') panelReference!: MatMenuTrigger;
  value: any;
  displayNotEditable!: boolean;
  nextRow!: EventEmitter<null>;
  nextColumn!: EventEmitter<null>;
  @Output() prevRow: EventEmitter<void> = new EventEmitter();
  @Output() prevColumn: EventEmitter<void> = new EventEmitter();
  valueChanged!: EventEmitter<null>;
  @Output() hideColumn = new EventEmitter<boolean>();
  private changesService = inject(ChangesService);
  private communicationService = inject(CommunicationService);
  private popupService = inject(PopupService);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  private breadcrumbsService = inject(BreadcrumbsService);

  actionTypes: { name: string, type: ActionsTypeEnum }[] = [
    { name: 'KW', type: ActionsTypeEnum.Keyword },
    { name: 'ADS', type: ActionsTypeEnum.AdGroup },
    { name: 'PRD', type: ActionsTypeEnum.ShoppingPerformance },
    { name: 'SQ', type: ActionsTypeEnum.SearchTerm }
  ];

  get actionsButtonSlot() {
    const { item } = this;

    return this.config.actionsButtonSlot!.filter((button) => {
      if (button.visibilityCondition && typeof button.visibilityCondition === 'function') {
        return button.visibilityCondition(item);
      }

      if (!button.onlyIfParam) {
        return true;
      }

      return button.paramNegation ? !item[button.onlyIfParam as keyof typeof item] : item[button.onlyIfParam as keyof typeof item];
    });
  }

  ngOnInit(): void {
    if (this.shouldCheckEmpty) {
      this.determineIsEmpty();
    }
  }

  navigateTo(item: TaskInterface, channel: ActionsTypeEnum): void {
    this.breadcrumbsService.campaignName$.next(item.title);
    const url = `${commonUrlsConfig.dashboardUrl}${item.project_id}/channels/${channel}?campaignId=${item.context.campaign_id}`;
    this.router.navigateByUrl(url);
  }

  @HostListener('keydown', ['$event']) keydown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  click(): void {
    throw new Error('Method not implemented.');
  }

  exitEditableState(): void {
    throw new Error('Method not implemented.');
  }

  enterEditableMode() {
    throw new Error(`zepsute${this.column.type}`);
  }

  selectClicked(clickEvent: MouseEvent, checkbox: MatCheckbox) {
    const isChecked = !checkbox.checked;
    const clickWithShift = clickEvent.shiftKey;

    if (clickWithShift && isChecked) {
      this.selectWithShift.emit(isChecked);
    } else if (isChecked) {
      this.selected.emit(isChecked);
    } else {
      this.unSelected.emit(isChecked);
    }
  }

  openEditInPopup(initItem: ItemModel) {
    this.popupService
      .openEditItem(initItem)
      .pipe(
        map(
          (itemAfterEdit: ItemModel) =>
            new ChangeModel({
              itemsBeforeChange: [initItem],
              itemsAfterChange: [itemAfterEdit],
            }),
        ),
      )
      .subscribe((change: ChangeModel) => this.changesService.newChange$.next(change));
  }

  initRemove(item: ItemModel) {
    this.communicationService.initRemove$.next([item]);
  }

  redirectToDetails(item: ItemModel) {
    this.communicationService.navigateTo$.next(item);
  }

  openDuplicatePopup(initItem: ItemModel) {
    const duplicateChange = this.config.tools.duplicateParamChange;
    if (duplicateChange) {
      initItem = {
        ...initItem,
        [duplicateChange.columnName]: duplicateChange.callback(initItem[duplicateChange.columnName as keyof typeof initItem], initItem),
      };
    }
    this.popupService
      .openDuplicateItem(initItem, true)
      .subscribe((duplicatedItem) => this.communicationService.initDuplicate$.next(duplicatedItem));
  }

  openDuplicateBy(item: ItemModel, columnName: string) {
    this.popupService.openDuplicateBy(columnName).subscribe((data) =>
      this.communicationService.initDuplicateBy$.next({
        data,
        columnName,
        parent: item,
      }),
    );
  }

  openPanel(item: ItemModel) {
    const {
      removing: removingConf,
      detailsBtn: detailsBtnC,
      duplicate: duplicateConf,
      duplicateBy: duplicateByC,
      editInPopup: editInPopupC,
    } = this.config.tools;

    const customButtons = this.actionsButtonSlot;

    const removing =
      removingConf &&
      (removingConf.activeAlways || (removingConf.dependsOnParam && item[removingConf.dependsOnParam as keyof typeof item]));
    const detailsBtn =
      detailsBtnC && (detailsBtnC.activeAlways || (detailsBtnC.dependsOnParam && item[detailsBtnC.dependsOnParam as keyof typeof item]));
    const duplicate =
      duplicateConf &&
      (duplicateConf.activeAlways || (duplicateConf.dependsOnParam && item[duplicateConf.dependsOnParam as keyof typeof item]));
    const duplicateBy =
      duplicateByC &&
      (duplicateByC.activeAlways || (duplicateByC.dependsOnParam && item[duplicateByC.dependsOnParam as keyof typeof item]));
    const editInPopup =
      editInPopupC &&
      (editInPopupC.activeAlways || (editInPopupC.dependsOnParam && item[editInPopupC.dependsOnParam as keyof typeof item]));
    const isEmpty = !(customButtons[0] || removing || detailsBtn || duplicate || duplicateBy || editInPopup);

    this.panelReference.menuData = {
      item,
      customButtons,
      removing,
      detailsBtn,
      duplicate,
      duplicateBy,
      editInPopup,
      isEmpty,
    };
    this.panelReference.openMenu();
  }

  determineIsEmpty() {
    const {
      removing: removingConf,
      detailsBtn: detailsBtnC,
      duplicate: duplicateConf,
      duplicateBy: duplicateByC,
      editInPopup: editInPopupC,
    } = this.config.tools;

    const isActive = (config: ToolInterface) =>
      config.activeAlways || (config.dependsOnParam && this.column[config.dependsOnParam as keyof InnerColumn]);

    const removing = removingConf && isActive(removingConf);
    const detailsBtn = detailsBtnC && isActive(detailsBtnC);
    const duplicate = duplicateConf && isActive(duplicateConf);
    const duplicateBy = duplicateByC && isActive(duplicateByC);
    const editInPopup = editInPopupC && isActive(editInPopupC);
    const isEmpty = !(this.actionsButtonSlot![0] || removing || detailsBtn || duplicate || duplicateBy || editInPopup);

    this.hideColumn.emit(isEmpty);
  }

  customButtonClicked(customButton: CustomButtonInterface, item: ItemModel | null) {
    this.communicationService.customButtonClicked$.next({
      type: customButton.actionType,
      data: item,
    });
  }
}
