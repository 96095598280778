import { Pipe, PipeTransform } from '@angular/core';
import { ItemModel } from '../../item.model';
import { CustomButtonPerItemInterface } from '../../models/CustomButton.model';
import { ColumnModel } from '../columns-switch/columns/column.model';

@Pipe({
  name: 'returnColumnCustomButtons',
})
export class ColumnCustomButtonsPipe implements PipeTransform {
  transform(column: ColumnModel, item: ItemModel): CustomButtonPerItemInterface[] {
    if (!column.customButton) {
      return [];
    }

    return column.customButton.filter((button) =>
      button.paramNegation ? !item[button.onlyIfParam as keyof typeof item] : item[button.onlyIfParam as keyof typeof item],
    );
  }
}
