<ng-container *ngIf="editState"></ng-container>

<ng-container *ngTemplateOutlet="editState ? editable : notEditable"> </ng-container>
<ng-template #editable>
  <div class="date-container {{ column.param }}-column-display">
    <input
      #input
      (dateTimeChange)="setDateValue($event)"
      [(ngModel)]="date"
      [disabled]="disabled"
      [name]="nameAttribute"
      [owlDateTimeTrigger]="dt1"
      [owlDateTime]="dt1"
      [placeholder]="'Choose Date'"
      [selectMode]="column.rangeType ? 'range' : 'single'"
      class="data-picker"
    />
    <owl-date-time [pickerType]="column.pickerType" #dt1></owl-date-time>
  </div>
</ng-template>
<ng-template #notEditable>
  <div #input [ngClass]="{ 'not-editable': displayNotEditable }" class="value">
    {{ date }}
  </div>
</ng-template>
