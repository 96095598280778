<form #formRef="ngForm" (ngSubmit)="submit()" [formGroup]="form" autocomplete="off" cdkTrapFocus class="container">
  <mat-form-field class="form-field-small-text">
    <input
      [formControl]="searchValue"
      cdkFocusInitial
      matInput
      placeholder="{{ 'sem_table.search_by' | translate | titlecase }} {{ column.alias }}"
    />
    <button mat-icon-button matSuffix type="submit">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</form>
