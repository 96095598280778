import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { skip, takeUntil, tap } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../../../modals/confirm-modal/confirm-modal.component';
import { SemTableStorageService } from '../../../../service/sem-table-storage.service';
import { FilterService } from '../../../filters/filters.service';
import { DefaultTabs, TabsToolConfig } from '../../../models/TableConfigurationInterface.model';
import { CommunicationService } from '../../../services/communication.service';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'sem-tabs-tool',
  templateUrl: './tabs-tool.component.html',
  styleUrls: ['./tabs-tool.component.scss'],
})
export class TabsToolComponent implements OnInit, OnDestroy {
  leftTabs: TabsToolConfig[] = [];
  allLeftTabs: TabsToolConfig[] = [];
  rightTabs: TabsToolConfig[] = [];
  allRightTabs: TabsToolConfig[] = [];
  private onDestroy$ = new Subject<void>();
  private onTabChange$ = new Subject<void>();

  private configService = inject(ConfigService);
  private filterService = inject(FilterService);
  private communicationService = inject(CommunicationService);
  private storageService = inject(SemTableStorageService);
  private dialog = inject(MatDialog);
  private translateService = inject(TranslateService);

  ngOnInit(): void {
    this.communicationService.tabsChanged$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.recalculateTabs());
    if (this.configService?.config?.tools?.tabs![0]) {
      this.recalculateTabs();
      this.configService.config.tools.tabs!.forEach((t) => (t.active = false));
      this.leftTabs[0].active = true;
    }
  }

  useTab(tab: TabsToolConfig) {
    this.onTabChange$.next();
    this.leftTabs.forEach((t) => (t.active = false));
    this.rightTabs.forEach((t) => (t.active = false));
    tab.active = true;
    this.filterService.setFilters({
      filterGroups: JSON.parse(JSON.stringify(tab.fiterGroup)),
      filterManualChanges: JSON.parse(JSON.stringify(tab.filterManualChanges)),
    });
    this.filterService.filters$
      .pipe(
        skip(1),
        takeUntil(this.onDestroy$),
        takeUntil(this.onTabChange$),
        tap(() => (tab.active = false)),
        tap(() => (this.leftTabs[0].fiterGroup = JSON.parse(JSON.stringify(this.filterService.groups)))),
        tap(() => (this.leftTabs[0].active = true)),
      )
      .subscribe();
  }

  changeTabState(tab: TabsToolConfig) {
    tab.visible = !tab.visible;
    this.communicationService.tabsChanged$.next();
  }

  confirmDelete(tab: TabsToolConfig) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        key: this.translateService.instant('confirmations.delete_tab'),
        params: {},
        isOK: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteFilter(tab);
      }
    });
  }

  deleteFilter(tab: TabsToolConfig) {
    const tabIndex = this.configService.config.tools.tabs!.findIndex((t) => t.name === tab.name);
    if (tabIndex > -1) {
      this.configService.config.tools.tabs!.splice(tabIndex, 1);
      this.storageService.saveStorage(this.configService.config.configuration.storageName, this.configService.config);
      this.recalculateTabs();
      this.communicationService.tabsChanged$.next();

      const currentFilters = this.filterService.filters$.getValue();
      if (this.areFiltersEqual(currentFilters, { filters: tab.fiterGroup, filterManualChanges: tab.filterManualChanges })) {
        this.filterService.resetFilters();
      }
    }
  }

  isDefaultTab(name: string): boolean {
    return Object.values(DefaultTabs).includes(name as DefaultTabs);
  }

  private areFiltersEqual(filters1: any, filters2: any): boolean {
    return JSON.stringify(filters1) === JSON.stringify(filters2);
  }

  private recalculateTabs() {
    this.leftTabs = this.configService.config.tools.tabs!.filter((tab) => tab.position === 'left' && tab.visible);
    this.allLeftTabs = this.configService.config.tools.tabs!.filter((tab) => tab.position === 'left');
    this.rightTabs = this.configService.config.tools.tabs!.filter((tab) => tab.position === 'right' && tab.visible);
    this.allRightTabs = this.configService.config.tools.tabs!.filter((tab) => tab.position === 'right');
    const lastFilter = JSON.parse(JSON.stringify(this.filterService.groups));
    this.leftTabs.unshift({
      name: 'Custom Filter',
      filterManualChanges: JSON.parse(JSON.stringify(this.filterService.filterManualChanges)),
      fiterGroup: lastFilter,
      position: 'left',
      visible: true,
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
