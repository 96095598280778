<div appVerticalScrollable class="container">
  <div class="tool-container">
    <div class="items-counter">
      <span *ngIf="(selectService.selectedGlobally$ | async) === false; else globalSelected">
        {{ 'sem_table.chosen' | translate | titlecase }}:
        <span class="bold">{{ (selectService.selectedItems$ | async)!.length }}</span>
      </span>
      <ng-template #globalSelected>
        <span>
          {{ 'sem_table.chosen' | translate | titlecase }}:
          <span class="bold">{{ itemsCounter$ | async }}</span>
        </span>
      </ng-template>

      <span
        (click)="selectService.selectedGlobally$.next(true)"
        *ngIf="(selectService.isAllAtPageSelected$ | async) && (selectService.selectedGlobally$ | async) === false"
        class="select-all-btn"
      >
        {{ 'sem_table.choose_all' | translate }}: {{ itemsCounter$ | async }}
      </span>
    </div>

    <div class="operations-container">
      <button (click)="openActionPopup()" *ngIf="!configService.config.readOnly" mat-flat-button>
        {{ 'sem_table.action_edit' | translate | titlecase }}
      </button>
      <button
        (click)="multiDuplicateSelected()"
        *ngIf="configService.config.tools.multiDuplicate && (selectService.selectedGlobally$ | async) === false"
        mat-flat-button
      >
        {{ 'sem_table.action_duplicate_selected' | translate | titlecase }}
      </button>
      <ng-container *ngIf="(selectService.selectedGlobally$ | async) === false; else selectedGloballyButtons">
        <button (click)="customButtonClicked(button)" *ngFor="let button of configService.config.selectedToolbarSlot" mat-flat-button>
          <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
          {{ button.alias }}
        </button>
      </ng-container>
      <button (click)="removeData()" *ngIf="configService.config.tools.removing && hasCopySelected()" mat-flat-button>
        <mat-icon>delete</mat-icon>
        {{ 'sem_table.remove_duplicates' | translate }}
      </button>

      <ng-container *ngFor="let button of mainToolbarButtons">
        <button
          (click)="customButtonClicked(button)"
          *ngIf="hasCopySelected() || hasInactiveSelected()"
          [ngClass]="button.className"
          mat-flat-button
        >
          <mat-icon>delete</mat-icon>
          {{ button.alias }}
        </button>
      </ng-container>

      @if (configService.config.selectedToolbarDropdown) {
        <button mat-raised-button [matMenuTriggerFor]="dropdownMenu">
          {{ configService.config.selectedToolbarDropdown.translationKey | translate }} <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
        </button>

        <mat-menu #dropdownMenu>
          <ng-template matMenuContent>
            @for (menuItem of menuItems$ | async; track menuItem) {
              <button mat-menu-item (click)="selectToolMenuItemsProvider?.itemClick(menuItem)">
                {{ menuItem.name }}
              </button>
            }
          </ng-template>
        </mat-menu>
      }

      <ng-template #selectedGloballyButtons>
        <button
          (click)="customButtonGloballyClicked(button)"
          *ngFor="let button of configService.config.selectedGloballyToolbarSlot"
          mat-flat-button
        >
          <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
          {{ button.alias }}
        </button>
      </ng-template>
    </div>
    <button (click)="selectService.unselectAll()" class="close-btn" mat-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
