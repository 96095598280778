<ng-container *ngTemplateOutlet="editState ? editable : notEditable"></ng-container>

<ng-template #editable>
  <!-- TODO:  fix brak inputa [disabled]="disabled"  -->
  <mat-form-field appearance="outline" class="{{ column.param }}-column-display">
    <input
      #input
      (blur)="exitEditableState()"
      *ngIf="column.valueType === valueTypes.number"
      [disabled]="disabled"
      [value]="value"
      matInput
      type="number"
    />
    <!-- TODO:  fix brak inputa [disabled]="disabled"  -->
    <input
      #input
      (blur)="exitEditableState()"
      (input)="calculateValueLength()"
      *ngIf="column.valueType !== valueTypes.number && 50 >= value.length && !column.textAreaAlways"
      [disabled]="disabled"
      [value]="value | mapColumnValue: column"
      matInput
      type="'text'"
    />

    <!-- TODO:  fix brak inputa [disabled]="disabled"  -->
    <textarea
      #autosize="cdkTextareaAutosize"
      #input
      (blur)="exitEditableState()"
      (input)="calculateValueLength()"
      *ngIf="value.length > 50 || column.textAreaAlways"
      [disabled]="disabled"
      [value]="value | mapColumnValue: column"
      cdkAutosizeMaxRows="10"
      cdkAutosizeMinRows="1"
      cdkTextareaAutosize
      matInput
      type="text"
    >
    </textarea>
  </mat-form-field>
</ng-template>

<ng-template #notEditable>
  <span
    #input
    [appOnlyNumber]="column.valueType === valueTypes.number"
    [isFullContent]="isFullContent"
    [ngClass]="{ 'not-editable': displayNotEditable, 'full-conent': isFullContent && valueLength > valueMaxLength }"
    [semColumn]="column.directive!"
    [value]="value"
    class="value"
  >
    {{ isFullContent ? value : (value | truncate: valueMaxLength : column.valueType) }}
  </span>
</ng-template>

<ng-container *ngIf="valueLength > valueMaxLength && !column._isNavigationColumn">
  <div (click)="showFullText()" class="full-content-expander">
    <ng-container *ngIf="isFullContent; else expandMore">
      <mat-icon>expand_less</mat-icon>
      {{ 'sem_table.show_less' | translate }}
    </ng-container>
    <ng-template #expandMore>
      <mat-icon>expand_more</mat-icon>
      {{ 'sem_table.show_more' | translate }}
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="column.lettersLimit">
  <div [ngClass]="{ warn: valueLength > column.lettersLimit }" class="limitter">{{ valueLength }} / {{ column.lettersLimit }}</div>
</ng-container>
