import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutocompleteEventModel } from '../../../models/autocompleteEvent.model';
import { AutocompleteService } from '../../../services/autocomplete.service';
import { AutocompleteColumn } from '../../../table-display/columns-switch/columns/autocomplete-column/AutocompleteColumn';
import { ColumnModel } from '../../../table-display/columns-switch/columns/column.model';
import { SelectColumn } from '../../../table-display/columns-switch/columns/select-column/SelectColumn';
import { FilterLogicOperatorEnum } from '../../FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../../FilterTypesEnum';
import { FilterFactoryService } from '../../filtersFactory.service';
import { FilterModel } from '../../models/filter.model';
import { QuickFilterService } from '../quick-filter.service';

@Component({
  selector: 'sem-quick-filter-with-select',
  templateUrl: './quick-filter-with-select.component.html',
  styleUrls: ['./quick-filter-with-select.component.scss'],
})
export class QuickFilterWithSelectComponent implements OnInit, OnDestroy {
  @Input() column!: ColumnModel;
  formControl = new UntypedFormControl();
  searchControl = new UntypedFormControl();
  selectedValuesWithId!: any[];
  valuesWithId!: any[];
  private onDestroy$ = new Subject<void>();

  private readonly autocompleteService = inject(AutocompleteService);
  private readonly filterFactoryService = inject(FilterFactoryService);
  private readonly quickFilterService = inject(QuickFilterService);

  ngOnInit(): void {
    this.getAvailableOptions();
    this.formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => this.addFilter(value));
    this.searchControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => this.search(value));
  }

  toggled() {
    // TODO: do zmiany - trzeba zrobić przechowywanie wartości po wybraniu oraz obsłużyć wg aktualnych filtrów:
    this.searchControl.setValue(null, { emitEvent: false });
    this.formControl.setValue(null, { emitEvent: false });
    this.selectedValuesWithId = cloneDeep(this.valuesWithId);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private addFilter(value: any) {
    const responseValue = this.column.responseMapping ? this.column.responseMapping(value) : value;
    const filterIndexInLastGroup = this.quickFilterService.getFilterIndexInLastGroup(this.column);
    const filterOperator = filterIndexInLastGroup > 0 ? FilterLogicOperatorEnum.and : FilterLogicOperatorEnum.none;
    const filterType = this.column.defaultFilterType || FilterTypesEnum.equal;
    const filter: FilterModel = this.filterFactoryService.createFilter(this.column, filterType, responseValue, filterOperator);

    this.quickFilterService.setFilter(filterIndexInLastGroup, filter);
    // this.formControl.setValue(null, { emitEvent: false });
  }

  private getAvailableOptions() {
    if (this.column instanceof AutocompleteColumn) {
      this.column.autocomplete$!.pipe(takeUntil(this.onDestroy$)).subscribe((values) => this.setList(values));
      const data: AutocompleteEventModel = { columnName: this.column.param, value: '' };
      this.autocompleteService.initAutocompleteChange$.next(data);
    } else if (this.column instanceof SelectColumn) {
      this.setList(this.column.possibleValues);
    }
  }

  private search(value: string) {
    const list = cloneDeep(this.valuesWithId);
    if (value) {
      const filter = value.toLowerCase();
      this.selectedValuesWithId = list.filter((option) => this.column.map(option).toLowerCase().includes(filter));
    } else {
      this.selectedValuesWithId = list;
    }
  }

  private setList(list: unknown[]) {
    this.valuesWithId = list;
    this.selectedValuesWithId = cloneDeep(list);
  }
}
