import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// @dynamic
@Component({
  selector: 'sem-photo-popup',
  templateUrl: './photo-popup.component.html',
  styleUrls: ['./photo-popup.component.scss'],
})
export class PhotoPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<PhotoPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      photo: string;
      additionalData: string[];
    },
  ) {}
}
