import { Pipe, PipeTransform, inject } from '@angular/core';
import { ActionTypesEnum } from '../../ActionTypesEnum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'actionTypeAlias',
})
export class ActionTypeAliasPipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(type: ActionTypesEnum): any {
    switch (type) {
      case ActionTypesEnum.override:
        return this.translateService.instant('sem_table.actions_list.override');
      case ActionTypesEnum.caseChange:
        return this.translateService.instant('sem_table.actions_list.case_change');
      case ActionTypesEnum.capitalized:
        return this.translateService.instant('sem_table.actions_list.capitalized');
      case ActionTypesEnum.lower:
        return this.translateService.instant('sem_table.actions_list.lower');
      case ActionTypesEnum.upper:
        return this.translateService.instant('sem_table.actions_list.upper');
      case ActionTypesEnum.addEnd:
        return this.translateService.instant('sem_table.actions_list.add_end');
      case ActionTypesEnum.addStart:
        return this.translateService.instant('sem_table.actions_list.add_start');
      case ActionTypesEnum.generateValue:
        return this.translateService.instant('sem_table.actions_list.generate_value');
      case ActionTypesEnum.replace:
        return this.translateService.instant('sem_table.actions_list.replace');
      case ActionTypesEnum.regexpReplace:
        return this.translateService.instant('sem_table.actions_list.regexp_replace');
      case ActionTypesEnum.setEmpty:
        return this.translateService.instant('sem_table.actions_list.set_empty');
      case ActionTypesEnum.add:
        return this.translateService.instant('sem_table.actions_list.add');
      case ActionTypesEnum.divide:
        return this.translateService.instant('sem_table.actions_list.divide');
      case ActionTypesEnum.multiply:
        return this.translateService.instant('sem_table.actions_list.multiply');
      case ActionTypesEnum.subtract:
        return this.translateService.instant('sem_table.actions_list.subtract');
      case ActionTypesEnum.multiSearch:
        return this.translateService.instant('sem_table.actions_list.mult_search');

      case ActionTypesEnum.modifyLink:
        return this.translateService.instant('sem_table.actions_list.modify_link');

      case ActionTypesEnum.fixUppercase:
        return this.translateService.instant('sem_table.actions_list.fix_uppercase');

      case ActionTypesEnum.addFoundFromField:
        return this.translateService.instant('sem_table.actions_list.add_found_from_field');

      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const exhaustCheck: never = type;
        return null;
      }
    }
  }
}
