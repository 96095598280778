<ng-container *ngTemplateOutlet="editState ? editable : notEditable"></ng-container>

<ng-template #notEditable>
  <span #input [ngClass]="{ 'not-editable': displayNotEditable }" class="value">{{ value | mapColumnValue: column }}</span>
</ng-template>

<ng-template #editable>
  <mat-form-field [appearance]="formFieldAppearance" class="input autoWidth {{ column.param }}-column-display">
    <input
      #input
      #trigger="matAutocompleteTrigger"
      (blur)="autocompleteblur(input.value, $event)"
      (focus)="autocompleteChanged.emit({ columnName: column.param, value: '' })"
      (keyup)="autocompleteKeyUp($event, input.value)"
      [disabled]="disabled"
      [matAutocomplete]="autocomplete"
      [value]="value | mapColumnValue: column"
      matInput
      type="text"
    />

    <!-- TODO: usunołem [disabled]="disabled" mat-autocomplete nie posiada disabled w api  -->
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      (optionSelected)="autocompleteClickInOption($event)"
      [autoActiveFirstOption]="column.onlyFromAutocomplete"
      [displayWith]="displayFn.bind(column)"
      [panelWidth]="'auto'"
      class="{{ 'autocomplete-' + column.param }}"
    >
      <mat-option *ngFor="let value of column.autocomplete$ | async" [value]="value">
        {{ value | mapColumnValue: column }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
