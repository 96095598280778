import { Injectable, inject } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { TableConfigurationModel } from '../sem-table/models/TableConfiguration.model';
import { TableConfigurationInterface } from '../sem-table/models/TableConfigurationInterface.model';
import { AuthService } from './auth.service';
import { CustomSettingsService } from './custom-settings.service';

@Injectable({
  providedIn: 'root',
})
export class SemTableStorageService {
  private readonly customSettingsService = inject(CustomSettingsService);
  private readonly authService = inject(AuthService);
  private readonly projectService = inject(ProjectService);

  saveStorage(name: string, data: TableConfigurationModel) {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    const accountId = this.authService.authUser?.id;

    const dataToSave = {
      itemsPerPage: data.itemsPerPage,
      sorting: data.sorting,
      activeFilters: data.activeFilters,
      filtersRecent: data.memory!.filtersRecent,
      filterSets: data.memory!.filterSets,
      displayedColumns: data.displayedColumns,
      columnsSets: data.memory!.columnsSets,
      tabs: data.tools.tabs,
    };

    return this.customSettingsService.save({
      data: dataToSave,
      name,
      accountId,
      projectId,
    });
  }

  getConfigWithStorage(config: TableConfigurationInterface): Observable<TableConfigurationInterface> {
    const projectId = this.projectService.activeProject$.getValue()!.id;
    const accountId = this.authService.authUser?.id;

    return this.customSettingsService.get({ name: config.configuration.storageName, projectId, accountId }).pipe(
      map((data) => data.data.data),
      catchError(() => of({})),
      map((configFromStorage) => this.parse(config, configFromStorage)),
    );
  }

  private parse(config: TableConfigurationInterface, data: any) {
    let parsed = cloneDeep(config);
    if (!config.memory) {
      parsed.memory = {};
    }
    parsed = this.parseByFilters(parsed, data);
    parsed = this.parseBySorting(parsed, data);
    parsed = this.parseByPagination(parsed, data);
    parsed = this.parseByColumns(parsed, data);
    parsed = this.parseByTabs(parsed, data);

    return parsed;
  }

  private parseByTabs(config: TableConfigurationInterface, data: any) {
    const parsed = cloneDeep(config);
    parsed.tools.tabs = data.tabs || parsed.tools.tabs || [];

    return parsed;
  }

  private parseBySorting(config: TableConfigurationInterface, data: any) {
    const parsed = cloneDeep(config);
    parsed.sorting = { active: data.order, direction: data.direction };

    return parsed;
  }

  private parseByFilters(config: TableConfigurationInterface, data: any) {
    const parsed = cloneDeep(config);

    try {
      parsed.initFilters = data.activeFilters;
      parsed.memory!.filtersRecent = data.filtersRecent;
      parsed.memory!.filterSets = data.filterSets;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    return parsed;
  }

  private parseByColumns(config: TableConfigurationInterface, data: any) {
    const parsed = cloneDeep(config);
    parsed.displayedColumns = data.displayedColumns || config.displayedColumns;
    parsed.memory!.columnsSets = data.columnsSets || [];

    return parsed;
  }

  private parseByPagination(config: TableConfigurationInterface, data: any) {
    const parsed = cloneDeep(config);
    parsed.itemsPerPage = data.itemsPerPage;

    return parsed;
  }
}
