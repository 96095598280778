import { Pipe, PipeTransform, inject } from '@angular/core';
import { map, merge, of } from 'rxjs';
import { ItemModel } from '../../item.model';
import { ExpansionRowService } from '../../services/expansion-row.service';

@Pipe({
  name: 'isExpanded',
})
export class IsExpandedPipe implements PipeTransform {
  private readonly expansionRowService = inject(ExpansionRowService);

  transform(item: ItemModel) {
    return merge(
      of(this.expansionRowService.isExpanded(item)),
      this.expansionRowService.expansionStateChanged$.pipe(map(() => this.expansionRowService.isExpanded(item))),
    );
  }
}

@Pipe({
  name: 'getDepth',
})
export class DepthPipe implements PipeTransform {
  private readonly expansionRowService = inject(ExpansionRowService);

  transform(item: ItemModel): number {
    return this.expansionRowService.getDepth(item);
  }
}

@Pipe({
  name: 'canExpand',
})
export class CanExpand implements PipeTransform {
  private readonly expansionRowService = inject(ExpansionRowService);

  transform(item: ItemModel) {
    return this.expansionRowService.canExpand(item);
  }
}

@Pipe({
  name: 'getExpandedRows',
})
export class GetExpandedRowsPipe implements PipeTransform {
  private readonly expansionRowService = inject(ExpansionRowService);

  transform(itemId: number) {
    return this.expansionRowService.getExpandedItems(itemId);
  }
}
