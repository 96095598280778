import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { TableConfigurationModel } from '../../../../models/TableConfiguration.model';
import { AutocompleteService } from '../../../../services/autocomplete.service';
import { ColumnsService } from '../../../../services/columns.service';
import { ColumnModel } from '../../../../table-display/columns-switch/columns/column.model';
import { FilterLogicOperatorEnum } from '../../../FilterLogicOperatorEnum';
import { FilterFactoryService } from '../../../filtersFactory.service';
import { FilterGroupModel } from '../../../models/filter.model';

@Component({
  selector: 'sem-filter-group-form',
  templateUrl: './filter-group-form.component.html',
  styleUrls: ['./filter-group-form.component.scss'],
})
export class FilterGroupFormComponent implements OnInit, OnDestroy {
  config!: TableConfigurationModel;
  @Input() columns!: ColumnModel[];
  @Input() columnsService!: ColumnsService;
  @Input() autocompleteService!: AutocompleteService;
  @Input() filterFactoryService!: FilterFactoryService;
  @Output() filterChanged = new EventEmitter<FilterGroupModel>();
  @Input() groupForm!: UntypedFormGroup;
  @Input() index!: number;
  @Output() removed = new EventEmitter<void>();
  protected readonly FormGroup = FormGroup;
  private onDestroy$ = new Subject<void>();

  get filtersArray() {
    return this.groupForm.get('filters') as UntypedFormArray;
  }

  get operatorCtrl() {
    return this.groupForm.get('operator') as UntypedFormControl;
  }

  ngOnInit(): void {
    if (!this.filtersArray.controls[0]) {
      this.addNextFilter();
    }
  }

  addNextFilter() {
    const filterForm = new UntypedFormGroup({
      additional_sources: new UntypedFormControl(null),
      column: new UntypedFormControl(null, Validators.required),
      type: new UntypedFormControl(null, Validators.required),
      operator: new UntypedFormControl(
        this.filtersArray && this.filtersArray.length > 0 ? FilterLogicOperatorEnum.and : FilterLogicOperatorEnum.none,
      ),
      value: new UntypedFormGroup({
        data: new UntypedFormControl(null),
        config: new UntypedFormControl(null),
      }),
    });
    this.filtersArray.push(filterForm);
    // this.group.filters.push(null);
  }

  catchFilterRemove(index: number) {
    this.filtersArray.removeAt(index);
  }

  removeGroup() {
    this.removed.emit();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
