import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { PopupService } from '../../services/popup.service';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';
import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';

@Component({
  selector: 'sem-quick-filters',
  templateUrl: './quick-filters.component.html',
  styleUrls: ['./quick-filters.component.scss'],
})
export class QuickFiltersComponent implements OnInit {
  areFiltersAvailable: boolean = false;
  columnsToFiler: ColumnModel[] =
    this.configService.config.tools.quickFilters?.map((name) => this.configService.config.columns[name]) || [];

  columnTypes = ColumnTypesEnum;
  constructor(
    private configService: ConfigService,
    private popupService: PopupService,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.areFiltersAvailable = this.configService?.config?.tools?.filter || false;
  }

  openFilters() {
    if (!this.areFiltersAvailable) {
      return;
    }
    this.popupService.openFilterManagerPopup().subscribe();
  }
}
