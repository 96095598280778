import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { UserModel } from '../../../../../model/user.model';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'sem-select-chip-column',
  templateUrl: './chip-column.component.html',
  styleUrls: ['./chip-column.component.scss', '../shared-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipColumnComponent implements OnInit, OnDestroy {
  @Input() users: UserModel[] = [];
  @Input() assignedUsers: UserModel[] = [];
  @Output() userSelected = new EventEmitter<{ users: UserModel[]; isChanged: boolean }>();

  userCtrl = new FormControl<string | null>(null);
  filteredUsers: ReplaySubject<UserModel[]> = new ReplaySubject<UserModel[]>(1);
  selectedUsers: UserModel[] = [];
  separatorKeysCodes: number[] = [ENTER, SPACE];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('auto') matAutocomplete: MatAutocomplete | undefined;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger | undefined;

  private onDestroy$ = new Subject<void>();

  ngOnInit() {
    this.filteredUsers.next(this.users.slice());

    this.userCtrl.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(300)).subscribe((value) => {
      this.onInput(value || '');
    });

    this.selectedUsers = this.assignedUsers.slice();
    this.emitUserChange();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onInput(value: string) {
    this.filterUsers(value);
  }

  filterUsers(value: string) {
    if (!this.users) {
      return;
    }

    let search = value || '';
    search = search.toString();
    if (!search) {
      this.filteredUsers.next(this.users.slice().filter((user) => !this.isUserSelected(user)));
      return;
    }

    search = search.startsWith('@') ? search.substring(1).toLowerCase() : search.toLowerCase();

    this.filteredUsers.next(this.users.filter((user) => user.email?.toLowerCase().includes(search) && !this.isUserSelected(user)));
  }

  addUser(event: MatChipInputEvent): void {
    const { input, value } = event;
    const trimmedValue = value.trim();

    if (trimmedValue) {
      const user = this.users.find((u) => u.email === trimmedValue);
      if (user && !this.isUserSelected(user)) {
        this.selectedUsers.push(user);
        this.emitUserChange();
        this.filterUsers('');
      }
    }

    if (input) {
      input.value = '';
    }

    this.userCtrl.reset(null, { emitEvent: false });
  }

  removeUser(user: UserModel): void {
    this.selectedUsers = this.selectedUsers.filter((u) => u !== user);
    this.assignedUsers = this.selectedUsers.slice();
    this.userCtrl.reset(null, { emitEvent: false });

    this.resetUserList();
    this.emitUserChange();
    setTimeout(() => this.userInput?.nativeElement.focus(), 0);
  }

  selectUser(event: MatAutocompleteSelectedEvent): void {
    const user = event.option.value;
    if (!this.isUserSelected(user)) {
      this.selectedUsers.push(user);
      this.userCtrl.setValue(null);
      this.autocompleteTrigger?.closePanel();
      this.emitUserChange();
      this.filterUsers('');
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.matAutocomplete) {
      event.preventDefault();

      if (this.autocompleteTrigger?.panelOpen) {
        const options = this.matAutocomplete.options.toArray();
        if (options.length > 0 && !this.isUserSelected(options[0].value)) {
          const firstOption = options[0];
          this.selectedUsers.push(firstOption.value);
          this.userCtrl.setValue(null);
          if (this.userInput) {
            this.userInput.nativeElement.value = '';
          }
          this.autocompleteTrigger?.closePanel();
          this.emitUserChange();
          this.filterUsers('');
        }
      }
    }
    if (event.key === 'Tab' && !this.autocompleteTrigger?.panelOpen) {
      this.clearInputOnLeave();
    }
  }

  clearInputOnLeave(): void {
    if (!this.autocompleteTrigger?.panelOpen) {
      this.userCtrl.setValue('');
      if (this.userInput) {
        this.userInput.nativeElement.value = '';
      }
    }
  }

  resetUserList() {
    this.filteredUsers.next(this.users.slice().filter((user) => !this.isUserSelected(user)));
  }

  isUserSelected(user: UserModel): boolean {
    return this.selectedUsers.some((selectedUser) => selectedUser.id === user.id);
  }

  private emitUserChange() {
    this.userSelected.emit({ users: this.selectedUsers, isChanged: true });
  }
}
