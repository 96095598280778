import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives/directives.module';
import { MaterialModule } from '../material-module/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { ActionFormComponent } from './action/action-form/action-form.component';
import { ActionModule } from './action/action.module';
import { FiltersForColumnsComponent } from './components/filters-for-columns/filters-for-columns.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FiltersModule } from './filters/filters.module';
import { MainToolbarModule } from './main-toolbar/main-toolbar.module';
import { DuplicateByComponent } from './modals/duplicate-by/duplicate-by.component';
import { ItemPopupComponent } from './modals/item-popup/item-popup.component';
import { PhotoPopupComponent } from './modals/photo-popup/photo-popup.component';
import { SaveAsPopupComponent } from './modals/save-as-popup/save-as-popup.component';
import { UploadPhotoPopupComponent } from './modals/upload-photo-popup/upload-photo-popup.component';
import { QuickActionButonsComponent } from './quick-action-butons/quick-action-butons.component';
import { SemTableComponent } from './sem-table/sem-table.component';
import { AutocompleteColumnComponent } from './table-display/columns-switch/columns/autocomplete-column/autocomplete-column.component';
import { SelectColumnComponent } from './table-display/columns-switch/columns/select-column/select-column.component';
import { SimpleColumnComponent } from './table-display/columns-switch/columns/simple-column/simple-column.component';
import { TableDisplayModule } from './table-display/table-display.module';

@NgModule({
  declarations: [
    DuplicateByComponent,
    FiltersForColumnsComponent,
    ImageUploaderComponent,
    ItemPopupComponent,
    PhotoPopupComponent,
    QuickActionButonsComponent,
    SaveAsPopupComponent,
    SemTableComponent,
    SpinnerComponent,
    UploadPhotoPopupComponent,
  ],
  imports: [
    ActionModule,
    DirectivesModule,
    FiltersModule,
    MainToolbarModule,
    MaterialModule,
    MaterialModule,
    PipesModule,
    TableDisplayModule,
    TranslateModule,
  ],
  exports: [
    ActionFormComponent,
    ActionModule,
    AutocompleteColumnComponent,
    DirectivesModule,
    FiltersForColumnsComponent,
    FiltersModule,
    ImageUploaderComponent,
    MainToolbarModule,
    PhotoPopupComponent,
    PipesModule,
    SelectColumnComponent,
    SemTableComponent,
    SimpleColumnComponent,
  ],
})
export class SemTableModule {}
