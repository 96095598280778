import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Observable, Subject, merge } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { mainTableColumnDefaultGroup } from '../../../enums';
import { TableConfigurationModel } from '../../../models/TableConfiguration.model';
import { ColumnTypesEnum } from '../../../table-display/ColumnTypesEnum';
import { ColumnModel } from '../../../table-display/columns-switch/columns/column.model';

@Component({
  selector: 'sem-add-found-from-field',
  templateUrl: './add-found-from-field.component.html',
  styleUrls: ['./add-found-from-field.component.scss'],
})
export class AddFoundFromFieldComponent implements OnInit, OnDestroy {
  @Input() config!: TableConfigurationModel;
  @Input() targetColumn!: ColumnModel;
  @Output() valueChanged: EventEmitter<{ values: string[]; target: string; source: string }> = new EventEmitter();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new UntypedFormControl('');
  columnsAvailable: ColumnModel[] = [];
  filteredColumns$!: Observable<ColumnModel[]>;
  private resetColumnsFiltered$: Subject<string> = new Subject();
  private onDestroy$: Subject<void> = new Subject();
  private form: UntypedFormGroup = new UntypedFormGroup({
    values: new UntypedFormControl([], [Validators.required, Validators.minLength(1)]),
    source: new UntypedFormControl('', [Validators.required]),
  });

  get valuesCtrl() {
    return this.form.get('values') as UntypedFormControl;
  }

  get sourceCtrl() {
    return this.form.get('source') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.columnsAvailable = Object.values(this.config.columns)
      .filter((column) => column.type !== ColumnTypesEnum.IMAGE)
      .filter((column) => column.type !== ColumnTypesEnum.INNER)
      .filter((column) => column.group === mainTableColumnDefaultGroup)
      .sort((a, b) => this.sort(a.alias, b.alias));

    this.filteredColumns$ = merge(this.sourceCtrl.valueChanges, this.resetColumnsFiltered$).pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      map((value) => this.filter(value.alias ? value.alias : value)),
    );

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => this.valueChanged.emit({ ...value, source: value.source.param }));
  }

  add(value: string) {
    if (!value || this.valuesCtrl.value.includes(value)) {
      return;
    }
    const values = [...this.valuesCtrl.value, value];
    this.valuesCtrl.setValue(values);
    this.inputCtrl.setValue('');
  }

  remove(value: string) {
    const values = this.valuesCtrl.value;
    values.splice(values.indexOf(value), 1);
    this.valuesCtrl.setValue(values);
  }

  onFocus() {
    this.resetColumnsFiltered$.next('');
  }

  onBlur(value: ColumnModel, input: MatInput) {
    if (!value.param) {
      input.value = '';
    }
  }

  columnDisplayAs(column: ColumnModel) {
    return column.alias;
  }

  private sort(a: string, b: string) {
    const lowerCaseA = a.toLowerCase();
    const lowerCaseB = b.toLowerCase();
    if (lowerCaseA < lowerCaseB) {
      return -1;
    }
    if (lowerCaseA > lowerCaseB) {
      return 1;
    }
    return 0;
  }

  private filter(name: string): ColumnModel[] {
    const filterValue = name.toLowerCase();
    return this.columnsAvailable.filter((option) => option.alias.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
