import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ConfigService } from './config.service';

export interface SortingEvent {
  columnName: string | null;
  direction: 'asc' | 'desc' | null;
}

@Injectable()
export class SortingService {
  sorting$ = new BehaviorSubject<SortingEvent>({
    columnName: null,
    direction: null,
  });

  constructor(private configService: ConfigService) {
    this.configService.config$
      .pipe(first())
      .subscribe((config) => this.sorting$.next({ columnName: config.sorting?.active!, direction: config.sorting?.direction! }));
  }
}
