import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sem-save-as-popup',
  templateUrl: './save-as-popup.component.html',
  styleUrls: ['./save-as-popup.component.scss'],
})
export class SaveAsPopupComponent {
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });

  saveAsTabCtrl = new UntypedFormControl(false);

  constructor(
    public dialogRef: MatDialogRef<SaveAsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveAsPopupData,
  ) {}

  get name() {
    return this.form.get('name') as UntypedFormControl;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close({
      name: this.name.value,
      saveAsTab: this.saveAsTabCtrl.value,
    });
  }
}

export interface SaveAsPopupData {
  title: string;
  subtitle: string;
  type: 'column' | 'filters';
}
