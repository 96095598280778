<mat-form-field class="container">
  <mat-chip-grid #chipList>
    <mat-chip-row (removed)="removeUser(user)" *ngFor="let user of selectedUsers" matTooltipPosition="above">
      {{ user.email }}
      <button [attr.aria-label]="'remove ' + user.email" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #userInput
    (blur)="clearInputOnLeave()"
    (input)="onInput(userInput.value)"
    (keydown)="handleKeydown($event)"
    (matChipInputTokenEnd)="addUser($event)"
    [formControl]="userCtrl"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [placeholder]="selectedUsers.length > 0 ? '' : ('components.user_autocomplete.placeholder' | translate)"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectUser($event)">
    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
      {{ user.email }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
