import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { ColumnsService } from '../../services/columns.service';
import { GroupService } from '../../services/group.service';
import { SelectedService } from '../../services/selected.service';
import { SortingEvent, SortingService } from '../../services/sorting.service';
import { ColumnTypesEnum } from '../ColumnTypesEnum';
import { ColumnModel } from '../columns-switch/columns/column.model';

function comparisonFunction(a: [boolean, boolean], b: [boolean, boolean]) {
  return a[0] === b[0] && a[1] === b[1];
}
@Component({
  selector: 'sem-headers-displayer',
  templateUrl: './headers-displayer.component.html',
  styleUrls: ['./headers-displayer.component.scss'],
})
export class HeadersDisplayerComponent implements OnInit, OnDestroy {
  @Input() column!: ColumnModel;
  @Input() config!: TableConfigurationModel;
  @Input() index!: number;
  @Input() tableCells!: QueryList<ElementRef<HTMLTableCellElement>>;
  sorting: SortingEvent | null = null;
  columnTypes = ColumnTypesEnum;
  private onDestroy$: Subject<void> = new Subject();
  displayedColumns$ = this.columnsService.displayedColumns$;
  indeterminateState$ = combineLatest([this.selectedService.isAnyItemSelected$, this.selectedService.isAllAtPageSelected$]).pipe(
    distinctUntilChanged(comparisonFunction),
    map(([anySelected, allAtPage]) => anySelected && !allAtPage),
  );

  checkedState$ = combineLatest([this.selectedService.isAllAtPageSelected$, this.selectedService.selectedGlobally$]).pipe(
    distinctUntilChanged(comparisonFunction),
    map(([allAtPage, globallSelected]) => allAtPage || globallSelected),
  );

  constructor(
    private columnsService: ColumnsService,
    private sortingService: SortingService,
    private groupService: GroupService,
    private selectedService: SelectedService,
  ) {}

  ngOnInit(): void {
    this.sortingService.sorting$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => (this.sorting = data && data.columnName === this.getColumnName() ? data : null));
  }

  selectClicked() {
    this.selectedService.mainCheckboxClick();
  }

  groupBy(event: MouseEvent, columnName: string) {
    event.stopPropagation();
    this.groupService.addToGroupedBy$.next(columnName);
  }

  setSorting() {
    let sortingEvent: SortingEvent | null;
    if (!this.column.canBeSorted) {
      return;
    }
    if (this.sorting && this.sorting.direction === 'desc') {
      sortingEvent = null;
    } else {
      sortingEvent = {
        columnName: this.getColumnName(),
        direction: this.sorting ? 'desc' : 'asc',
      };
    }
    this.sortingService.sorting$.next(sortingEvent!);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private getColumnName(): string {
    const { baseColumnName, param } = this.column;

    return baseColumnName || param;
  }
}
