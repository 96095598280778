import { Subject } from 'rxjs';

export class SimpleMapping {
  constructor(public rules: { [value: string]: string }) {}

  map(value: any): string {
    return this.rules[value] ? this.rules[value].toString() : value;
  }

  getByKey(key: any): string | number {
    return this.rules[key] || null!;
  }

  getValues(): Array<string | number> {
    const values = Object.keys(this.rules).map((key: string) => key);

    return values.map((key) => (/^\d+$/.test(key) ? +key : key));
  }
}

export class ObjectMapping {
  constructor(
    public displayValueAs: string | string[],
    private fields: { [key: string]: ColumnObjectField },
  ) {}

  map(value: any): string {
    if (Object.prototype.toString.call(this.displayValueAs) === '[object Array]') {
      this.displayValueAs = this.displayValueAs as string[];
      if (value) {
        let resString = value[this.displayValueAs[0]] || '';

        this.displayValueAs
          .filter((data, i) => i > 0)
          .forEach((displayProp) => {
            const value2 = value[displayProp]?.trim();
            value2 && (resString += ` - ${value2}`);
          });
        return resString;
      }

      return '';
    }
    this.displayValueAs = this.displayValueAs as string;
    return value ? value[this.displayValueAs] : '';
  }

  returnFieldsKeys(): string[] {
    return Object.keys(this.fields);
  }

  getByKey(key: string): ColumnObjectField {
    return this.fields[key];
  }

  getValues(): any {
    return [];
  }
}

export enum ColumnObjectFieldTypesEnum {
  number,
  text,
  autocomplete,
  select,
}

export class ColumnObjectField {
  autocomplete$: Subject<any[]> = new Subject();

  constructor(
    public alias: string,
    public type: ColumnObjectFieldTypesEnum,
    public required?: boolean,
  ) {}
}
