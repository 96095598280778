import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { DynamicValue } from '../../../models/DynamicValue.model';
import { TableConfigurationModel } from '../../../models/TableConfiguration.model';
import { AutocompleteEventModel } from '../../../models/autocompleteEvent.model';
import { AutocompleteService } from '../../../services/autocomplete.service';

@Component({
  selector: 'sem-value-generator',
  templateUrl: './value-generator.component.html',
  styleUrls: ['./value-generator.component.scss'],
})
export class ValueGeneratorComponent {
  properties: string[] = [];
  filteredProperties!: Observable<string[]>;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @Input() config!: TableConfigurationModel;
  @Input() autocompleteService!: AutocompleteService;
  @Output() valueChanged: EventEmitter<DynamicValue> = new EventEmitter();

  catchValue(data: DynamicValue) {
    this.valueChanged.emit(data);
    this.trigger.closeMenu();
  }

  autocompleteChanged(changedEvent: AutocompleteEventModel) {
    this.autocompleteService.initAutocompleteChange$.next(changedEvent);
  }
}
