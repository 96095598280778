<ng-container>
  <h2 mat-dialog-title>{{ data.column!.alias }}</h2>
  <button class="close-button" mat-dialog-close mat-icon-button tabindex="-1">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <mat-dialog-content>
    <div *ngFor="let value of data.value" class="object">
      <div *ngFor="let field of fields" class="value-container">
        <div class="param">
          {{ data.column!.valueMapping.getByKey(field).alias }}
        </div>
        <div class="value">
          {{ value[field] }}
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-container>
