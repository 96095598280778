import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../directives/directives.module';
import { MaterialModule } from '../../material-module/material.module';
import { PipesModule } from '../../pipes/pipes.module';
import { FiltersModule } from '../filters/filters.module';
import { SemTableSharedModule } from '../sem-table-shared/sem-table-shared.module';
import { CurrentFiltersDisplayerComponent } from './current-filters-displayer/current-filters-displayer.component';
import { CustomButtonsComponent } from './custom-buttons/custom-buttons.component';
import { MainToolbarComponent } from './main-toolbar/main-toolbar.component';
import { ColumnToolComponent } from './tool-displayer/column-tool/column-tool.component';
import { SelectToolComponent } from './tool-displayer/select-tool/select-tool.component';
import { TabsToolComponent } from './tool-displayer/tabs-tool/tabs-tool.component';
import { ToolDisplayerComponent } from './tool-displayer/tool-displayer.component';

@NgModule({
  declarations: [
    MainToolbarComponent,
    CurrentFiltersDisplayerComponent,
    CustomButtonsComponent,
    ToolDisplayerComponent,
    ColumnToolComponent,
    SelectToolComponent,
    TabsToolComponent,
  ],
  imports: [DirectivesModule, FiltersModule, MaterialModule, PipesModule, SemTableSharedModule, SemTableSharedModule, TranslateModule],
  exports: [DirectivesModule, MainToolbarComponent, CurrentFiltersDisplayerComponent, PipesModule],
})
export class MainToolbarModule {}
