<ng-container>
  <h2 mat-dialog-title></h2>
  <button class="close-button" mat-dialog-close mat-icon-button tabindex="-1">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="container">
      <div class="photo-container">
        <div class="data-container">
          <ng-container *ngIf="data.additionalData as additionalData">
            <ng-container *ngFor="let column of additionalData; let i = index">
              <span class="additionalData">{{ additionalData[i] }}</span>
              <span *ngIf="additionalData.length - 1 !== i" class="slash">/</span>
            </ng-container>
          </ng-container>
        </div>
        <img [src]="data.photo" class="photo" />
      </div>
    </div>
  </mat-dialog-content>
</ng-container>
