import { ColumnTypesEnum, ColumnValueTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnModel } from '../column.model';

export interface ActionColumnItemInterface {
  label: string;
  value: string;
}

export interface SelectedActionInterface {
  id: number | string;
  value: string;
  item: unknown;
}

export class ActionColumn extends ColumnModel {
  alias = 'action';
  type = ColumnTypesEnum.ACTION;
  valueType = ColumnValueTypesEnum.custom;
}
