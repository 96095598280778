<ng-container>
  <h2 mat-dialog-title>{{ data.column!.alias }}</h2>
  <button class="close-button" mat-dialog-close mat-icon-button tabindex="-1">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <mat-dialog-content>
    <form (ngSubmit)="submit()" [cdkTrapFocusAutoCapture]="true" [formGroup]="form" cdkTrapFocus>
      <div *ngFor="let group of formArray.controls; index as index" class="object" formArrayName="formArray">
        <div [formGroupName]="index">
          <button (click)="remove(index)" class="delete-icon" color="warn" mat-icon-button type="button">
            <mat-icon>delete</mat-icon>
          </button>
          <ng-container *ngFor="let field of fieldsInColumn" [ngSwitch]="data.column!.valueMapping.getByKey(field).type">
            <ng-container *ngSwitchCase="columnFieldTypes.text">
              <mat-form-field class="input">
                <input
                  [formControlName]="field"
                  [placeholder]="data.column!.valueMapping.getByKey(field).alias"
                  [required]="data.column!.valueMapping.getByKey(field).required!"
                  cdkFocusInitial
                  matInput
                />
              </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="columnFieldTypes.autocomplete">
              <mat-form-field>
                <input
                  #input
                  #trigger="matAutocompleteTrigger"
                  [formControlName]="field"
                  [matAutocomplete]="autocomplete"
                  [placeholder]="data.column!.valueMapping.getByKey(field).alias"
                  [required]="data.column!.valueMapping.getByKey(field).required!"
                  matInput
                  type="text"
                />

                <mat-autocomplete #autocomplete="matAutocomplete" [autoActiveFirstOption]="true">
                  <mat-option *ngFor="let value of data.column!.valueMapping.getByKey(field).autocomplete$ | async" [value]="value">
                    {{ value }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button (click)="addNew()" class="add-icon" color="primary" mat-raised-button>{{ 'sem_table.add_new_value' | translate }}</button>
    <button (click)="submit()" class="submit" color="primary" mat-raised-button>{{ 'sem_table.save' | translate }}</button>
  </mat-dialog-actions>
</ng-container>
