<div
  (click)="fileInput.click()"
  (dragleave)="onDragLeave()"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)"
  [ngClass]="{ 'drag-over': isDragOver && !disable }"
  class="container"
  enctype="multipart/form-data"
>
  <input #fileInput (change)="onChange($event)" [accept]="accept" [multiple]="isMultiple" class="file-input" type="file" />
  <mat-icon>publish</mat-icon>
  <div class="placeholder">{{ placeholder }}</div>
</div>
<!--
<div class="previewContainer">
  <div class="preview" *ngFor="let file of filesToUploadPreviewsUrl">
    <img [src]="file" alt="">
  </div>
</div> -->
