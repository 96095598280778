import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sem-upload-photo-popup',
  templateUrl: './upload-photo-popup.component.html',
  styleUrls: ['./upload-photo-popup.component.scss'],
})
export class UploadPhotoPopupComponent implements OnInit, OnDestroy {
  disable = false;
  imagesInApi!: any[];
  sub!: Subscription;
  initMainImageId!: number;

  constructor(public dialogRef: MatDialogRef<UploadPhotoPopupComponent>) {}

  @HostListener('keydown', ['$event']) c(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.getImages();
  }

  setCloseSubscription() {
    this.sub = this.dialogRef.backdropClick().subscribe(() => {
      const actualMainImage = this.imagesInApi.find((image) => image.main);
      const res = actualMainImage && this.initMainImageId !== actualMainImage.id ? actualMainImage : null;
      this.dialogRef.close(res);
    });
  }

  getImages() {}

  onClick() {}

  sendImagesToApi() {}

  deleteImage() {}

  close() {
    // console.log(this.product);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
