import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionService } from '../action/action.service';
import { ActionModel } from '../action/models/ActionModel';
import { ItemModel } from '../item.model';
import { ChangesService } from '../services/changes.service';
import { CommunicationService } from '../services/communication.service';
import { SequenceService } from '../services/sequence.service';

@Component({
  selector: 'sem-quick-action-butons',
  templateUrl: './quick-action-butons.component.html',
  styleUrls: ['./quick-action-butons.component.scss'],
})
export class QuickActionButonsComponent {
  @Input() requiredFields!: string[];
  @Output() actionEmitted: EventEmitter<ActionModel> = new EventEmitter();
  @Output() dataToUpdate: EventEmitter<ItemModel[]> = new EventEmitter();
  @Output() sequenceEmitted: EventEmitter<Array<ActionModel[] | ItemModel[]>> = new EventEmitter();
  public actionService = inject(ActionService);
  public changesService = inject(ChangesService);
  public sequenceService = inject(SequenceService);
  private translateService = inject(TranslateService);
  private communicationService = inject(CommunicationService);

  protected update() {
    if (this.requiredFields && this.requiredFields.length) {
      const incompleteData = [];

      this.sequenceService.sequence.forEach((sequence) => {
        const typedSequence = sequence as Array<ItemModel | ActionModel>;
        const incompleteSequence = typedSequence.filter((item) => !this.requiredFields.every((key) => item[key as keyof typeof item]));

        if (incompleteSequence && incompleteSequence.length) {
          incompleteData.push(incompleteSequence);
        }
      });

      if (incompleteData.length) {
        const message = this.translateService.instant('sem_table.incomplete_data');
        this.communicationService.customMessage$.next({ type: 'error', case: 'DEFAULT', message });

        return false;
      }
    }

    this.sequenceService.sequence.forEach((operation) => {
      if (this.sequenceService.sequenceWithGlobalAction(operation)) {
        const action = operation[0] as ActionModel;
        this.actionEmitted.next(action);
      } else {
        const items = operation as ItemModel[];
        this.dataToUpdate.next(items);
      }
    });

    this.sequenceEmitted.next(this.sequenceService.sequence);
    this.changesService.clearChanges$.next();
    this.changesService.clearUpdates$.next();
    this.actionService.clearActions$.next();
  }
}
