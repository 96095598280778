import { TruncatePipe } from '../../../../../pipes/truncate.pipe';
import { ActionTypesEnum } from '../../../../action/ActionTypesEnum';
import { mainTableColumnDefaultGroup } from '../../../../enums';
import { FilterTypesEnum } from '../../../../filters/FilterTypesEnum';
import { ItemModel } from '../../../../item.model';
import { CustomButtonPerItemInterface } from '../../../../models/CustomButton.model';
import { SimpleMapping } from '../../../../models/Mapping.model';
import { ToolInterface } from '../../../../models/TableConfigurationInterface.model';
import { AgregationInterface } from '../../../../models/agregation.model';
import { ColumnTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnComponentModel, ColumnModel } from '../column.model';

export class SelectColumn extends ColumnModel {
  actionsAvailableTypes = [ActionTypesEnum.override];
  defaultValue: any;
  description: string;
  filterAvailableTypes = [FilterTypesEnum.equal];
  isValueToTranslate = false;
  possibleValues: Array<string | number>;
  truncatePipe: TruncatePipe = new TruncatePipe();
  type = ColumnTypesEnum.SELECT;
  valueMapping: SimpleMapping;

  constructor(config: {
    ableToFilter?: true;
    actionsAvailable?: ActionTypesEnum[];
    aggregate?: boolean;
    aggregateBySelect?: AgregationInterface;
    alias?: string;
    baseColumnName?: string;
    canBeSorted?: boolean;
    component?: ColumnComponentModel;
    customButton?: CustomButtonPerItemInterface[];
    defaultValue?: any;
    editable?: ToolInterface;
    filtersAvailable?: FilterTypesEnum[];
    group?: string;
    grouping?: true;
    isValueToTranslate?: boolean;
    required?: boolean;
    showAliasWithoutTranslation?: boolean;
    tooltip?: string;
    valueMapping?: SimpleMapping;
    disabledIn?: {
      duplicatePopup?: boolean;
      editPopup?: boolean;
      addPopup?: boolean;
      usingAsFilterInMassOperations?: boolean;
    };
    description?: string;
  }) {
    super();
    this.valueMapping = config.valueMapping!;

    this.ableToFilter = config.ableToFilter;
    this.actionsAvailableTypes = config.actionsAvailable ? config.actionsAvailable : this.actionsAvailableTypes;
    this.aggregate = config.aggregate;
    this.aggregateBySelect = config.aggregateBySelect;
    this.alias = config.alias || '';
    this.baseColumnName = config.baseColumnName!;
    this.canBeSorted = config.canBeSorted;
    this.customButton = config.customButton;
    this.defaultValue = config.defaultValue || null;
    this.description = config.description!;
    this.disabledIn = config.disabledIn;
    this.editable = config.editable;
    this.filterAvailableTypes = config.filtersAvailable ? config.filtersAvailable : this.filterAvailableTypes;
    this.group = config.group || mainTableColumnDefaultGroup;
    this.grouping = config.grouping;
    this.isValueToTranslate = config.isValueToTranslate || this.isValueToTranslate;
    this.possibleValues = this.valueMapping ? this.valueMapping.getValues() : [];
    this.required = config.required!;
    this.showAliasWithoutTranslation = config.showAliasWithoutTranslation;
    this.tooltip = config.tooltip;

    if (config.component) {
      this.setComponent(config.component);
    }
  }

  returnValueForDisplay(item: ItemModel): string {
    const value = this.valueMapping.map(item[this.param as keyof typeof item]);
    return this.displayFullLength ? this.truncatePipe.transform(value, 70) : value;
  }

  returnValue(item: ItemModel): string {
    return this.valueMapping.map(item[this.param as keyof typeof item]);
  }
}
