import { mainTableColumnDefaultGroup } from '../../../enums';
import { ColumnTypesEnum, ColumnValueTypesEnum, innerColumnName } from '../../ColumnTypesEnum';
import { ColumnComponentModel, ColumnModel } from './column.model';

export class InnerColumn extends ColumnModel {
  actionsAvailableTypes = [];
  alias = innerColumnName;
  group = mainTableColumnDefaultGroup;
  styleClass = '';
  type = ColumnTypesEnum.INNER;

  constructor(config?: { component?: ColumnComponentModel; responseMapping?: any; styleClass?: string; valueType?: ColumnValueTypesEnum }) {
    super();

    if (config) {
      if (config.component) {
        this.component = config.component;
      }

      if (config.styleClass) {
        this.styleClass = config.styleClass;
      }

      this.responseMapping = config.responseMapping!;
      this.valueType = config.valueType;
    }
  }

  returnPossibleValues() {
    return null;
  }

  returnValueForDisplay() {
    return null;
  }

  returnValue() {
    return null;
  }

  isEditable() {
    return null;
  }

  isRequired() {
    return null;
  }

  isAbleToSort() {
    return false;
  }
}
