<mat-form-field appearance="outline" class="action-column">
  <mat-select
    (openedChange)="openedChange($event)"
    (selectionChange)="selectionChange($event.value)"
    [panelWidth]="null"
    [placeholder]="'sem_table.action' | translate"
    floatlLabel="never"
    id="row-action-{{ id }}"
    multiple="false"
  >
    <ng-container *ngIf="!isLoading && (actionsList$ | async) as actionsList; else loading">
      <mat-option *ngFor="let option of actionsList" [value]="option">
        {{ option.label }}
      </mat-option>
    </ng-container>

    <ng-template #loading>
      <mat-option disabled>
        <div class="select-loader">
          <mat-spinner class="spinner" diameter="20"></mat-spinner>
        </div>
      </mat-option>
    </ng-template>
  </mat-select>
</mat-form-field>
