<div class="container">
  <mat-form-field appearance="outline">
    <mat-select
      (openedChange)="toggled()"
      [formControl]="formControl"
      [panelWidth]="null"
      placeholder="{{ 'sem_table.by' | translate | titlecase }} {{ column.alias }}"
    >
      <div class="tw-m-2">
        <mat-form-field *ngIf="valuesWithId && valuesWithId.length > 3" appearance="outline">
          <input [formControl]="searchControl" [placeholder]="'sem_table.search' | translate | titlecase" matInput />
        </mat-form-field>
      </div>

      <mat-option *ngFor="let option of selectedValuesWithId" [value]="option">
        {{ option | mapColumnValue: column }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
