import { Pipe, PipeTransform } from '@angular/core';
import { ItemModel } from '../../item.model';
import { ToolInterface } from '../../models/TableConfigurationInterface.model';

@Pipe({
  name: 'toolActivenessManager',
})
export class ToolActivenessManagerPipe implements PipeTransform {
  transform(tool: ToolInterface, item: ItemModel): boolean {
    return tool && (tool.activeAlways || ((tool.dependsOnParam && item[tool.dependsOnParam as keyof ItemModel]) as boolean));
  }
}
