import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'sem-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
  // filesToUploadPreviewsUrl: string[] = [];
  isDragOver = false;
  @Input() allowedTypes = ['image/jpeg', 'image/jpg', 'image/bmp', 'image/png'];
  @Input() disable = false;
  @Input() isMultiple = false;
  @Input() placeholder: unknown;

  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() filesToUpload: EventEmitter<File[]> = new EventEmitter();

  get accept() {
    // TODO: It is IMAGE uploader, so should be limited to image extensions only?
    return this.allowedTypes.join(',');
  }

  constructor(private cd: ChangeDetectorRef) {}

  onDrop(event: DragEvent) {
    if (this.disable) {
      return;
    }

    event.preventDefault();
    this.isDragOver = false;
    if (event.dataTransfer!.items) {
      const droppedFiles: File[] = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < event.dataTransfer!.items.length; i++) {
        const item = event.dataTransfer!.items[i];
        if (item.kind === 'file') {
          const file: File | null = event.dataTransfer!.items[i].getAsFile();
          file && droppedFiles.push(file);
        }
      }

      droppedFiles.length && this.catchFileLogic(droppedFiles);
    }
  }

  onChange(event: any) {
    // eslint-disable-next-line prefer-destructuring
    const files: FileList = event.target.files;
    this.catchFileLogic(Array.from(files));
  }

  onClick(event: MouseEvent) {
    if (this.disable) {
      return;
    }
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave() {
    this.isDragOver = false;
  }

  private catchFileLogic(files: File[]) {
    const filesToUpload: File[] = files.filter((file) => file.type && this.allowedTypes.includes(file.type));
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = () => {
    //   const base64 = reader.result as string;
    //   this.filesToUploadPreviewsUrl.push(base64);
    //   this.cd.detectChanges();
    // };
    this.filesToUpload.emit(filesToUpload);
  }
}
