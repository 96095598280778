<div #input (click)="click()" class="container {{ column.param }}-column-display" tabindex="0">
  @if (value && value[0]) {
    <div class="chip-value-container">
      @for (val of value; track val) {
        <div class="chip-value">
          {{ val | mapColumnValue: column }}
        </div>
      }
    </div>
  } @else {
    <div class="empty">{{ column.emptyValueLabel }}</div>
  }
</div>
