import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { TableConfigurationModel } from '../../../../../models/TableConfiguration.model';
import { AutocompleteEventModel } from '../../../../../models/autocompleteEvent.model';
import { AutocompleteService } from '../../../../../services/autocomplete.service';
import { ColumnModel } from '../../../../../table-display/columns-switch/columns/column.model';
import { FilterTypesEnum } from '../../../../FilterTypesEnum';
import { FilterFactoryService } from '../../../../filtersFactory.service';

@Component({
  selector: 'sem-single-filter-form',
  templateUrl: './single-filter-form.component.html',
  styleUrls: ['./single-filter-form.component.scss'],
})
export class SingleFilterFormComponent implements OnDestroy, OnInit {
  @Input() autocompleteService!: AutocompleteService;
  @Input() config!: TableConfigurationModel;
  @Input() columns!: ColumnModel[];
  @Input() filterFactoryService!: FilterFactoryService;
  @Input() isFirst!: boolean;
  @Input() filterForm!: AbstractControl;
  @Output() removed = new EventEmitter<void>();
  @Input() filter!: AbstractControl;
  isArrayLike = false;
  private onDestroy$: Subject<void> = new Subject();

  private readonly dialog = inject(MatDialog);

  get columnCtrl(): UntypedFormControl {
    return this.filterForm.get('column') as UntypedFormControl;
  }

  get filterTypeCtrl(): UntypedFormControl {
    return this.filterForm.get('type') as UntypedFormControl;
  }

  get additionalsCtrl() {
    return this.filterForm.get('additional_sources');
  }

  get operatorCtrl(): UntypedFormControl {
    return this.filterForm.get('operator') as UntypedFormControl;
  }

  get valueCtrl(): UntypedFormControl {
    return this.filterForm.get('value') as UntypedFormControl;
  }

  get valueDataCtrl() {
    return this.valueCtrl.get('data');
  }

  get valueConfigCtrl() {
    return this.valueCtrl.get('config');
  }

  ngOnInit(): void {
    const arrayLikeTypes = [FilterTypesEnum.exists_in, FilterTypesEnum.not_exists_in];
    this.isArrayLike = arrayLikeTypes.includes(this.filterTypeCtrl.value);

    this.columnCtrl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((column) => {
      this.filterTypeCtrl.setValue(column.filterAvailableTypes[0]);

      if (column.dialog) {
        this.additionalsCtrl!.setValidators([Validators.required]);

        this.openDialog(column);
      } else {
        this.additionalsCtrl!.clearValidators();
        this.additionalsCtrl?.setValue(null);
      }
      this.additionalsCtrl!.updateValueAndValidity();
    });

    this.filterTypeCtrl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        tap((type: FilterTypesEnum) => (this.isArrayLike = arrayLikeTypes.includes(type))),
      )
      .subscribe();
  }

  clearAll() {
    this.valueDataCtrl!.setValue([]);
  }

  autocompleteChanged(changedEvent: AutocompleteEventModel) {
    this.autocompleteService.initAutocompleteChange$.next(changedEvent);
  }

  removeFilter() {
    this.removed.emit();
  }

  removeElementFromValue(el: string) {
    const values = cloneDeep(this.valueDataCtrl!.value);
    const index = values.indexOf(el);
    if (index > -1) {
      values.splice(index, 1);
    }
    this.valueDataCtrl!.setValue(values);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  protected openDialog(column: ColumnModel) {
    const { config, component } = column.dialog!;
    config.data = { data: this.additionalsCtrl!.value, params: { param: column.param || null } };

    this.dialog
      .open(component, config)
      .afterClosed()
      .pipe(filter((res) => res || res === null))
      .subscribe((data: any) => this.additionalsCtrl!.setValue(data));
  }
}
