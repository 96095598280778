<div class="container">
  <mat-form-field appearance="outline">
    <mat-label>Values to look for</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row (removed)="remove(value)" *ngFor="let value of valuesCtrl.value" [removable]="true">
        {{ value }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        #fruitInput
        (matChipInputTokenEnd)="add(fruitInput.value)"
        [formControl]="inputCtrl"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        placeholder="Enter the value"
      />
    </mat-chip-grid>
  </mat-form-field>

  <div class="columns">
    <mat-form-field appearance="outline">
      <mat-label>Column to be searched</mat-label>
      <input
        #sourceInput="matInput"
        (blur)="onBlur(sourceCtrl.value, sourceInput)"
        (click)="$event.stopPropagation()"
        (focus)="onFocus()"
        [formControl]="sourceCtrl"
        [matAutocomplete]="auto"
        matInput
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="columnDisplayAs" autoActiveFirstOption>
        <mat-option *ngFor="let column of filteredColumns$ | async" [value]="column">
          {{ column.alias }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="separator">
      <mat-icon> double_arrow </mat-icon>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Column to put value</mat-label>
      <input [value]="config.columns[targetColumn.param].alias" disabled matInput />
    </mat-form-field>
  </div>
</div>
