<app-dialog [dialogRef]="dialogRef">
  <div>
    <h3>
      {{ (isProductTask ? 'projects.monitor.results.product.title' : 'projects.monitor.results.phrase.title') | translate }}
      {{ task.query ?? '' }}
      @if (task.product_gtin) {
        (GTIN: {{ task.product_gtin }})
      }
    </h3>
    @if (getRank()) {
      <h4>Your current position: {{ getRank() }}</h4>
    }
  </div>
</app-dialog>

<ng-container>
  <mat-dialog-content class="content">
    @if (isLoaded) {
      @if (taskResults && taskResults.length) {
        <div class="chart">
          <div class="date-container">
            <div class="range-buttons">
              @for (range of sortedRangeEnum; track range) {
                <button (click)="updateOptions(range.value)" [class.active]="activeOptionButton === range.value" mat-button>
                  {{ 'projects.monitor.date_range.' + range.value | translate }}
                </button>
              }
            </div>

            <mat-form-field appearance="fill">
              <mat-label>{{ 'projects.monitor.range_datepicker.enter_range' | translate }}</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                  class="fit-content"
                  formControlName="start"
                  matStartDate
                  placeholder="{{ 'projects.monitor.range_datepicker.start_date' | translate }}"
                />
                <input
                  class="fit-content"
                  formControlName="end"
                  matEndDate
                  placeholder="{{ 'projects.monitor.range_datepicker.end_date' | translate }}"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          @if (!isChartLoading) {
            <app-apex-chart [chartOptions]="chartOptions" [type]="ChartType.line"></app-apex-chart>
          } @else {
            <div class="tw-flex tw-h-4/5">
              <mat-spinner class="tw-m-auto" diameter="50"></mat-spinner>
            </div>
          }
        </div>

        <div class="status">
          @if (task.status) {
            <mat-icon aria-hidden="false">info</mat-icon>
            {{ 'projects.monitor.results.status.' + task.status | translate }}
          }
          <mat-form-field class="tw-ml-auto" subscriptSizing="dynamic">
            <mat-label>{{ 'projects.monitor.results.choose_date' | translate }}</mat-label>
            <input matInput [matDatepicker]="tablePicker" [ngModel]="tableDate" (ngModelChange)="tableDateChanged($event)" />
            <mat-datepicker-toggle matIconSuffix [for]="tablePicker"></mat-datepicker-toggle>
            <mat-datepicker #tablePicker></mat-datepicker>
          </mat-form-field>
        </div>

        @if (isProductTask) {
          <div class="task-descriptions">
            @if (tableDate) {
              <h3>{{ 'projects.monitor.results.results_from' | translate: { date: tableDate.format('YYYY-MM-DD') } }}</h3>
            } @else {
              <h3>{{ 'projects.monitor.results.latest' | translate }}</h3>
            }
            <ng-container *ngTemplateOutlet="productTable"></ng-container>
          </div>
        }

        @if (this.data.type === 'organic' || this.data.type === 'paid') {
          @if (tableDate) {
            <h3>{{ 'projects.monitor.results.results_from' | translate: { date: tableDate.format('YYYY-MM-DD') } }}</h3>
          } @else {
            <h3>{{ 'projects.monitor.results.latest' | translate }}</h3>
          }
          <ng-container *ngTemplateOutlet="phraseTable"></ng-container>
        }

        @if (this.data.type === 'shopping') {
          @if (tableDate) {
            <h3>{{ 'projects.monitor.results.results_from' | translate: { date: tableDate.format('YYYY-MM-DD') } }}</h3>
          } @else {
            <h3>{{ 'projects.monitor.results.latest' | translate }}</h3>
          }
          <ng-container *ngTemplateOutlet="shoppingTable"></ng-container>
        }
      } @else {
        <div class="center">
          {{
            task && task.status
              ? ('projects.monitor.results.status.' + task.status | translate)
              : ('projects.monitor.results.no_data_info' | translate)
          }}
        </div>
      }
    } @else {
      <mat-spinner class="center" diameter="50"></mat-spinner>
    }
  </mat-dialog-content>
</ng-container>

<ng-template #productTable>
  @if (productTableDataSource.length) {
    @if (!isTableLoading) {
      <table [dataSource]="productTableDataSource" class="mat-elevation-z8" mat-table>
        <ng-container matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox
              (change)="masterToggle($event)"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" mat-cell>
            <mat-checkbox
              (change)="$event ? selection.toggle(row) : null; selectionChange()"
              (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="rank_price">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.rank' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ element.rank_price }}</td>
        </ng-container>

        <ng-container matColumnDef="seller">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.seller' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ element.seller }}</td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'projects.monitor.results.price' | translate }} ({{ currency }}
            )
          </th>
          <td *matCellDef="let element" mat-cell>{{ element.price }}</td>
        </ng-container>

        <ng-container matColumnDef="total_price">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.total_price' | translate }} ({{ currency }})</th>
          <td *matCellDef="let element" mat-cell>{{ element.total_price }}</td>
        </ng-container>

        <ng-container matColumnDef="shipping_costs">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.shipping_costs' | translate }} ({{ currency }})</th>
          <td *matCellDef="let element" mat-cell>{{ element.shipping_costs }}</td>
        </ng-container>

        <ng-container matColumnDef="price_min">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.price_min' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ getMinMaxPrice(element.seller, 'min') }}</td>
        </ng-container>

        <ng-container matColumnDef="price_max">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.price_max' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ getMinMaxPrice(element.seller, 'max') }}</td>
        </ng-container>

        <ng-container matColumnDef="url">
          <th *matHeaderCellDef class="table-url" mat-header-cell>Url</th>
          <td *matCellDef="let element" class="table-url" mat-cell>
            <a [href]="element.url" color="primary" mat-button target="_blank">Link</a>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedProductColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedProductColumns" [ngClass]="{ 'my-result': row.is_my_result }" mat-row></tr>
      </table>
    } @else {
      <div class="tw-flex">
        <mat-spinner class="tw-m-auto" diameter="50"></mat-spinner>
      </div>
    }
  }
</ng-template>

<ng-template #phraseTable>
  @if (phraseTableDataSource.length) {
    @if (!isTableLoading) {
      <table [dataSource]="phraseTableDataSource" class="mat-elevation-z8" mat-table>
        <ng-container matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox
              (change)="masterToggle($event)"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" mat-cell>
            <mat-checkbox
              (change)="$event ? selection.toggle(row) : null; selectionChange()"
              (click)="$event.stopPropagation()"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="rank_group">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.rank' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ element.rank_group }}</td>
        </ng-container>

        <ng-container matColumnDef="rank_min">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.rank_min' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ minmaxData[element.domain].min }}</td>
        </ng-container>

        <ng-container matColumnDef="rank_max">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.rank_max' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ minmaxData[element.domain].max }}</td>
        </ng-container>

        <ng-container matColumnDef="domain">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.domain' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ element.domain }}</td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.title' | translate }}</th>
          <td *matCellDef="let element" mat-cell>{{ element.title }}</td>
        </ng-container>

        <ng-container matColumnDef="url">
          <th *matHeaderCellDef class="table-url" mat-header-cell>Url</th>
          <td *matCellDef="let element" class="table-url" mat-cell>
            <a [href]="element.url" color="primary" mat-button target="_blank">Link</a>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedPhraseColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedPhraseColumns" [ngClass]="{ 'my-result': row.is_my_result }" mat-row></tr>
      </table>
    } @else {
      <div class="tw-flex">
        <mat-spinner class="tw-m-auto" diameter="50"></mat-spinner>
      </div>
    }
  }
</ng-template>

<ng-template #shoppingTable>
  @if (phraseTableDataSource.length) {
    <table [dataSource]="phraseTableDataSource" class="mat-elevation-z8" mat-table>
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            (change)="masterToggle($event)"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell>
          <mat-checkbox
            (change)="$event ? selection.toggle(row) : null; selectionChange()"
            (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="rank_group">
        <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.rank' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.rank_group }}</td>
      </ng-container>

      <ng-container matColumnDef="rank_min">
        <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.rank_min' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ minmaxData[element.domain].min }}</td>
      </ng-container>

      <ng-container matColumnDef="rank_max">
        <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.rank_max' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ minmaxData[element.domain].max }}</td>
      </ng-container>

      <ng-container matColumnDef="domain">
        <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.domain' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.domain }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.price' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.price }}</td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-header-cell>{{ 'projects.monitor.results.title' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.title }}</td>
      </ng-container>

      <ng-container matColumnDef="url">
        <th *matHeaderCellDef class="table-url" mat-header-cell>Url</th>
        <td *matCellDef="let element" class="table-url" mat-cell>
          <a [href]="element.url" color="primary" mat-button target="_blank">Link</a>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedShoppingAdsColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedShoppingAdsColumns" [ngClass]="{ 'my-result': row.is_my_result }" mat-row></tr>
    </table>
  }
</ng-template>
