import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ProjectAvailableFieldsToMapModel } from 'src/app/shared/custom-output-feed-mapper/custom-mapper/model/OutputFeedSchema.model';
import ApiUrls from '../../configs/api-urls.config';
import { PaginationInterface, PaginationResourceInterface, ResponseV2Interface } from '../../shared/model/response.model';
import { FilterGroupModel } from '../../shared/sem-table/filters/models/filter.model';
import { TableChangeDataEmittedInterface } from '../../shared/sem-table/models/TableChangeDataEmitted.model';
import { HelperService } from '../../shared/service/helper.service';
import { ProjectTypesEnum } from './project-types.enum';
import { CurrencyData, ProjectModel } from './project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService implements OnDestroy {
  activeProject$ = new BehaviorSubject<ProjectModel | null>(null);
  projects$: BehaviorSubject<ProjectModel[]> = new BehaviorSubject<ProjectModel[]>([]);

  starredProjects: ProjectModel[] = [];
  starredProjectsAdded$: Subject<ProjectModel> = new Subject();
  starredProjectsRemoved$: Subject<ProjectModel> = new Subject();
  starredProjects$: Subject<ProjectModel[]> = new Subject();

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private helperService: HelperService,
    private http: HttpClient,
  ) {
    this.starredProjects$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((res) => (this.starredProjects = res)),
      )
      .subscribe();
  }

  setActiveProject(project: ProjectModel) {
    this.activeProject$.next(project);
  }

  unsetActiveProject() {
    this.activeProject$.next(null);
  }

  setStarredProject(project: ProjectModel) {
    this.starredProjects.push(project);
    this.starredProjects$.next(this.starredProjects);
    this.starredProjectsAdded$.next(project);
  }

  getProjectsList(dataToParse: TableChangeDataEmittedInterface): Observable<PaginationInterface<ProjectModel>> {
    const parsedData = this.helperService.parseBodyParamsFromTable(dataToParse);
    const filtersChanged: FilterGroupModel[] = this.helperService.parseProductFiltersForApi(parsedData.filterGroups!);
    return this.http
      .post<PaginationResourceInterface<ProjectModel>>(ApiUrls.projectsList, {
        ...parsedData,
        filterGroups: filtersChanged,
      })
      .pipe(
        map(({ data, links, meta }) => ({
          data,
          ...links,
          ...meta,
        })),
      );
  }

  unsetStarredProject(project: ProjectModel) {
    this.helperService.removeElementFromArray(project, this.starredProjects);
    this.starredProjects$.next(this.starredProjects);
    this.starredProjectsRemoved$.next(project);
  }

  getProject(id: number): Observable<ProjectModel> {
    return this.http.get<ResponseV2Interface<ProjectModel>>(ApiUrls.project.prepareUrl({ project: id })).pipe(
      map((response) => response.data),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }

  getProjectLanguage(): Observable<string[]> {
    return this.http.get<ResponseV2Interface<string[]>>(ApiUrls.projectLanguage).pipe(
      map((response) => response.data),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }

  getProjectAdditionalAttributes(): Observable<string[]> {
    const activeProjectId = this.activeProject$.getValue()!.id;
    return this.http.get<ResponseV2Interface<string[]>>(ApiUrls.projectAdditionalAttributes.prepareUrl({ project: activeProjectId })).pipe(
      map((response) => response.data),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }

  getProjectAvailableFieldsToMap(): Observable<ProjectAvailableFieldsToMapModel> {
    const activeProjectId = this.activeProject$.getValue()!.id;
    return this.http
      .get<ResponseV2Interface<ProjectAvailableFieldsToMapModel>>(ApiUrls.projectFeedsOutputFields.prepareUrl({ project: activeProjectId }))
      .pipe(map((response) => response.data));
  }

  getProjectPhrases(startDate: string, endDate: string, filterGroups: FilterGroupModel[]): Observable<null> {
    const activeProjectId = this.activeProject$.getValue()!.id;

    return this.http.post<null>(ApiUrls.projectPhrases.prepareUrl({ project: activeProjectId }), {
      start_date: startDate,
      end_date: endDate,
      filterGroups,
    });
  }

  changeProjectPhrasesStatus(conversion_phrases: boolean): Observable<null> {
    const activeProjectId = this.activeProject$.getValue()!.id;
    return this.http
      .patch<null>(ApiUrls.projectConversionPhrases.prepareUrl({ project: activeProjectId }), {
        conversion_phrases,
      })
      .pipe(tap(() => this.setActiveProject({ ...this.activeProject$.getValue()!, conversion_phrases })));
  }

  getProjects(limit: number, page?: number, name?: string): Observable<PaginationInterface<ProjectModel>> {
    let params = new HttpParams();
    params = name ? params.set('name', name.toString()) : params;
    params = limit ? params.set('per_page', limit.toString()) : params;
    params = page ? params.set('page', page.toString()) : params;

    return this.http
      .get<PaginationResourceInterface<ProjectModel>>(ApiUrls.projects, {
        params,
      })
      .pipe(
        tap((projects) => this.projects$.next(projects.data)),
        catchError((error: HttpErrorResponse) => throwError(error.error)),
      );
  }

  getCurrencies(): Observable<CurrencyData> {
    return this.http.get<ResponseV2Interface<CurrencyData>>(ApiUrls.dictsCurrencies).pipe(map((response) => response.data));
  }

  getAvailableProjectType(): Observable<ProjectTypesEnum> {
    return this.http.get<ResponseV2Interface<{ project_type: ProjectTypesEnum }>>(ApiUrls.projectType).pipe(
      map((response) => response.data),
      map((data) => data.project_type),
    );
  }

  addProject(
    name: string,
    domain: string,
    language: string,
    type: number,
    addToGlobalComparator: boolean,
    preferredCurrency?: string,
  ): Observable<ProjectModel> {
    return this.http
      .post<ResponseV2Interface<ProjectModel>>(ApiUrls.projects, {
        name,
        domain,
        language,
        type,
        preferred_currency: preferredCurrency,
        addToGlobalComparator,
      })
      .pipe(
        map((response) => response.data),
        tap((project) => this.projects$.next([...this.projects$.getValue(), project])),
        tap((project) => this.setActiveProject(project)),
      );
  }

  updateProject(project: ProjectModel): Observable<ProjectModel> {
    return this.http
      .put<ResponseV2Interface<ProjectModel>>(ApiUrls.project.prepareUrl({ project: project.id }), project)
      .pipe(map((response) => response.data));
  }

  deleteProject(project: ProjectModel): Observable<void> {
    return this.http.delete<void>(ApiUrls.project.prepareUrl({ project: project.id })).pipe(tap(() => this.removeProjectFromApp(project)));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private removeProjectFromApp(project: ProjectModel) {
    const projects = this.projects$.getValue();
    const activeProject = this.activeProject$.getValue();

    this.helperService.removeElementFromArray(project, this.starredProjects);
    this.helperService.removeElementFromArray(project, projects);

    this.projects$.next(projects);
    this.starredProjects$.next(this.starredProjects);
    if (project.id === activeProject!.id) {
      this.unsetActiveProject();
    }

    if (!projects || !projects.length) {
      // Refresh i jeśli brak projektów to wtedy wyświetli się tryb restrykcyjny z obowiązkiem stworzenia pierwszego projektu:
      window.location.reload();
    }
  }
}
