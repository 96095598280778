<!--------------------           header Switch                 ---------------------->
<ng-container [ngSwitch]="column.type">
  <ng-container *ngSwitchCase="columnTypes.INNER">
    <div class="inner-header-container">
      <div *ngIf="config.tools.select" class="innerHeader">
        <mat-checkbox
          noopClick
          #checkboxRef="matCheckbox"
          (click)="selectClicked()"
          [checked]="checkedState$ | async"
          [indeterminate]="indeterminateState$ | async"
        >
        </mat-checkbox>
      </div>
      <div class="innerHeader"></div>
      <div class="innerHeader"></div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="header">
      <div (click)="setSorting()" [ngClass]="{ sortable: column.canBeSorted }" class="alias">
        {{ column.alias | titlecase }}
        <ng-container *ngIf="column.canBeSorted">
          <mat-icon *ngIf="!sorting" class="sort-icon init">arrow_upward</mat-icon>
          <mat-icon *ngIf="sorting && sorting.direction === 'asc'" class="sort-icon">arrow_upward</mat-icon>
          <mat-icon *ngIf="sorting && sorting.direction === 'desc'" class="sort-icon">arrow_downward</mat-icon>
        </ng-container>
      </div>

      <ng-container *ngIf="config">
        <span
          *ngIf="index >= config.indexFirstResizingElement"
          [displayedColumns]="(displayedColumns$ | async)!"
          [index]="index"
          [tableCells]="tableCells"
          appResizeMaterialTable
          class="resize-element"
        ></span>
        <mat-icon (click)="groupBy($event, column.param)" *ngIf="config.tools.grouping && column.grouping" class="group-icon-on-top">
          link
        </mat-icon>
      </ng-container>

      <mat-icon *ngIf="column.tooltip" [matTooltip]="column.tooltip | translate" class="group-icon-on-top" matTooltipClass="label-tooltip"
        >help
      </mat-icon>
    </div>
  </ng-container>
</ng-container>
