<div [ngClass]="{ active: (currentFilters$ | async)!.filterGroups![0] }" class="container filter-display">
  <sem-filter-groups-displayer
    [config]="configService.config"
    [cursorDefault]="true"
    [filters]="(currentFilters$ | async)!"
    [numberOfLetters]="35"
    class="filterDisplayComponent"
  ></sem-filter-groups-displayer>

  <div class="btns icon-button-density-minus-5">
    <button (click)="openFilterPopup()" mat-icon-button>
      <mat-icon>edit</mat-icon>
    </button>
    <button (click)="clear()" *ngIf="(currentFilters$ | async)!.filterGroups![0]" mat-icon-button>
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
