<div class="container">
  <div class="main-row">
    <button (click)="setToAddState()" [color]="!deleteCtrl.value ? 'primary' : ''" mat-raised-button>
      {{ 'sem_table.add_param ' | translate }}
    </button>
    <div class="or">
      {{ 'sem_table.or' | translate }}
    </div>
    <button (click)="setToRemoveState()" [color]="deleteCtrl.value ? 'warn' : ''" mat-raised-button>
      {{ 'sem_table.remove_param' | translate }}
    </button>
  </div>

  <div class="form">
    <ng-container [ngTemplateOutlet]="!deleteCtrl.value ? add : remove"></ng-container>
  </div>
</div>

<ng-template #add>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'sem_table.param_name' | translate }}</mat-label>
    <input [formControl]="paramCtrl" matInput name="param" />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
    <input [formControl]="valueCtrl" matInput name="value" />
  </mat-form-field>

  <mat-checkbox [formControl]="skipCtrl" name="skip">{{ 'sem_table.skip_existing_param' | translate }}</mat-checkbox>
</ng-template>

<ng-template #remove>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'sem_table.param_name' | translate }}</mat-label>
    <input [formControl]="paramCtrl" matInput name="param" />
  </mat-form-field>
</ng-template>
