<ng-container *ngTemplateOutlet="editState ? editable : notEditable"></ng-container>

<ng-template #editable>
  <mat-form-field class="{{ column.param }}-column-display">
    <mat-select [value]="actualValue" #reference [disabled]="disabled">
      <mat-option *ngFor="let option of possibleValues" [value]="option">
        {{ option | mapColumnValue: column }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #notEditable>
  <span class="value" [ngClass]="{ 'not-editable': displayNotEditable }" #input>{{ actualValue | mapColumnValue: column }}</span>
</ng-template>
