import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material-module/material.module';
import { SemTableSharedModule } from '../sem-table-shared/sem-table-shared.module';
import { CaseSizeConfigComponent } from './filter-form-switch/case-size-config/case-size-config.component';
import { ContainsComponent } from './filter-form-switch/contains/contains.component';
import { FieldNoIncludeFieldComponent } from './filter-form-switch/field-no-include-field/field-no-include-field.component';
import { FilterFormSwitchComponent } from './filter-form-switch/filter-form-switch.component';
import { FilterGroupsDisplayerComponent } from './filter-groups-displayer/filter-groups-displayer.component';
import { FilterOperatorComponent } from './filter-operator/filter-operator.component';
import { FilterGroupFormComponent } from './modals/filters-manager-popup/filter-group-form/filter-group-form.component';
import { SingleFilterFormComponent } from './modals/filters-manager-popup/filter-group-form/single-filter-form/single-filter-form.component';
import { FiltersManagerPopupComponent } from './modals/filters-manager-popup/filters-manager-popup.component';
import { FilterTypeAliasPipe } from './modals/pipes/filter-type-alias.pipe';
import { QuickFilterWithSelectComponent } from './quick-filters/quick-filter-with-select/quick-filter-with-select.component';
import { QuickFiltersComponent } from './quick-filters/quick-filters.component';
import { SearchToolComponent } from './quick-filters/search-tool/search-tool.component';
import { TableDisplayModule } from '../table-display/table-display.module';
import { UserIdsToEmailsPipe } from '../../pipes/user-ids-to-emails.pipe';

@NgModule({
  declarations: [
    ContainsComponent,
    FilterTypeAliasPipe,
    FiltersManagerPopupComponent,
    FieldNoIncludeFieldComponent,
    FilterGroupsDisplayerComponent,
    FilterFormSwitchComponent,
    CaseSizeConfigComponent,
    QuickFiltersComponent,
    SearchToolComponent,
    QuickFilterWithSelectComponent,
    FilterGroupFormComponent,
    SingleFilterFormComponent,
    FilterOperatorComponent,
  ],
  imports: [
    MaterialModule,
    TableDisplayModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    SemTableSharedModule,
    TranslateModule,
    UserIdsToEmailsPipe,
  ],
  exports: [FilterGroupsDisplayerComponent, QuickFiltersComponent],
})
export class FiltersModule {}
