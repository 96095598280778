import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { InfoDialogComponent } from '../components/info-dialog/info-dialog.component';
import { ConnectionsService } from './connections.service';
import { LoaderService } from './loader.service';
import { LocalStorageService, storageKey, WelcomeDialogStorageEnum } from './local-storage.service';

export enum RestricteModeEnum {
  connections = 'no-connections',
  email = 'unverified-email',
  fault = 'fault',
  payment = 'payment-required',
  project = 'no-project-selected',
  projectConnections = 'no-project-connections',
  welcome = 'welcome-screen',
}

@Injectable({
  providedIn: 'root',
})
export class RestrictedModeService implements OnDestroy {
  isSubscriptionActive = new BehaviorSubject<boolean>(true);
  restrictedMode$ = new BehaviorSubject<RestricteModeEnum | null>(null);
  restrictedMode: RestricteModeEnum | null = null;
  private onDestroy$ = new Subject<void>();

  constructor(
    private loaderService: LoaderService,
    private storageHelperService: LocalStorageService,
    public connectionsService: ConnectionsService,
    public dialog: MatDialog,
    public projectService: ProjectService,
  ) {
    this.restrictedMode$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((restrictedMode) => (this.restrictedMode = restrictedMode)),
        tap((restrictedMode) => (!restrictedMode || restrictedMode === RestricteModeEnum.welcome) && this.isSubscriptionActive.next(false)),
      )
      .subscribe();
  }

  get isRestrictedMode(): boolean {
    return this.restrictedMode ? true : false;
  }

  turnOffRestrictiveMode() {
    this.restrictedMode$.next(null);
    this.loaderService.off();

    const welcomeDialog = this.storageHelperService.get(storageKey.welcomeDialog);

    if (welcomeDialog) {
      this.showWelcomeDialog(welcomeDialog);
    }
  }

  setConnectionsMode() {
    this.restrictedMode$.next(RestricteModeEnum.connections);
    this.loaderService.off();
  }

  setEmailMode() {
    this.restrictedMode$.next(RestricteModeEnum.email);
    this.loaderService.off();
  }

  setFaultMode() {
    this.restrictedMode$.next(RestricteModeEnum.fault);
    this.loaderService.off();
  }

  setPaymentMode() {
    this.restrictedMode$.next(RestricteModeEnum.payment);
    this.loaderService.off();
  }

  setProjectConnectionsMode() {
    this.restrictedMode$.next(RestricteModeEnum.projectConnections);
    this.loaderService.off();
  }

  setProjectMode() {
    this.restrictedMode$.next(RestricteModeEnum.project);
    this.loaderService.off();
  }

  setWelcomeScreenMode() {
    this.restrictedMode$.next(RestricteModeEnum.welcome);
    this.loaderService.off();
  }

  final(type: string) {
    // @TODO: enum
    switch (
      type // Indywidualne (per typ konta) ścieżki końcowe:
    ) {
      case 'sso': {
        // Chat GPT user
        const activeProjectId = this.projectService.activeProject$.getValue()?.id || null;

        if (activeProjectId) {
          // Dla usera z chata GPT spradzamy czy dla aktualnego projektu ma podpiętych chociaz 1 connection:
          this.connectionsService
            .getProjectConnections(activeProjectId)
            .pipe(map((res) => res.data || null))
            .subscribe(
              (connections) => {
                if (!connections || !connections.length) {
                  this.setProjectConnectionsMode();
                } else {
                  this.turnOffRestrictiveMode();
                }
              },
              () => this.setProjectConnectionsMode(),
            );
        } else {
          // @TODO: jak obsłużyć taki przypadek?
          this.turnOffRestrictiveMode();
        }

        break;
      }
      default:
        this.turnOffRestrictiveMode();
        break;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  // setCustomMode(mode: RestricteModeEnum) {
  //   const restricteMode = Object.entries(RestricteModeEnum).find(([key, val]) => val === mode)?.[0];

  //   if (restricteMode) {
  //     switch (mode) {
  //       case RestricteModeEnum.projectConnections:
  //         break;
  //       default:
  //         this.loaderService.off();
  //         break;
  //     }
  //   } else {
  //     this.loaderService.off();
  //   }
  // }

  private showWelcomeDialog(welcomeDialog: string) {
    let data = null;

    switch (welcomeDialog) {
      case WelcomeDialogStorageEnum.connections: // @TODO: case już prawdopdoobnie nieaktualny
        data = {
          buttons: [{ key: 'welcome_dialog.connections.btn_txts.create_connection' }],
          descriptionKey: 'welcome_dialog.connections.description',
          titleKey: 'welcome_dialog.connections.title',
        };
        break;

      case WelcomeDialogStorageEnum.chatGpt:
        data = {
          buttons: [
            { key: 'welcome_dialog.chat_gpt.btn_txts.explore_sembot' },
            {
              key: 'welcome_dialog.chat_gpt.btn_txts.visit_prompts_base',
              route: 'https://sembot.com/sembot-chat-gpt-plug-in/#prompts',
            },
            { key: 'welcome_dialog.chat_gpt.btn_txts.go_to_chat', route: 'https://chat.openai.com/auth/login' },
          ],
          descriptionKey: 'welcome_dialog.chat_gpt.description',
          titleKey: 'welcome_dialog.chat_gpt.title',
        };
        break;
    }

    if (data) {
      this.storageHelperService.remove(storageKey.welcomeDialog);

      this.dialog
        .open(InfoDialogComponent, {
          data,
          maxWidth: '600px',
          panelClass: 'no-dialog-spinner',
        })
        .afterClosed()
        .subscribe(() => this.storageHelperService.remove(storageKey.welcomeDialog));
    }
  }
}
