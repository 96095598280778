<app-dialog [dialogRef]="dialogRef">
  <div class="title-container">
    <div class="title">
      {{ 'sem_table.manage_filters' | translate }}
    </div>

    <div class="filters-header-wrapper">
      <button
        #recentFilterTrigger="matMenuTrigger"
        (click)="recentFilterTrigger.openMenu()"
        [matMenuTriggerFor]="recentFilterMenu"
        mat-button
      >
        <mat-icon class="filters-icon">history</mat-icon>
        {{ 'sem_table.recent_filter_group' | translate | titlecase }}
      </button>
      <button *ngIf="(data.filterService.filtersInStorage$ | async)!.length !== 0" [matMenuTriggerFor]="storageMenu" mat-button>
        <mat-icon>download</mat-icon>
        {{ 'sem_table.memory' | translate | titlecase }}
      </button>
    </div>
  </div>
</app-dialog>

<mat-dialog-content class="container">
  <div class="preview-container">
    <div class="label">{{ 'sem_table.preview' | translate }}</div>
    <sem-filter-groups-displayer [config]="config" [cursorDefault]="true" [filters]="actualFilters"></sem-filter-groups-displayer>
  </div>
  <sem-filter-group-form
    (removed)="catchGroupRemove(index)"
    *ngFor="let group of groupsFormArray.controls; let index = index"
    [autocompleteService]="data.autocompleteService"
    [columns]="columns"
    [filterFactoryService]="data.filterFactoryService"
    [groupForm]="getGroupFormGroup(index)"
    [index]="index"
  >
  </sem-filter-group-form>
  @if (config.tools.filterManualChanges) {
    <mat-checkbox class="tw-mt-4" [formControl]="filterManualChangesControl">{{ 'sem_table.has_manual_changes' | translate }}</mat-checkbox>
  }
</mat-dialog-content>

<!------------          actions           ------------------->
<mat-dialog-actions>
  <!------------          add new filter group           ------------------->
  <button (click)="addGroup()" *ngIf="config.tools.filterAdvancedGroup" class="newGroupBtn" color="primary" mat-raised-button>
    <!--
    TODO: back it to *ngIf
    &&
  activeFilterGroups[0] &&
  activeFilterGroups[activeFilterGroups.length - 1].filters[0] -->

    <mat-icon>add</mat-icon>
    {{ 'sem_table.new_filter_group' | translate | titlecase }}
  </button>
  <button (click)="onApproveClick()" class="submit-filters-btn" color="primary" mat-raised-button>
    {{ 'sem_table.approve' | translate | titlecase }}
  </button>

  <button (click)="openSaveAs()" *ngIf="actualFilters.filterGroups[0]" mat-raised-button>
    <mat-icon>save</mat-icon>
    {{ 'sem_table.save_as' | translate | titlecase }}
  </button>
  <button (click)="close()" mat-raised-button>{{ 'sem_table.cancel' | translate | titlecase }}</button>
</mat-dialog-actions>

<!------------          menu for recent filters           ------------------->
<mat-menu #recentFilterMenu="matMenu" [hasBackdrop]="false" xPosition="after">
  <ng-template matMenuContent>
    <div (mouseleave)="recentFilterTrigger.closeMenu()">
      <button
        (click)="setFilter(recentFilter)"
        *ngFor="let recentFilter of data.filterService.recentFiltersInStorage$ | async"
        mat-menu-item
      >
        <sem-filter-groups-displayer
          [config]="config"
          [filters]="recentFilter.filters"
          [numberOfLetters]="10"
        ></sem-filter-groups-displayer>
      </button>
    </div>
    <div *ngIf="(data.filterService.recentFiltersInStorage$ | async)!.length === 0" style="padding: 13px">
      {{ 'sem_table.recent_filter_group_empty' | translate | titlecase }}
    </div>
  </ng-template>
</mat-menu>
<!------------          menus for storage           ------------------->
<mat-menu #storageMenu="matMenu" class="storageMenu" xPosition="after">
  <button
    (click)="setFilter(filter)"
    *ngFor="let filter of data.filterService.filtersInStorage$ | async"
    class="storage-btn-container"
    mat-menu-item
  >
    <mat-icon (click)="deleteFiltersFromStorage(filter, $event)" class="remove-storage-btn">delete</mat-icon>
    {{ filter.name | truncate: 15 }}
  </button>
</mat-menu>
