<form (click)="click($event)" [cdkTrapFocusAutoCapture]="true" [formGroup]="searchForm" cdkTrapFocus>
  <mat-form-field class="searchColumnField">
    <input
      #input
      [placeholder]="'sem_table.column_name' | translate"
      autocomplete="off"
      cdkFocusInitial
      formControlName="search"
      matInput
    />
  </mat-form-field>
</form>

<ng-content></ng-content>

<div>
  @for (group of config.columnGroups; track group) {
    <div>
      @let columnNames = group.getColumnsFromAllColumn(columnsModels);
      @if (columnNames) {
        @if (columnNames[0]) {
          <div class="group-name">
            {{ group.alias }}
          </div>
        }
        <button
          (click)="clickOnColumn(column, $event)"
          (mouseenter)="columnHovered.emit()"
          *ngFor="let column of columnNames"
          [ngClass]="column.param + '-column-btn-' + column.group"
          class="display-column-in-choose-list"
          mat-menu-item
        >
          <mat-icon>add</mat-icon>
          {{ column.alias | titlecase }}
        </button>
      }
    </div>
  }
</div>
