<div class="values-container">
  <mat-form-field #trigger="matMenuTrigger" (click)="input.blur()" [matMenuTriggerFor]="propertyEditMenu" appearance="outline">
    <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
    <input
      #input
      (focus)="input.blur()"
      autocomplete="off"
      matInput
      name="value"
      placeholder="{{ 'sem_table.value' | translate }}"
      required
      tabindex="-1"
    />
  </mat-form-field>

  <mat-menu #propertyEditMenu="matMenu" xPosition="after" yPosition="above">
    <ng-template matMenuContent>
      <sem-value-chooser
        (click)="$event.stopPropagation(); $event.preventDefault()"
        (valueEmitted)="catchValue($event)"
        [config]="config"
        cdkFocusInitial
      ></sem-value-chooser>
    </ng-template>
  </mat-menu>
</div>
