import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MonitorOrganicTaskModel, MonitorTooltipData } from '../../model/monitor.model';
import moment from 'moment';
import { EditableColumnsComponentInterface } from '../../sem-table/table-display/columns-switch/columns/editable-column.component';
import { MatDialog } from '@angular/material/dialog';
import { TaskResultsPopupComponent } from 'src/app/dashboard/project/monitor/task-results-popup/task-results-popup.component';
import { filter } from 'rxjs';

@Component({
  selector: 'app-monitor-pill',
  templateUrl: './monitor-pill.component.html',
  styleUrls: ['./monitor-pill.component.scss'],
})
export class MonitorPillComponent implements EditableColumnsComponentInterface {
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  edit = false;

  private defaultTranslateKey: any;
  private _translateKey!: string;
  private dialog = inject(MatDialog);

  @Input() tasks!: MonitorOrganicTaskModel[];
  @Input() set translateKey(key: string) {
    this._translateKey = key;
  }

  get translateKey(): string {
    return this._translateKey || this.defaultTranslateKey;
  }

  enterEditableMode(): void {
    this.edit = true;
  }

  exitEditableState(): void {
    this.edit = false;
  }

  click(task: MonitorOrganicTaskModel) {
    this.dialog
      .open(TaskResultsPopupComponent, { data: { task: task, type: task.type } })
      .afterClosed()
      .pipe(filter((isSuccess) => !!isSuccess));
  }

  getTooltipData(task: MonitorOrganicTaskModel): MonitorTooltipData {
    return {
      position: task.rank_group ?? '-',
      change_1: task.change_1 ?? '-',
      change_7: task.change_7 ?? '-',
      change_30: task.change_30 ?? '-',

      updated_at: task.updated ? moment(task.updated).format('YYYY-MM-DD HH:mm') : '-',
      status: task.status ?? '-',
      language: task.language ?? '-',
      location: task.location ?? '-',
    };
  }
}
