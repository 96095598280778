import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { ColumnDirectivesEnum } from '../ColumnTypesEnum';
@Directive({
  selector: '[semColumn]',
})
export class ColumnDirective implements OnChanges {
  @Input() isFullContent!: boolean;
  @Input() semColumn!: ColumnDirectivesEnum;
  @Input() value!: string;

  constructor(private elRef: ElementRef<HTMLInputElement>) {}

  ngOnChanges(): void {
    const value = this.value.trim();

    if (this.semColumn && value) {
      this.init(this.semColumn, value, this.isFullContent);
    } else if (this.semColumn && !value) {
      this.elRef.nativeElement.innerHTML = '';
    }
  }

  private init(directive: ColumnDirectivesEnum, value: string, isFullContent: boolean = false) {
    switch (directive) {
      case ColumnDirectivesEnum.link: {
        const innerHTML = isFullContent ? this.elRef.nativeElement.innerHTML.trim() : value;
        this.elRef.nativeElement.innerHTML = `<a href="${value}" target="_blank" class="column-link">${innerHTML}</a>`;
        break;
      }
      default:
        break;
    }
  }
}
