import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, QueryList, ViewChildren, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, delay, map, takeUntil, tap } from 'rxjs/operators';
import { ItemPopupDataInterface } from '../../models/modalsData.model';
import { ColumnTypesEnum, ColumnValueTypesEnum } from '../../table-display/ColumnTypesEnum';
import { AutocompleteColumn } from '../../table-display/columns-switch/columns/autocomplete-column/AutocompleteColumn';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';
import { SelectColumn } from '../../table-display/columns-switch/columns/select-column/SelectColumn';
import { SimpleColumn } from '../../table-display/columns-switch/columns/simple-column/SimpleColumn';

@Component({
  selector: 'sem-product-popup',
  templateUrl: './item-popup.component.html',
  styleUrls: ['./item-popup.component.scss'],
})
export class ItemPopupComponent implements OnInit, OnDestroy {
  @ViewChildren('inputEl', { read: ElementRef }) inputReference!: QueryList<ElementRef>;
  searchForm = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    require: new UntypedFormControl(false),
  });

  columnTypes = ColumnTypesEnum;
  columnValueTypes = ColumnValueTypesEnum;
  form!: UntypedFormGroup;
  columnsToDisplay$ = new BehaviorSubject<string[]>([]);
  private onDestroy$: Subject<void> = new Subject();
  private translateService = inject(TranslateService);

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ItemPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemPopupDataInterface,
  ) {}

  get searchCtrl() {
    return this.searchForm.get('search') as UntypedFormControl;
  }

  get requireCtrl() {
    return this.searchForm.get('require') as UntypedFormControl;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      const currentIndex = (event as any).target.dataset.id;
      this.next(currentIndex);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  ngOnInit() {
    this.initForm();
    this.autocompleteStart();
    this.columnsToDisplay$.next(this.data.columnNames);

    this.searchForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => this.searchColumns(value.search, value.require));

    if (this.data.waitForApiResponse) {
      this.data.communicationService.apiSuccess$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.dialogRef.close());

      this.data.communicationService.apiError$
        .pipe(
          takeUntil(this.onDestroy$),
          tap((response) => response.setOnForm(this.form)),
          delay(100),
        )
        .subscribe(() => this.scrollToError());
    }
  }

  searchColumns(name: string, required: boolean) {
    const search = name.toLowerCase();
    const columns: string[] = this.data.columnNames.filter((columnName) => {
      const column = this.data.config.columns[columnName];
      return column.alias.indexOf(search) !== -1 && (required ? column.required : true);
    });
    this.columnsToDisplay$.next(columns);
  }

  displayFn(value: any): string {
    const column = this as unknown as AutocompleteColumn;
    return column.map(value);
  }

  catchChange(value: any, columnName: string): void {
    this.form.get(columnName)!.setValue(value);
  }

  onSubmit() {
    if (this.form.invalid) {
      const message = this.translateService.instant('sem_table.error_in_the_form');
      this.data.communicationService.customMessage$.next({ type: 'error', message, case: 'DEFAULT' });
      this.form.markAllAsTouched();
      this.scrollToError();

      return;
    }
    const { value } = this.form;
    Object.keys(value).forEach((key) => {
      if (value[key] === 'null') {
        value[key] = null;
      }
    });

    const item = { ...this.form.value, id: this.data.item.id };
    this.data.itemToEmit$.next(item);
    if (!this.data.waitForApiResponse) {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private next(currentIndex: number) {
    let nextIndex: number = -1;
    this.inputReference.find((ref, index) => {
      if (+ref.nativeElement.dataset.id === +currentIndex) {
        nextIndex = index + 1;
        return true;
      }
      return false;
    });
    const references = this.inputReference.toArray();
    const nextRef = references[nextIndex];
    if (nextRef) {
      nextRef.nativeElement.focus();
    }
  }

  private initForm() {
    this.form = this.fb.group({});
    this.data.columnNames.forEach((columnName) => {
      this.form.addControl(columnName, new UntypedFormControl(this.data.item[columnName as keyof typeof this.data.item]));
      const column = this.data.config.columns[columnName];
      this.addValidationsToForm(column);

      if (
        column.type === ColumnTypesEnum.SELECT &&
        !this.data.item[columnName as keyof typeof this.data.item] &&
        (column as SelectColumn).defaultValue
      ) {
        this.form.get(columnName)!.setValue((column as SelectColumn).defaultValue);
      }
    });
  }

  private scrollToError(): void {
    const form = document.querySelector('.form');

    const firstElementWithError = form!.querySelector('.ng-invalid');
    firstElementWithError && firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    form!.scrollTop -= 10;
  }

  private addValidationsToForm(column: ColumnModel) {
    if (column.type === ColumnTypesEnum.SIMPLE && (column as SimpleColumn).lettersLimit) {
      this.form.get(column.param)!.setValidators(Validators.maxLength((column as SimpleColumn).lettersLimit));
    }
  }

  private autocompleteStart() {
    this.data.config.returnColumnsWithType(ColumnTypesEnum.AUTOCOMPLETE).forEach((param) => {
      if (!this.form.get(param)) {
        return;
      }

      this.form
        .get(param)!
        .valueChanges.pipe(
          takeUntil(this.onDestroy$),
          debounceTime(500),
          map((value) => (typeof value === 'object' ? this.data.config.columns[param].map(value) : value)),
        )
        .subscribe((valueChange: string) =>
          this.data.autocompleteService.initAutocompleteChange$.next({
            columnName: param,
            value: valueChange,
          }),
        );
    });
  }
}
