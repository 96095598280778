import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModifyLinkAction, ModifyLinkActionPayload } from '../../models/ModifyLinkAction';

@Component({
  selector: 'sem-link-builder',
  templateUrl: './link-builder.component.html',
  styleUrls: ['./link-builder.component.scss'],
})
export class LinkBuilderComponent implements OnInit, OnDestroy {
  form!: UntypedFormGroup;
  @Input() action!: ModifyLinkAction;
  @Output() valueChanged = new EventEmitter<ModifyLinkActionPayload>();
  private onDestroy$ = new Subject<void>();

  get paramCtrl() {
    return this.form.get('name') as UntypedFormControl;
  }

  get valueCtrl() {
    return this.form.get('value') as UntypedFormControl;
  }

  get skipCtrl() {
    return this.form.get('skip_existing') as UntypedFormControl;
  }

  get deleteCtrl() {
    return this.form.get('delete') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.action.value.name, Validators.required),
      value: new UntypedFormControl(this.action.value.value, Validators.required),
      skip_existing: new UntypedFormControl(this.action.value.skip_existing),
      delete: new UntypedFormControl(this.action.value.delete),
    });

    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => this.valueChanged.emit(value));
  }

  setToAddState() {
    this.deleteCtrl.setValue(false);
  }

  setToRemoveState() {
    this.deleteCtrl.setValue(true);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
