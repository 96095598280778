import { Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, QueryList, Renderer2 } from '@angular/core';

const COLUMN_MIN_WIDTH_PX = 45;

@Directive({
  selector: '[appResizeMaterialTable]',
})
export class ResizeMaterialTableDirective implements OnChanges, OnDestroy {
  @Input() displayedColumns: string[] = [];
  @Input() index!: number;
  @Input() tableCells!: QueryList<ElementRef<HTMLTableCellElement>>;
  resizeInAction!: boolean;
  resizeMouseMoveListener!: () => void;
  resizeMouseUpListener!: () => void;

  @HostListener('mousedown', ['$event']) mouseDown(event: MouseEvent) {
    event.preventDefault();
    this.resizeInAction = true;

    const startingPosition = event.pageX;

    this.mouseMove(startingPosition, this.getRelatedColumns());
  }

  constructor(private renderer: Renderer2) {}

  ngOnChanges(): void {
    const columnLeft = this.getRelatedColumns();
    if (columnLeft.length) {
      const leftColumnWidth = columnLeft[0].clientWidth;
      this.resizeAllRelatedElements(columnLeft, leftColumnWidth);
    }
  }

  mouseMove(startingPosition: number, columnLeft: HTMLTableCellElement[]) {
    const leftColumnWidth = columnLeft[0].clientWidth;
    this.resizeMouseMoveListener = this.renderer.listen('document', 'mousemove', (event) => {
      if (event.buttons === 1) {
        const dx = event.pageX - startingPosition;
        const newLeftWidth = leftColumnWidth + dx;
        const ableToResize = newLeftWidth > COLUMN_MIN_WIDTH_PX;

        if (!ableToResize) {
          return;
        }
        this.resizeAllRelatedElements(columnLeft, newLeftWidth);
      }
    });

    this.resizeMouseUpListener = this.renderer.listen('document', 'mouseup', () => {
      if (this.resizeInAction) {
        this.resizeInAction = false;
        this.resizeMouseMoveListener();
        this.resizeMouseUpListener();
      }
    });
  }

  private getRelatedColumns() {
    const resizableColumnClassName = `${this.displayedColumns[this.index - 1]}Cell`;

    const cells = this.tableCells?.toArray() ?? [];
    return cells.filter((x) => x.nativeElement.classList.contains(resizableColumnClassName)).map((x) => x.nativeElement);
  }

  private resizeAllRelatedElements(elements: HTMLTableCellElement[], size: number) {
    for (const element of elements) {
      this.setSizeOnElement(element, size);
    }
  }

  private setSizeOnElement(element: Element, size: number) {
    this.renderer.setStyle(element, 'max-width', `${size}px`);
    this.renderer.setStyle(element.firstChild, 'max-width', `${size}px`);
    this.renderer.setStyle(element, 'width', `${size}px`);
    this.renderer.setStyle(element.firstChild, 'width', `${size}px`);
  }

  ngOnDestroy() {
    if (this.resizeMouseMoveListener) {
      this.resizeMouseMoveListener();
    }
    if (this.resizeMouseUpListener) {
      this.resizeMouseUpListener();
    }
    const columns = this.getRelatedColumns();
    if (columns.length) {
      this.renderer.removeStyle(columns[0], 'max-width');
      this.renderer.removeStyle(columns[0], 'width');
    }
  }
}
