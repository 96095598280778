import { Directive, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
  selector: '[trimSpaces]',
  standalone: true,
})
export class TrimSpacesDirective {
  private el = inject(ElementRef);

  @HostListener('blur')
  onBlur(): void {
    const inputValue = this.el.nativeElement.value;
    this.el.nativeElement.value = inputValue?.trim();
    this.el.nativeElement.dispatchEvent(new Event('input'));
  }
}
