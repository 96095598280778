<div *ngIf="page$ | async as page" class="container">
  <div *ngIf="!isItemsPerPageHidden" class="items-per-page">
    <div class="label">{{ 'sem_table.page_items_per_page' | translate }}</div>

    <mat-form-field class="pagination-select">
      <mat-select (selectionChange)="changePageSize($event)" [value]="page.itemsPerPage">
        <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="total">{{ 'sem_table.page_of' | translate }} {{ page.totalItemsCount }}</div>
  </div>

  <div *ngIf="page.pagesCount && page.pagesCount > 1" class="pagination-container">
    <div class="pagination-container-inner">
      <div class="btns-container">
        <button (click)="goToFirst()" [disabled]="page.currentPageIndex === 1" class="button-icon" mat-button>
          <mat-icon>first_page</mat-icon>
        </button>
        <button (click)="goBack()" [disabled]="page.currentPageIndex === 1" mat-stroked-button>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      </div>
      <div class="pages-display">
        <button (click)="goToPage(page.currentPageIndex - 2)" *ngIf="page.currentPageIndex - 2 > 0" mat-flat-button>
          {{ page.currentPageIndex - 2 }}
        </button>
        <button (click)="goToPage(page.currentPageIndex - 1)" *ngIf="page.currentPageIndex - 1 > 0" mat-flat-button>
          {{ page.currentPageIndex - 1 }}
        </button>
        <button (click)="goToPage(page.currentPageIndex)" color="primary" mat-flat-button>{{ page.currentPageIndex }}</button>
        <button (click)="goToPage(page.currentPageIndex + 1)" *ngIf="page.pagesCount >= page.currentPageIndex + 1" mat-flat-button>
          {{ page.currentPageIndex + 1 }}
        </button>
        <button (click)="goToPage(page.currentPageIndex + 2)" *ngIf="page.pagesCount >= page.currentPageIndex + 2" mat-flat-button>
          {{ page.currentPageIndex + 2 }}
        </button>
        <button
          (click)="goToPage(page.currentPageIndex + 3)"
          *ngIf="page.pagesCount >= page.currentPageIndex + 3 && 0 >= page.currentPageIndex - 2"
          mat-flat-button
        >
          {{ page.currentPageIndex + 3 }}
        </button>
        <button
          (click)="goToPage(page.currentPageIndex + 4)"
          *ngIf="page.pagesCount >= page.currentPageIndex + 4 && 0 >= page.currentPageIndex - 1"
          mat-flat-button
        >
          {{ page.currentPageIndex + 4 }}
        </button>
        <ng-container *ngIf="page.pagesCount >= page.currentPageIndex + 3">
          <div class="placeholder">...</div>
          <button (click)="goToPage(page.pagesCount)" mat-flat-button>{{ page.pagesCount }}</button>
        </ng-container>
      </div>

      <div class="btns-container">
        <button (click)="goNext()" [disabled]="page.currentPageIndex === page.pagesCount" mat-stroked-button>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button (click)="goToLast()" [disabled]="page.currentPageIndex === page.pagesCount" class="button-icon" mat-button>
          <mat-icon>last_page</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="!isGoToPageHidden" class="go-to-page">
      <div class="label">{{ 'sem_table.go_to_page' | translate }}:</div>
      <mat-form-field appearance="outline">
        <input [appOnlyNumber]="true" [formControl]="goToPageCtrl" matInput />
      </mat-form-field>
      <button (click)="goToPage()" mat-stroked-button>{{ 'sem_table.go' | translate }}</button>
    </div>
  </div>
</div>
