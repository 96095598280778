import { Component } from '@angular/core';
import { FilterService } from '../../filters/filters.service';
import { CustomButtonInterface } from '../../models/CustomButton.model';
import { CommunicationService } from '../../services/communication.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'sem-custom-buttons',
  templateUrl: './custom-buttons.component.html',
  styleUrls: ['./custom-buttons.component.scss'],
})
export class CustomButtonsComponent {
  mainToolbarButtons: CustomButtonInterface[] = this.configService?.config?.mainToolbarSlot || [];

  constructor(
    private configService: ConfigService,
    private communicationService: CommunicationService,
    private filterService: FilterService,
  ) {}

  customButtonClicked(customButton: CustomButtonInterface) {
    if (!this.filterService.checkIfFiltersAreAllowedInMassOperations()) {
      this.communicationService.customMessage$.next({ type: 'error', case: 'DISABLED_IN_MASS_OPERATIONS' });
      return;
    }
    this.communicationService.customButtonClicked$.next({
      type: customButton.actionType,
      data: null,
    });
  }
}
