import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionTypesEnum } from '../../ActionTypesEnum';

@Pipe({
  name: 'actionTypeTooltip',
})
export class ActionTypeTooltipPipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(type: ActionTypesEnum): string {
    switch (type) {
      case ActionTypesEnum.override:
        return this.translateService.instant('sem_table.actions_list.override_tooltip');
      case ActionTypesEnum.generateValue:
        return this.translateService.instant('sem_table.actions_list.generate_value_tooltip');
      case ActionTypesEnum.caseChange:
        return this.translateService.instant('sem_table.actions_list.case_change_tooltip');
      case ActionTypesEnum.add:
        return this.translateService.instant('sem_table.actions_list.add_tooltip');
      case ActionTypesEnum.divide:
        return this.translateService.instant('sem_table.actions_list.divide_tooltip');
      case ActionTypesEnum.multiply:
        return this.translateService.instant('sem_table.actions_list.multiply_tooltip');
      case ActionTypesEnum.subtract:
        return this.translateService.instant('sem_table.actions_list.subtract_tooltip');

      case ActionTypesEnum.capitalized:
        return this.translateService.instant('sem_table.actions_list.capitalized_tooltip');
      case ActionTypesEnum.lower:
        return this.translateService.instant('sem_table.actions_list.lower_tooltip');
      case ActionTypesEnum.upper:
        return this.translateService.instant('sem_table.actions_list.upper_tooltip');

      case ActionTypesEnum.addEnd:
        return this.translateService.instant('sem_table.actions_list.add_end_tooltip');
      case ActionTypesEnum.addStart:
        return this.translateService.instant('sem_table.actions_list.add_start_tooltip');
      case ActionTypesEnum.replace:
        return this.translateService.instant('sem_table.actions_list.replace_tooltip');
      case ActionTypesEnum.regexpReplace:
        return this.translateService.instant('sem_table.actions_list.regexp_replace_tooltip');
      case ActionTypesEnum.setEmpty:
        return this.translateService.instant('sem_table.actions_list.set_empty_tooltip');
      case ActionTypesEnum.multiSearch:
        return this.translateService.instant('sem_table.actions_list.mult_search');

      case ActionTypesEnum.modifyLink:
        return this.translateService.instant('sem_table.actions_list.modify_link_tooltip');

      case ActionTypesEnum.fixUppercase:
        return this.translateService.instant('sem_table.actions_list.fix_uppercase_tooltip');

      case ActionTypesEnum.addFoundFromField:
        return this.translateService.instant('sem_table.actions_list.add_found_from_field_tooltip');

      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const exhaustCheck: never = type;
        return null!;
      }
    }
  }
}
