import { Injectable } from '@angular/core';
import moment from 'moment';

export enum DateRangeEnum {
  OneWeek = '1w',
  OneMonth = '1m',
  ThreeMonths = '3m',
  SixMonths = '6m',
}

@Injectable({
  providedIn: 'root',
})
export class DateRangeService {
  calculateSortedRangeEnum(rangeEnum: typeof DateRangeEnum): { key: string; value: DateRangeEnum }[] {
    const ranges = Object.entries(rangeEnum).map(([key, value]) => ({ key, value }));
    return ranges.sort((a, b) => {
      if (a.value === DateRangeEnum.ThreeMonths) return 1;
      if (b.value === DateRangeEnum.ThreeMonths) return -1;
      return 0;
    });
  }

  getDateRange(range: DateRangeEnum): { minDate?: number; maxDate: number } {
    const maxDate = moment().valueOf();
    let minDate: number | undefined;

    switch (range) {
      case DateRangeEnum.OneWeek:
        minDate = moment().subtract(1, 'weeks').valueOf();
        break;
      case DateRangeEnum.OneMonth:
        minDate = moment().subtract(1, 'months').valueOf();
        break;
      case DateRangeEnum.ThreeMonths:
        minDate = moment().subtract(3, 'months').valueOf();
        break;
      case DateRangeEnum.SixMonths:
        minDate = moment().subtract(6, 'months').valueOf();
        break;
    }

    return { minDate, maxDate };
  }
}
