import { Component, inject } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { MainToolbarService, TOOLBAR_STATE } from '../main-toolbar.service';

@Component({
  selector: 'sem-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolbarComponent {
  public configService = inject(ConfigService);
  private mainToolbarService = inject(MainToolbarService);

  columnSettingsClicked() {
    if (this.mainToolbarService.currentState !== TOOLBAR_STATE.column) {
      this.mainToolbarService.currentState = TOOLBAR_STATE.column;
    } else {
      this.mainToolbarService.currentState = TOOLBAR_STATE.default;
    }
  }
}
