import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActionService } from '../action/action.service';
import { ActionModel } from '../action/models/ActionModel';
import { ItemModel } from '../item.model';
import { ChangesService } from './changes.service';

@Injectable()
export class SequenceService implements OnDestroy {
  sequence: (ItemModel[] | ActionModel[])[] = [];
  sequence$: BehaviorSubject<(ItemModel[] | ActionModel[])[]> = new BehaviorSubject(this.sequence);
  onDestroy$: Subject<void> = new Subject();

  constructor(
    private changesService: ChangesService,
    private actionService: ActionService,
  ) {
    this.actionService.globalActions$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.createSequence());

    this.changesService.toUpdate$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.createSequence());

    this.changesService.clearChanges$
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => (this.sequence = [])),
      )
      .subscribe(() => this.sequence$.next([]));
  }

  createSequence() {
    const res = {};
    [this.changesService.toUpdate, this.actionService.globalActions].forEach((change) => {
      change.forEach((element) => {
        // element = this.helperService.copyNoReference(element);
        if (element && res[element.sequence! as keyof typeof res]) {
          (res[element.sequence! as keyof typeof res] as unknown[]).push(element);
        } else {
          (res[element.sequence! as keyof typeof res] as unknown[]) = [element];
        }
      });
    });

    this.sequence = Object.values(res);
    this.sequence$.next(this.sequence);
  }

  sequenceWithGlobalAction(sequence: ActionModel[] | ItemModel[]): boolean {
    const param = 'global';
    if (sequence[0][param as keyof (typeof sequence)[number]]) {
      return true;
    }
    return false;
  }

  sequenceWithItemsToUpdate(sequence: ActionModel[] | ItemModel[]): boolean {
    const param = 'global';
    if (sequence[0][param as keyof (typeof sequence)[number]]) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
