<div
  class="container"
  [ngClass]="{ 'background-active': mainToolbarService.currentState !== possibleStates.default }"
  [ngSwitch]="mainToolbarService.currentState"
>
  <ng-container *ngSwitchCase="possibleStates.default">
    <sem-tabs-tool></sem-tabs-tool>
  </ng-container>
  <ng-container *ngSwitchCase="possibleStates.column">
    <sem-column-tool (closed)="disableColumnTool()"></sem-column-tool>
  </ng-container>
  <ng-container *ngSwitchCase="possibleStates.select">
    <sem-select-tool></sem-select-tool>
  </ng-container>
  <ng-container *ngSwitchDefault> unknown tool! </ng-container>
</div>
