<sem-filter-operator *ngIf="!isFirst" [operatorCtrl]="operatorCtrl"></sem-filter-operator>

<div class="container icon-button-density-minus-5">
  <sem-column-chooser-input [columnCtrl]="columnCtrl" [columns]="columns"></sem-column-chooser-input>

  <mat-form-field *ngIf="columnCtrl.value as column; else placeholder" appearance="outline">
    <mat-label>{{ 'sem_table.use_action' | translate }}:</mat-label>
    <mat-select [formControl]="filterTypeCtrl">
      <mat-option *ngFor="let type of column.filterAvailableTypes" [value]="type">{{ type | filterTypeAlias }} </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- TODO : Do naprawienia nie zaczytuje typów -->
  <sem-filter-form-switch
    (autocompleteChanged)="autocompleteChanged($event)"
    *ngIf="filterTypeCtrl.value; else placeholder"
    [column]="columnCtrl.value"
    [filterType]="filterTypeCtrl.value"
    [valueCtrl]="valueCtrl"
  ></sem-filter-form-switch>
  <button (click)="removeFilter()" class="remove-icon" mat-icon-button>
    <mat-icon>delete</mat-icon>
  </button>
</div>

<div *ngIf="columnCtrl.value?.dialog" class="container">
  <button
    (click)="openDialog(columnCtrl.value)"
    [ngClass]="{ 'no-valid': !additionalsCtrl!.valid }"
    class="secondary dialog-btn"
    mat-stroked-button
    type="button"
  >
    <mat-icon>add_box</mat-icon>

    <ng-container [ngSwitch]="additionalsCtrl!.value">
      <ng-container *ngSwitchCase="'connection_period'">
        {{ 'sem_table.additional_filters.connection_period' | translate | replace: additionalsCtrl!.value }}
      </ng-container>
      <ng-container *ngSwitchCase="'connection_range'">
        {{ 'sem_table.additional_filters.connection_range' | translate | replace: additionalsCtrl!.value }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="emptyAdditionals"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #emptyAdditionals>{{ 'sem_table.additional_filters.no_data' | translate }}</ng-template>
  </button>
</div>

<ng-template #placeholder>
  <div class="placeholder"></div>
</ng-template>

<div *ngIf="isArrayLike && valueDataCtrl!.value && valueDataCtrl!.value.length > 0" class="tw-mb-8">
  <mat-chip-listbox>
    <mat-chip-option (removed)="removeElementFromValue(value)" *ngFor="let value of valueDataCtrl!.value">
      {{ value }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-option>
  </mat-chip-listbox>

  <div class="tw-mt-6">
    <button (click)="clearAll()" class="clear-btn" mat-raised-button>
      <mat-icon>delete</mat-icon>
      {{ 'sem_table.clear_all' | translate }}
    </button>
  </div>
</div>
