<div class="container">
  <mat-form-field appearance="outline">
    <input
      #input
      (blur)="onBlur()"
      (keyup)="keyup($event)"
      (paste)="onPaste($event)"
      [formControl]="inputCtrl"
      [placeholder]="'sem_table.value' | translate | titlecase"
      matInput
    />
  </mat-form-field>
</div>
