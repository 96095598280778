<div class="container">
  <div class="btn-container">
    <button (click)="changesService.revertChange()" *ngIf="changesService.changeToRevert" class="revertBtn" color="primary" mat-mini-fab>
      <mat-icon class="icon">undo</mat-icon>
    </button>
    <button (click)="changesService.restoreChange()" *ngIf="changesService.changeToRestore" class="restoreBtn" color="primary" mat-mini-fab>
      <mat-icon class="icon">redo</mat-icon>
    </button>
  </div>
  <div class="btn-container">
    <button
      (click)="changesService.clearChanges$.next(); changesService.clearUpdates$.next(); actionService.clearActions$.next()"
      *ngIf="changesService.changeToRevert || changesService.changeToRestore"
      class="revertAllBtn"
      color="primary"
      mat-mini-fab
    >
      <mat-icon class="icon">clear</mat-icon>
    </button>
    <button (click)="update()" *ngIf="(sequenceService.sequence$ | async)!.length > 0" class="save-btn" color="primary" mat-fab>
      <mat-icon class="icon">save</mat-icon>
    </button>
  </div>
</div>
