import { ActionTypesEnum } from '../../../../action/ActionTypesEnum';
import { mainTableColumnDefaultGroup } from '../../../../enums';
import { FilterTypesEnum } from '../../../../filters/FilterTypesEnum';
import { CustomButtonPerItemInterface } from '../../../../models/CustomButton.model';
import { ToolInterface } from '../../../../models/TableConfigurationInterface.model';
import { ColumnTypesEnum, ColumnValueTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnModel } from '../column.model';

export class BooleanColumn extends ColumnModel {
  type = ColumnTypesEnum.BOOLEAN;
  filterAvailableTypes: FilterTypesEnum[] = [FilterTypesEnum.equal];
  actionsAvailableTypes = [ActionTypesEnum.override];
  valueType = ColumnValueTypesEnum.boolean;

  constructor(config: {
    ableToFilter?: boolean;
    alias?: string;
    canBeSorted?: boolean;
    customButton?: CustomButtonPerItemInterface[];
    editable?: ToolInterface;
    group?: string;
    param?: string;
    tooltip?: string;
    disabledIn?: {
      duplicatePopup?: boolean;
      editPopup?: boolean;
      addPopup?: boolean;
      usingAsFilterInMassOperations?: boolean;
    };
  }) {
    super();
    this.ableToFilter = config.ableToFilter;
    this.alias = config.alias || '';
    this.canBeSorted = config.canBeSorted;
    this.customButton = config.customButton;
    this.disabledIn = config.disabledIn;
    this.editable = config.editable;
    this.group = config.group || mainTableColumnDefaultGroup;
    this.param = config.param!;
    this.tooltip = config.tooltip;
    // this.actionsAvailableTypes = config.actionsAvailable ? config.actionsAvailable : this.actionsAvailableTypes;
  }
}
