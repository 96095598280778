import { of } from 'rxjs';
import { ActionTypesEnum } from '../../../../action/ActionTypesEnum';
import { mainTableColumnDefaultGroup } from '../../../../enums';
import { FilterTypesEnum } from '../../../../filters/FilterTypesEnum';
import { ItemModel } from '../../../../item.model';
import { CustomButtonPerItemInterface } from '../../../../models/CustomButton.model';
import { ToolInterface } from '../../../../models/TableConfigurationInterface.model';
import { AgregationInterface } from '../../../../models/agregation.model';
import { ColumnTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnModel } from '../column.model';

export class ImageColumn extends ColumnModel {
  type = ColumnTypesEnum.IMAGE;
  imageToDisplay: string;
  imageData?: string[];
  editInCustomLogic: boolean;

  constructor(config: {
    ableToFilter?: true;
    actionsAvailable?: ActionTypesEnum[];
    aggregate?: boolean;
    aggregateBySelect?: AgregationInterface;
    alias?: string;
    customButton?: CustomButtonPerItemInterface[];
    editInCustomLogic?: boolean;
    editable?: ToolInterface;
    filtersAvailable?: FilterTypesEnum[];
    group?: string;
    imageData?: string[];
    imageToDisplay: string;
    required?: boolean;
    tooltip?: string;
  }) {
    super();
    this.ableToFilter = config.ableToFilter;
    this.actionsAvailableTypes = config.actionsAvailable ? config.actionsAvailable : this.actionsAvailableTypes;
    this.aggregate = config.aggregate;
    this.aggregateBySelect = config.aggregateBySelect;
    this.alias = config.alias || '';
    this.customButton = config.customButton;
    this.editInCustomLogic = config.editInCustomLogic!;
    this.editable = config.editable;
    this.filterAvailableTypes = config.filtersAvailable!;
    this.group = config.group || mainTableColumnDefaultGroup;
    this.imageData = config.imageData;
    this.imageToDisplay = config.imageToDisplay;
    this.required = config.required!;
    this.tooltip = config.tooltip;
  }

  map(value: any): string {
    return value;
  }

  returnValue(item: ItemModel): string {
    return item[this.param as keyof typeof item] as string;
  }

  returnPossibleValues() {
    return of(null);
  }

  returnValueForDisplay(item: ItemModel): string {
    return item[this.param as keyof typeof item] as string;
  }
}
