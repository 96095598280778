<div class="container">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <div class="subtitle">{{ data.subtitle }}</div>
  <button class="close-button" mat-dialog-close mat-icon-button tabindex="-1">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <input [formControl]="name" cdkFocusInitial matInput placeholder="{{ 'sem_table.storage_name_of_set' | translate }}" />
        <app-form-field-error-display matError></app-form-field-error-display>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <mat-checkbox *ngIf="data.type === 'filters'" [formControl]="saveAsTabCtrl" name="saveAsTabCtrl"
      >{{ 'sem_table.save_as_tab' | translate | titlecase }}
    </mat-checkbox>
    <button (click)="cancel()" mat-button>{{ 'sem_table.cancel' | translate | titlecase }}</button>
    <button (click)="submit()" color="primary" mat-raised-button>{{ 'sem_table.save' | translate | titlecase }}</button>
  </mat-dialog-actions>
</div>
