import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { ActionColumnItemInterface, SelectedActionInterface } from './ActionColumn';

@Injectable()
export class ActionColumnService {
  actionsList$ = new Subject<ActionColumnItemInterface[] | null>();
  openActionsList$ = new Subject<string | number | null>();
  selectedAction$ = new Subject<SelectedActionInterface>();

  openActionsList(id: string | number) {
    this.actionsList$.next(null);
    this.openActionsList$.next(id);
  }

  selectedAction(id: number | string, action: ActionColumnItemInterface) {
    this.selectedAction$.next({ id, value: action.value || null!, item: action });
  }

  setActionsList(actionslist: ActionColumnItemInterface[]) {
    this.actionsList$.next(actionslist);
  }
}
