<div *ngIf="currentPackage.restrictions || !withoutAdditionalResources; else noItems" class="display-package-statistics">
  <div class="summary-restrictions" *ngIf="currentPackage.restrictions">
    <app-summarized-restrictions [currentPackage]="currentPackage"></app-summarized-restrictions>
  </div>

  <div class="summary-restrictions" *ngIf="currentPackage.restrictions && mainPackage" appPluginDisplayHandler>
    <app-summarized-restrictions [currentPackage]="currentPackage" [mainPackage]="mainPackage"></app-summarized-restrictions>
  </div>

  <div class="additional-items" *ngIf="!withoutAdditionalResources" appPluginDisplayHandler>
    <app-part-restrictions [partItems]="partItems"></app-part-restrictions>
  </div>
</div>

<ng-template #noItems>
  <div class="browse-btn-wrapper">
    <div>
      {{ 'payments.current_package.free_package' | translate }}
    </div>
    <button mat-raised-button color="primary" appRouter="paymentsPlan">
      {{ 'payments.current_package.choose_plan' | translate }}
    </button>
  </div>
</ng-template>
