import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ColumnObjectFieldTypesEnum } from '../../models/Mapping.model';
import { CustomEditorPopupDataInterface } from '../../models/modals/CustomEditorPopupDataInterface';

@Component({
  selector: 'sem-custom-editor',
  templateUrl: './custom-editor.component.html',
  styleUrls: ['./custom-editor.component.scss'],
})
export class CustomEditorComponent implements OnInit, OnDestroy {
  form!: UntypedFormGroup;
  fieldsInColumn!: string[];
  columnFieldTypes: typeof ColumnObjectFieldTypesEnum = ColumnObjectFieldTypesEnum;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<CustomEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomEditorPopupDataInterface,
  ) {}

  get formArray(): UntypedFormArray {
    return this.form.get('formArray') as UntypedFormArray;
  }

  ngOnInit() {
    this.fieldsInColumn = this.data.column!.valueMapping.returnFieldsKeys();
    this.form = new UntypedFormGroup({ formArray: new UntypedFormArray([]) });

    if (this.data.value) {
      this.data.value.forEach((data: any) => this.formArray.push(this.returnFormForObject(data)));
    } else {
      this.formArray.push(this.returnFormForObject(null));
    }
  }

  remove(index: number) {
    this.formArray.removeAt(index);
  }

  addNew() {
    this.formArray.push(this.returnFormForObject(null));
  }

  changeAutocomplete(columnName: string, value: any) {
    this.data.autocompleteService!.initAutocompleteChange$.next({
      columnName,
      value,
    });
  }

  submit() {
    if (this.form.valid) {
      this.dialogRef.close(this.formArray.value);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private returnFormForObject(object: {} | null): UntypedFormGroup {
    const group = new UntypedFormGroup({});

    this.fieldsInColumn.forEach((field) => {
      const validators = [];
      if (this.data.column!.valueMapping.getByKey(field).required) {
        validators.push(Validators.required);
      }
      const control = new UntypedFormControl(object ? object[field as keyof typeof object] : null, validators);

      if (this.data.column!.valueMapping.getByKey(field).type === this.columnFieldTypes.autocomplete) {
        control.valueChanges
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((value) => this.changeAutocomplete(`${this.data.column!.param}.${field}`, value));
      }

      group.addControl(field, control);
    });
    return group;
  }
}
