@if (column && action) {
  @switch (column.type) {
    <!-------------------------------            SIMPLE COLUMN               --------------------------------------------------->
    @case (columnTypes.SIMPLE) {
      <div class="value-action-container" @appearAnimation>
        @switch (action.type) {
          @case (actionTypes.generateValue) {
            <sem-value-generator [config]="config" (valueChanged)="valueGeneratorChanged($event)"> </sem-value-generator>
          }

          @case (actionTypes.modifyLink) {
            <sem-link-builder [action]="$any(action)" (valueChanged)="valueLinkChanged($event)"> </sem-link-builder>
          }

          @case (actionTypes.fixUppercase) {
            <div></div>
          }

          @case (actionTypes.addFoundFromField) {
            <sem-add-found-from-field
              [config]="config"
              [targetColumn]="config.columns[action.columnName]"
              (valueChanged)="valueAddFoundFromFieldChanged($event)"
            >
            </sem-add-found-from-field>
          }

          @case (actionTypes.addEnd) {
            <mat-form-field appearance="outline">
              <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
              <input [(ngModel)]="action.value" matInput name="value" placeholder="Value" required />
            </mat-form-field>
          }

          @case (actionTypes.addStart) {
            <mat-form-field appearance="outline">
              <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
              <input [(ngModel)]="action.value" matInput name="value" placeholder="Value" required />
            </mat-form-field>
          }

          @case (actionTypes.override) {
            <mat-form-field appearance="outline">
              <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
              <input
                [(ngModel)]="action.value"
                [type]="config.columns[action.columnName].valueType === columnValueTypes.number ? 'number' : 'text'"
                matInput
                trimSpaces
                name="value"
                placeholder="Value"
                required
              />
            </mat-form-field>
          }

          @case ([actionTypes.add, actionTypes.divide, actionTypes.multiply, actionTypes.subtract] | switchMultiCase: action.type) {
            <mat-form-field appearance="outline">
              <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
              <input [(ngModel)]="action.value" type="number" matInput trimSpaces name="value" placeholder="Value" required />
            </mat-form-field>
          }

          @case (actionTypes.setEmpty) {
            <div></div>
          }

          @case (actionTypes.replace) {
            <sem-replace [action]="$any(action)"></sem-replace>
          }

          @case (actionTypes.regexpReplace) {
            <sem-regexp-replace [action]="$any(action)"></sem-regexp-replace>
          }
        }
      </div>
    }

    <!-------------------------------            SELECT COLUMN               --------------------------------------------------->
    @case (columnTypes.SELECT) {
      <div class="value-action-container" @appearAnimation>
        @switch (action.type) {
          @case (actionTypes.override) {
            <mat-form-field appearance="outline" ngDefaultControl>
              <mat-select [(ngModel)]="action.value" (valueChanged)="setValueInAction($event)">
                @for (option of $any(column).possibleValues; track option) {
                  @if (option) {
                    <mat-option [value]="option">
                      {{ option | mapColumnValue: column }}
                    </mat-option>
                  }
                }
              </mat-select>
            </mat-form-field>
          }

          @case (actionTypes.setEmpty) {
            <div></div>
          }
        }
      </div>
    }

    <!-------------------------------            AUTOCOMPLETE COLUMN               --------------------------------------------------->
    @case (columnTypes.AUTOCOMPLETE) {
      <div class="value-action-container" @appearAnimation>
        @switch (action.type) {
          <!-- TODO: utworzyć input [autocompleteColumn]="column" -->
          <!--      [autocompleteColumn]="column"-->
          @case (actionTypes.generateValue) {
            <sem-value-generator
              [config]="config"
              [autocompleteService]="autocompleteService"
              (valueChanged)="valueGeneratorChanged($event)"
            >
            </sem-value-generator>
          }

          @case (actionTypes.addEnd) {
            <mat-form-field appearance="outline">
              <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
              <input [(ngModel)]="action.value" matInput trimSpaces name="value" placeholder="Value" required />
            </mat-form-field>
          }

          @case (actionTypes.addStart) {
            <mat-form-field appearance="outline">
              <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
              <input [(ngModel)]="action.value" matInput trimSpaces name="value" placeholder="Value" required />
            </mat-form-field>
          }

          @case (actionTypes.override) {
            <sem-autocomplete-column
              [column]="$any(column)"
              [value]="action.value"
              [displayEditable]="true"
              [formFieldAppearance]="'outline'"
              [placeholderForce]="'value'"
              (valueChanged)="setValueInAction($event)"
              (autocompleteChanged)="autocompleteChanged($event)"
              class="columnComponent"
            >
            </sem-autocomplete-column>
          }

          @case ([actionTypes.add, actionTypes.divide, actionTypes.multiply, actionTypes.subtract] | switchMultiCase: action.type) {
            <mat-form-field appearance="outline">
              <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
              <input [(ngModel)]="action.value" [appOnlyNumber]="true" matInput name="value" placeholder="Value" required />
            </mat-form-field>
          }

          @case (actionTypes.replace) {
            <sem-replace-autocomplete [action]="$any(action)" [column]="column" (autocompleteChanged)="autocompleteChanged($event)">
            </sem-replace-autocomplete>
          }

          @case (actionTypes.regexpReplace) {
            <sem-regexp-replace [action]="$any(action)"></sem-regexp-replace>
          }
        }
      </div>
    }

    <!-------------------------------            DATE COLUMN               --------------------------------------------------->
    @case (columnTypes.DATE) {
      <div class="value-action-container date" @appearAnimation>
        @if (action.type === actionTypes.override) {
          <sem-date-column
            [column]="$any(column)"
            [value]="null!"
            [displayOnlyEditable]="true"
            (valueChanged)="setValueInAction($event)"
            class="columnComponent"
          >
          </sem-date-column>
        }
      </div>
    }

    <!-------------------------------            CUSTOM COLUMN               --------------------------------------------------->
    @case (columnTypes.CUSTOM) {
      <div class="value-action-container custom-column" @appearAnimation>
        @if (action.type === actionTypes.override) {
          <div class="info">{{ 'sem_table.value' | translate }}:</div>

          <!-- TODO: utworzyć input i podpiąć popup [popupService]="popupService" -->
          <sem-custom-column
            [column]="$any(column)"
            [value]="action.value"
            [autocompleteService]="autocompleteService"
            [popupService]="popupService"
            (valueChanged)="setValueInAction($event)"
            class="columnComponent"
          >
          </sem-custom-column>
        }
      </div>
    }

    <!-------------------------------            BOOLEAN COLUMN               --------------------------------------------------->
    @case (columnTypes.BOOLEAN) {
      <div class="value-action-container boolean" @appearAnimation>
        <div class="info" (click)="slider.toggle()">{{ 'sem_table.value' | translate }}:</div>

        <mat-slide-toggle #slider [(ngModel)]="action.value" color="primary" name="value"> </mat-slide-toggle>
      </div>
    }
  }
}
