<div class="chart">
  @if (resultTypes.length) {
    <mat-form-field class="select">
      <mat-label>{{ 'projects.monitor.list.chart_results_type' | translate }}</mat-label>
      <mat-select (selectionChange)="updateCharts()" [(value)]="selected">
        @for (resultType of resultTypes; track resultType.value) {
          <mat-option [value]="resultType.value">
            {{ resultType.dropdownLabel ?? resultType.yAxisName }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  <div class="date-container">
    <div class="range-buttons">
      @for (range of getRangeEnum(); track $index) {
        <button (click)="updateOptions(range.value)" [class.active]="activeOptionButton === range.value" mat-button>
          {{ 'projects.monitor.date_range.' + range.value | translate }}
        </button>
      }
    </div>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'projects.monitor.range_datepicker.enter_range' | translate }}</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input
          class="fit-content"
          formControlName="start"
          matStartDate
          placeholder="{{ 'projects.monitor.range_datepicker.start_date' | translate }}"
        />
        <input
          class="fit-content"
          formControlName="end"
          matEndDate
          placeholder="{{ 'projects.monitor.range_datepicker.end_date' | translate }}"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>

  @if (!isLoading && resultTypes.length) {
    <app-apex-chart [chartOptions]="chartOptions" [type]="ChartType.line"></app-apex-chart>
  } @else if (!resultTypes.length) {
    <div class="tw-flex">
      <span class="tw-m-auto">{{ 'projects.monitor.results.no_enough_data' | translate }}</span>
    </div>
  } @else {
    <div class="tw-flex tw-h-4/5">
      <mat-spinner class="tw-m-auto" diameter="50"></mat-spinner>
    </div>
  }
</div>
