<div class="container" *ngIf="configService?.config">
  <div class="row sem-tool-displayer">
    <sem-tool-displayer></sem-tool-displayer>
  </div>

  <div class="row space-beetween">
    <sem-quick-filters></sem-quick-filters>

    <div class="btns-container">
      <ng-content></ng-content>
      <button (click)="columnSettingsClicked()" *ngIf="configService.config.tools?.column" class="column-tool-button" mat-stroked-button>
        <mat-icon>view_column</mat-icon>
        {{ 'sem_table.column_settings' | translate }}
      </button>
      <sem-custom-buttons></sem-custom-buttons>
    </div>
  </div>

  <div *ngIf="configService.config.tools?.filter || configService.config.initFilters" class="row">
    <sem-current-filters-displayer></sem-current-filters-displayer>
  </div>
</div>
