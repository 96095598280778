import { Component, Input, inject } from '@angular/core';
import { ActionColumnItemInterface } from './ActionColumn';
import { ActionColumnService } from './action-column.service';

@Component({
  selector: 'sem-action-column',
  templateUrl: './action-column.component.html',
  styleUrls: ['./action-column.component.scss'],
})
export class ActionColumnComponent {
  @Input() id!: number | string;

  isLoading = false;

  private readonly actionColumnService = inject(ActionColumnService);
  readonly actionsList$ = this.actionColumnService.actionsList$;

  openedChange(isOpen: boolean) {
    this.isLoading = false;
    this.actionColumnService.openActionsList(isOpen ? this.id : null!);
  }

  selectionChange(option: ActionColumnItemInterface) {
    this.isLoading = true;
    this.actionColumnService.selectedAction(this.id, option);
  }
}
