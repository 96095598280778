/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable, inject } from '@angular/core';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { NotificationService } from 'src/app/notification/notification.service';
import { SingleChangeInterface } from 'src/app/shared/sem-table/models/change.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService } from 'src/app/shared/service/router.service';
import { IntervalForm, TaskInterface } from './tasks.model';

@Injectable({
  providedIn: 'root',
})
export class TasksFormService {
  private readonly authService = inject(AuthService);
  private readonly notificationService = inject(NotificationService);
  private readonly routerService = inject(RouterService);

  catchSingleTaskChange(event: SingleChangeInterface): {
    changedData: Partial<TaskInterface>;
    revertSingleChange: Function;
    task: TaskInterface;
  } {
    const { columnName, item, changedData, newValue, oldValue } = event;
    item[columnName] = newValue;

    const task = cloneDeep(item) as TaskInterface;
    const revertSingleChange = () => {
      // @TODO: poprawić obsługe błędów...
      item[columnName] && (item[columnName] = oldValue);
      this.notificationService.error('edit_task'); // @TODO: w przyszłości można sprecyzować której kolumny błąd dotyczy
    };

    return {
      changedData,
      revertSingleChange,
      task,
    };
  }

  createTaskFormData(data: Partial<TaskInterface>): FormData {
    let formData: FormData = new FormData();

    // Basic interface fields
    if (data.assignee_ids) {
      if (data.assignee_ids.length === 0) {
        formData.append('assignee_ids', `${[]}`);
      } else {
        data.assignee_ids.forEach((assignee_id) => {
          formData.append('assignee_ids[]', assignee_id?.toString());
        });
      }
    }

    if (data.description !== undefined) formData.append('description', data.description || '');
    if (data.priority !== undefined) formData.append('priority', data.priority || '');
    if (data.project_id !== undefined) formData.append('project_id', data.project_id?.toString() || '');
    if (data.status !== undefined) formData.append('status', data.status || '');
    if (data.title) formData.append('title', data.title || '');
    if (data.order) formData.append('order', data.order?.toString() || '');

    // Extended interface fields (if present)
    if ('due_date' in data) {
      formData.append('due_date', data.due_date ? moment(data.due_date).format(moment.HTML5_FMT.DATE) : '');
    }
    if ('account_id' in data) {
      formData.append('account_id', data.account_id?.toString() || '');
    }
    if ('author_id' in data) {
      formData.append('author_id', data.author_id?.toString() || '');
    }
    if ('section_id' in data) {
      formData.append('section_id', data.section_id?.toString() || '');
    }
    if ('task_trigger_id' in data) {
      formData.append('task_trigger_id', data.task_trigger_id?.toString() || '');
    }
    if ('attachments_to_upload' in data && data.attachments_to_upload && data.attachments_to_upload.length) {
      data.attachments_to_upload.forEach((attachment: File) => {
        formData.append('attachments[]', attachment);
      });
    }
    if ('parent_id' in data) {
      formData.append('parent_id', data.parent_id?.toString() || '');
    }

    if ('interval' in data && data.interval) {
      formData = this.appendIntervalToFormData(formData, data.interval!);
    }

    return formData;
  }

  prepareDefaultData(data: Partial<TaskInterface>) {
    if (this.routerService.getCurrentRouteKey() === 'myTasksList') {
      const { id, superuser } = this.authService.authUser!;
      id && !superuser && (data.assignee_ids = [id]);
    }
  }

  private appendIntervalToFormData(formData: FormData, intervalForm: IntervalForm): FormData {
    const appendOccurrence = (parentKey: string, occurrence: any) => {
      Object.entries(occurrence).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          formData.append(`${parentKey}['${key}']`, value.toString());
        }
      });
    };

    Object.entries(intervalForm).forEach(([key, value]) => {
      const parentKey = `interval[${key}]`;

      if (key === 'occurrence' && value && typeof value === 'object' && !Array.isArray(value)) {
        appendOccurrence(parentKey, value);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${parentKey}[${index}]`, item.toString());
        });
      } else if (value !== undefined && value !== null) {
        formData.append(parentKey, value.toString());
      }
    });

    return formData;
  }
}
