import { Component, Input, OnInit } from '@angular/core';
import { appearAnimation } from '../../animation/tool';
import { DynamicValue } from '../../models/DynamicValue.model';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { AutocompleteEventModel } from '../../models/autocompleteEvent.model';
import { AutocompleteService } from '../../services/autocomplete.service';
import { PopupService } from '../../services/popup.service';
import { ColumnTypesEnum, ColumnValueTypesEnum } from '../../table-display/ColumnTypesEnum';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';
import { SelectColumn } from '../../table-display/columns-switch/columns/select-column/SelectColumn';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from '../models/ActionModel';
import { ModifyLinkActionPayload } from '../models/ModifyLinkAction';

@Component({
  selector: 'sem-action-switch',
  templateUrl: './action-switch.component.html',
  styleUrls: ['./action-switch.component.scss'],
  animations: [appearAnimation],
})
export class ActionSwitchComponent implements OnInit {
  @Input() column!: ColumnModel;
  @Input() selectColumn!: SelectColumn;
  @Input() action!: ActionModel;
  @Input() config!: TableConfigurationModel;
  @Input() autocompleteService!: AutocompleteService;
  @Input() initAction?: ActionModel;
  columnValueTypes = ColumnValueTypesEnum;
  columnTypes = ColumnTypesEnum;
  actionTypes: typeof ActionTypesEnum = ActionTypesEnum;
  valueGeneratorValues: DynamicValue[] = [];

  constructor(protected popupService: PopupService) {}

  ngOnInit(): void {
    this.selectColumn = this.column as SelectColumn;
    this.valueGeneratorValues = Array.isArray(this.action?.value) ? (this.action?.value ?? []) : [];
  }

  autocompleteChanged(changedEvent: AutocompleteEventModel) {
    this.autocompleteService.initAutocompleteChange$.next(changedEvent);
  }

  valueGeneratorChanged(value: any) {
    this.valueGeneratorValues.push(value);
    this.setValueInAction(this.valueGeneratorValues);
  }

  valueLinkChanged(value: ModifyLinkActionPayload) {
    this.action.setValue(value);
  }

  valueAddFoundFromFieldChanged(values: { target: string; source: string; values: string[] }) {
    this.setValueInAction(values);
  }

  removeAllDynamicValues() {
    this.valueGeneratorValues = [];
    this.setValueInAction([]);
  }

  setValueInAction(value: any) {
    this.action.setValue(value);
  }
}
