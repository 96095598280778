import { Pipe, PipeTransform, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserModel } from '../model/user.model';
import { ProjectService } from '../../dashboard/project/project.service';
import { TasksService } from '../../dashboard/tasks/tasks.service';

@Pipe({
  name: 'userIdsToEmails',
  standalone: true,
})
export class UserIdsToEmailsPipe implements PipeTransform {
  private tasksService = inject(TasksService);
  private projectService = inject(ProjectService);

  transform(userIds: number[]): Observable<string[]> {
    if (!userIds) {
      return of(['Unknown']);
    }

    return this.projectService.activeProject$.pipe(
      switchMap((project) => {
        if (!project?.id) {
          return of();
        }

        // @TODO: tu raczej trzeba obsłużyć searcha (null) do autocomplete, ponieważ inaczej pobierze nam tylko pierwszych 50ciu userów
        return this.tasksService.getContributors(null, project.id).pipe(
          map((contributors: UserModel[]) => {
            return userIds.map((id) => {
              const user = contributors.find((u) => u.id === id);
              return user?.email || 'Unknown';
            });
          }),
          catchError(() => of(['Unknown'])),
        );
      }),
    );
  }
}
