import { TemplateRef, signal } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ItemModel } from '../item.model';

export interface ExpandModel {
  event: MouseEvent;
  item: ItemModel;
}

export abstract class ExpansionRowService {
  private readonly expansionSubject = new Subject<ExpandModel>();
  private readonly expansionStateChangedSubject = new Subject<void>();
  private readonly expandedRowExtraTemplatesState = signal<TemplateRef<unknown>[]>([]);

  readonly expansion$ = this.expansionSubject.asObservable();
  readonly expansionStateChanged$ = this.expansionStateChangedSubject.asObservable();
  readonly expandedRowExtraTemplates = this.expandedRowExtraTemplatesState.asReadonly();

  expansionStateChanged() {
    this.expansionStateChangedSubject.next();
  }

  expand(expandModel: ExpandModel) {
    this.expansionSubject.next(expandModel);
  }

  setRowExtraTemplates(templates: TemplateRef<unknown>[]) {
    this.expandedRowExtraTemplatesState.set(templates);
  }

  abstract getExpandedItems(itemId: number): Observable<unknown[]>;
  abstract canExpand(item: ItemModel): boolean;
  abstract isExpanded(item: ItemModel): boolean;
  abstract getDepth(item: ItemModel): number;
}
