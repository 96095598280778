import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomEditorPopupDataInterface } from '../../models/modals/CustomEditorPopupDataInterface';

@Component({
  selector: 'sem-custom-display',
  templateUrl: './custom-display.component.html',
  styleUrls: ['./custom-display.component.scss'],
})
export class CustomDisplayComponent implements OnInit {
  fields!: string[];

  constructor(
    public dialogRef: MatDialogRef<CustomDisplayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomEditorPopupDataInterface,
  ) {}

  ngOnInit() {
    this.fields = this.data.column!.valueMapping.returnFieldsKeys();
  }
}
