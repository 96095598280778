import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AutocompleteEventModel } from '../../../../models/autocompleteEvent.model';
import { ColumnsComponentInterface } from '../editable-column.component';
import { AutocompleteColumn } from './AutocompleteColumn';

@Component({
  selector: 'sem-autocomplete-column',
  templateUrl: './autocomplete-column.component.html',
  styleUrls: ['./autocomplete-column.component.scss', '../shared-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteColumnComponent implements ColumnsComponentInterface, OnInit {
  @Input() column!: AutocompleteColumn;
  @Input() disabled!: boolean;
  @Input() displayEditable!: boolean;
  @Input() displayNotEditable!: boolean;
  @Input() formFieldAppearance!: 'fill' | 'outline';
  @Input() placeholderForce!: string;
  @Input() value: any;
  @Input() editableMode!: boolean;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() autocompleteChanged: EventEmitter<AutocompleteEventModel> = new EventEmitter();
  @ViewChild('input') input!: ElementRef;
  @ViewChild('trigger') triggereRef!: MatAutocompleteTrigger;
  preventEnterEvent = false;

  editState = false;
  @Output() editStateEntered: EventEmitter<null> = new EventEmitter();
  @Output() editStateExited: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly eRef: ElementRef) {}

  @HostListener('keydown', ['$event']) keydown(event: KeyboardEvent): void {
    event.stopPropagation();
    if (event.key === 'Tab') {
      event.preventDefault();
      this.exitEditableState();
    }
    if (event.key === 'Escape' || event.keyCode === ENTER) {
      this.exitEditableState();
    }
  }

  @HostListener('dblclick', ['$event'])
  dbClick(event: any): void {
    event.stopPropagation();
    if ((this.column.editable && !this.displayNotEditable) || this.displayEditable) {
      this.enterEditableMode();
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent): void {
    if (this.editState && !this.eRef.nativeElement.contains(event.target)) {
      this.exitEditableState(false);
    }
  }

  ngOnInit(): void {
    this.editState = this.displayEditable;
    this.value = this.value || null;
  }

  enterEditableMode(): void {
    if (!this.column.editable || this.displayNotEditable) {
      return;
    }

    this.editStateEntered.emit();
    setTimeout(() => this.input.nativeElement.focus(), 10);
    this.editState = true;
  }

  exitEditableState(shouldFocusAfterLeave = true): void {
    if (this.displayEditable) {
      return;
    }
    this.triggereRef.closePanel();
    this.editStateExited.emit(shouldFocusAfterLeave);
    this.editState = false;
  }

  displayFn(value: any): string {
    const column = this as unknown as AutocompleteColumn;
    return column.map(value);
  }

  autocompleteKeyUp(event: KeyboardEvent, value: any): void {
    if (event.code !== 'Enter') {
      this.autocompleteChanged.emit({ columnName: this.column.param, value });
    }
  }

  autocompleteblur(newValue: unknown, event: any): void {
    let beforeValueMapped = this.column.map(this.value);
    const newValueMapped = this.column.map(newValue);
    event.target.blur();

    // --------------------------       JEŻELI MUSI BYĆ KLIKNIETA WARTOSC to sprawdz czy ktoś
    // --------------------------- nie wyczyścił pola albo wyzeruj jeżeli nei było klikniętej zmiany tylko cos z palca
    if (this.column.onlyFromAutocomplete) {
      if (!newValue && beforeValueMapped) {
        event.target.value = '';
        this.exitEditableState();
        this.valueChanged.emit(newValue);
      } else if (!newValueMapped) {
        event.target.value = beforeValueMapped;
      }
    } else {
      // --------------------------       JEŻELI NIE MUSI BYĆ KLIKNIETA WARTOSC to emituj zmiane wartosci ------------------
      beforeValueMapped = beforeValueMapped || '';
      if (beforeValueMapped !== newValueMapped && (!event.relatedTarget || event.relatedTarget.tagName !== 'MAT-OPTION')) {
        this.exitEditableState();
        this.valueChanged.emit(newValue);
      }
    }
  }

  autocompleteClickInOption(event: MatAutocompleteSelectedEvent): void {
    const beforeValueMapped = this.column.map(this.value);
    const newValue = event.option.value;
    const newValueMapped = this.column.map(newValue);
    // console.log('kliknięcie w opcje');
    this.preventEnterEvent = true;

    // --------------------------       USTAW JEŻELI MUSI BYĆ KLIKNIETA WARTOSC    ----------------------------------------------------
    if (beforeValueMapped !== newValueMapped) {
      this.valueChanged.emit(newValue);
    }
  }

  click(): void {
    throw new Error('Method not implemented.');
  }
}
