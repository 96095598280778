import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnValueTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnsComponentInterface } from '../editable-column.component';
import { SimpleColumn } from './SimpleColumn';

@Component({
  selector: 'sem-simple-column',
  templateUrl: 'simple-column.component.html',
  styleUrls: ['./simple-column.component.scss', '../shared-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleColumnComponent implements OnInit, OnDestroy, ColumnsComponentInterface {
  isFullContent = false;
  valueCatchedOnFocus: any;
  valueMaxLength = 50;
  valueLength = 0;
  valueTypes = ColumnValueTypesEnum;
  @Input() column!: SimpleColumn;
  @Input() displayNotEditable!: boolean;
  editState!: boolean;
  @Output() editStateEntered: EventEmitter<null> = new EventEmitter();
  @Output() editStateExited: EventEmitter<any> = new EventEmitter();
  @Output() navigationColumn: EventEmitter<SimpleColumn> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('input') input!: ElementRef;
  // TODO HTML input -> [disabled]="disabled"
  @Input() disabled: boolean = false;
  private onDestroy$ = new Subject<void>();

  constructor(private cd: ChangeDetectorRef) {}

  private _value!: string;

  get value(): any {
    return typeof this._value === 'undefined' || this._value === null ? '' : this._value.toString();
  }

  @Input() set value(value: any) {
    this._value = value;
  }

  @HostListener('keydown', ['$event']) keydown(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Escape' || (event.keyCode === ENTER && !event.shiftKey)) {
      this.exitEditableState();
    }
  }

  @HostListener('dblclick', ['$event']) dbClick(event: any) {
    event.stopPropagation();
    this.enterEditableMode();
  }

  ngOnInit() {
    this.valueLength = this.value ? this.value.length : 0;
    this.column.stringTruncateLimit && (this.valueMaxLength = this.column.stringTruncateLimit);
    this.value = this.value === 0 ? this.value.toString() : this.value; // To display "0" values
  }

  click(): void {}

  showFullText() {
    this.isFullContent = !this.isFullContent;
  }

  enterEditableMode() {
    if (!this.column.editable || this.displayNotEditable) {
      this.showFullText();
      return;
    }
    this.editStateEntered.emit();
    this.editState = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.valueCatchedOnFocus = this.input.nativeElement.value;
    }, 10);
  }

  exitEditableState() {
    this.checkIfValueWasChanged();
    this.editStateExited.emit();
    this.editState = false;
  }

  onPaste() {
    if (!this.column.lettersLimit) {
      return;
    }
    // event.preventDefault();
    // this.input.nativeElement.innerText = event.clipboardData.getData('Text').substring(0, this.column.lettersLimit);
    this.calculateValueLength();
  }

  calculateValueLength() {
    if (!this.column.lettersLimit) {
      return;
    }
    this.valueLength = this.input.nativeElement.value.length;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private checkIfValueWasChanged() {
    let currentValue = this.input.nativeElement.value;
    if (currentValue === null) {
      currentValue = '';
    }

    // eslint-disable-next-line
    if (currentValue != this.valueCatchedOnFocus) {
      this.valueChanged.emit(currentValue);
      this.valueCatchedOnFocus = null;
    }
  }
}
