import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Params } from '@angular/router';
import { get } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RouterConfigKey } from 'src/app/configs/router.config';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import sideBarGroupItems, { routeKeys } from '../../dashboard/side-bar/side-bar-items';
import { UrlVariablesType } from '../model/helpers.model';
import { RouteInterface } from '../model/router-config.model';
import { NavigateParams, RouterService, routerConfig } from '../service/router.service';

// Nie trzeba już przekazywać id projektu w `urlVars` jeśli jest to projekt aktualnie wybrany (w navigate zostanie dopisany jako domyślny)
@Directive({
  selector: '[appRouter]',
  standalone: true,
})
export class RouterDirective implements AfterViewInit, OnDestroy {
  @Input('appRouter') routeKey!: RouterConfigKey;
  @Input() childRouteKeys!: RouterConfigKey[];
  @Input() queryParams!: Params;
  @Input() routeActiveClass!: string;
  @Input() urlVars!: UrlVariablesType;

  private onDestroy$ = new Subject<void>();
  private routeConfig!: RouteInterface;

  @HostListener('click', ['$event']) onClick(event: PointerEvent) {
    event.preventDefault();
    this.navigate(false);
  }

  @HostListener('auxclick', ['$event']) onClick2(event: PointerEvent) {
    event.preventDefault();
    if (event.which === 2) {
      this.navigate(true);
    }
  }

  constructor(
    private el: ElementRef,
    private projectService: ProjectService,
    private renderer: Renderer2,
    private routerService: RouterService,
  ) {}

  ngAfterViewInit() {
    const el = this.el.nativeElement;

    const routeActiveClass = (route: RouteInterface) => {
      if (this.routeActiveClass) {
        const { areVariable, urlMask } = route;

        this.checkIfRouteActive(urlMask!, areVariable!, route.key!);

        this.routerService.routerEvents$
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((event) => event && this.checkIfRouteActive(urlMask!, areVariable!, route.key!));
      }
    };

    if (routerConfig[this.routeKey] && routerConfig[this.routeKey].url) {
      this.routeConfig = routerConfig[this.routeKey];

      if (
        this.routeConfig.permissions &&
        !this.routerService.appHasPermissions(this.routeConfig.permissions, this.routeConfig.permissionsOperator || null!)
      ) {
        this.removeElement(el);
      } else {
        routeActiveClass(routerConfig[this.routeKey]);
      }
    } else if (this.childRouteKeys && this.childRouteKeys.length) {
      this.childRouteKeys.forEach((childKey) => {
        routerConfig[childKey] && routeActiveClass(routerConfig[childKey]);
      });
    } else if (this.routeKey !== null) {
      this.removeElement(el);
    }
  }

  private navigate(inNewTab: boolean = false) {
    const queryParams = this.queryParams || null;
    const urlVars = this.urlVars || {};
    const paramsForNavigation: NavigateParams = { queryParams };

    if (inNewTab) {
      paramsForNavigation.windowName = '_blank';
    }

    // Jeśli nie podano ID projectu to przypisujemy jako domyślny aktualny projekt
    if (!urlVars.project) {
      const currentProjectId = this.projectService.activeProject$.getValue()?.id || null;
      currentProjectId && (urlVars.project = currentProjectId);
    }

    this.routerService.navigate(this.routeConfig, urlVars, paramsForNavigation);
  }

  private removeElement(el: HTMLElement) {
    // Remove element if it doesn't have permissions
    if (el) {
      el.style.display = 'none';
      el.remove();
    }
  }

  // Something like RouterLinkActive: Tracks whether the linked route of an element is currently active,
  // and allows you to specify one or more CSS classes to add to the element when the linked route is active.
  private checkIfRouteActive(routeConfigUrlMask: string, areVariable: boolean, routeKey: string) {
    const el = this.el.nativeElement;

    if (this.routerService.isRouteActive(routeConfigUrlMask, areVariable)) {
      if (routeKeys[routeKey as keyof typeof routeKeys]) {
        const currentItem = get(sideBarGroupItems, routeKeys[routeKey as keyof typeof routeKeys]);
        currentItem && (currentItem.isExpanded = true);
      }

      if (!el.classList.contains(this.routeActiveClass)) {
        this.renderer.addClass(el, this.routeActiveClass);

        // if (this.childRouteKeys && el.getAttribute('aria-expanded') !== 'true') { // Używane przy poprzednim sidebarze
        //   el.click();
        // }
      }
    } else {
      !this.childRouteKeys && this.renderer.removeClass(el, this.routeActiveClass);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
