import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnsService } from '../../../services/columns.service';
import { ConfigService } from '../../../services/config.service';
import { PopupService } from '../../../services/popup.service';
import { ColumnsSetModel, ColumnsStorageSetModel } from '../../../table-display/columns-switch/columns/column.model';

@Component({
  selector: 'sem-column-tool',
  templateUrl: './column-tool.component.html',
  styleUrls: ['./column-tool.component.scss'],
})
export class ColumnToolComponent implements OnDestroy {
  @Output() closed = new EventEmitter();
  private onDestroy$ = new Subject<void>();

  constructor(
    private popupService: PopupService,
    public columnsService: ColumnsService,
    public configService: ConfigService,
  ) {}

  addColumn(column: string) {
    this.columnsService.addColumn(column);
  }

  removeColumn(column: string) {
    this.columnsService.removeColumn(column);
  }

  useColumnSet(columnSet: ColumnsSetModel | ColumnsStorageSetModel) {
    this.columnsService.setActiveStorage(columnSet);
  }

  deleteColumnSet(columnSet: ColumnsSetModel | ColumnsStorageSetModel, event: any) {
    event.stopPropagation();
    this.columnsService.deleteColumnFromStorage(columnSet);
  }

  openSaveAs(): void {
    this.popupService
      .openStorageSaveAsPopup('column')
      .subscribe((name) => this.columnsService.saveColumnsToStorage(name, this.columnsService.columnChosenToDisplay));
  }

  drop(event: CdkDragDrop<any, any, any>) {
    this.columnsService.changeColumnsOrder(event.currentIndex, event.previousIndex);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
