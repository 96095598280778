import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LocalStorageService, storageKey } from 'src/app/shared/service/local-storage.service';

@Component({
  selector: 'app-manual-changes-warning',
  templateUrl: './manual-changes-warning.component.html',
  standalone: true,
  styleUrls: ['./manual-changes-warning.component.scss'],
  imports: [MatButtonModule, MatDividerModule, MatIconModule, MatCheckboxModule, MatDialogModule, TranslateModule],
})
export class ManualChangesWarningComponent {
  readonly localStorageService = inject(LocalStorageService);

  toggleShow(event: MatCheckboxChange) {
    this.localStorageService.save(storageKey.manualChangesWarningSkip, JSON.stringify(event.checked));
  }
}
