import { Component, Input } from '@angular/core';
import { TableConfigurationInterface } from '../../models/TableConfigurationInterface.model';
import { ColumnValueTypesEnum } from '../../table-display/ColumnTypesEnum';
import { FilterLogicOperatorEnum } from '../FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../FilterTypesEnum';
import { FiltersModel } from '../models/filter.model';

@Component({
  selector: 'sem-filter-groups-displayer',
  templateUrl: './filter-groups-displayer.component.html',
  styleUrls: ['./filter-groups-displayer.component.scss'],
})
export class FilterGroupsDisplayerComponent {
  operators = FilterLogicOperatorEnum;
  symbols = FilterTypesEnum;
  columnValueTypes = ColumnValueTypesEnum;
  filterTypes = FilterTypesEnum;
  @Input() filters: FiltersModel = { filterGroups: [], filterManualChanges: false };
  @Input() cursorDefault = false;
  @Input() config!: TableConfigurationInterface;
  @Input() color = '#8C9196';
  @Input() fontSize = 11;
  @Input() numberOfLetters = 10;
}
