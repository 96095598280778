import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionService } from '../../../action/action.service';
import { FilterService } from '../../../filters/filters.service';
import { ItemModel } from '../../../item.model';
import { CustomButtonInterface } from '../../../models/CustomButton.model';
import { CommunicationService } from '../../../services/communication.service';
import { ConfigService } from '../../../services/config.service';
import { PopupService } from '../../../services/popup.service';
import { SelectedService } from '../../../services/selected.service';
import { PaginationService } from '../../../table-display/pagination/pagination.service';
import { SelectToolMenuItemsProvider } from './select-tool-menu-items-provider';

@Component({
  selector: 'sem-select-tool',
  templateUrl: './select-tool.component.html',
  styleUrls: ['./select-tool.component.scss'],
})
export class SelectToolComponent implements OnInit {
  public configService = inject(ConfigService);
  mainToolbarButtons = this.configService.config.mainToolbarSlot;
  public selectService = inject(SelectedService);
  private filterService = inject(FilterService);
  private actionService = inject(ActionService);
  private popupService = inject(PopupService);
  private communicationService = inject(CommunicationService);
  private paginationService = inject(PaginationService);
  itemsCounter$: Observable<number> = this.paginationService.page$.pipe(map((page) => page.totalItemsCount));

  readonly selectToolMenuItemsProvider = inject(SelectToolMenuItemsProvider, { optional: true });
  readonly menuItems$ = this.selectToolMenuItemsProvider?.items$;

  openActionPopup(): void {
    this.popupService.openActionPopup().subscribe((data) => {
      const { action, isAction } = data;
      if (isAction) {
        this.actionService.actionEmitted$.next(action);
      }
    });
  }

  ngOnInit() {
    this.mainToolbarButtons = this.configService.config.mainToolbarSlot!.filter((button) => button.actionType === 'old_products');
  }

  hasInactiveSelected(): boolean {
    const { inactiveStateDependencyColumn } = this.configService.config;
    if (!inactiveStateDependencyColumn) return false;
    return this.selectService.selectedItems.some((item) => item[inactiveStateDependencyColumn as keyof ItemModel]);
  }

  multiDuplicateSelected() {
    if (this.selectService.isSelectedGlobally && !this.filterService.checkIfFiltersAreAllowedInMassOperations()) {
      this.communicationService.customMessage$.next({ type: 'error', case: 'DISABLED_IN_MASS_OPERATIONS' });
      return;
    }
    this.communicationService.initMultiDuplicate$.next(this.selectService.selectedItems);
    this.selectService.unselectAll();
  }

  customButtonClicked(customButton: CustomButtonInterface) {
    this.communicationService.customButtonClicked$.next({
      type: customButton.actionType,
      data: this.selectService.selectedItems,
    });
  }

  customButtonGloballyClicked(customButton: CustomButtonInterface) {
    if (this.selectService.isSelectedGlobally && !this.filterService.checkIfFiltersAreAllowedInMassOperations()) {
      this.communicationService.customMessage$.next({ type: 'error', case: 'DISABLED_IN_MASS_OPERATIONS' });
      return;
    }
    this.communicationService.customButtonClicked$.next({
      type: customButton.actionType,
      data: this.filterService.groups,
    });
  }

  removeData() {
    if (this.selectService.isSelectedGlobally) {
      if (this.selectService.isSelectedGlobally && !this.filterService.checkIfFiltersAreAllowedInMassOperations()) {
        this.communicationService.customMessage$.next({ type: 'error', case: 'DISABLED_IN_MASS_OPERATIONS' });
        return;
      }
      this.communicationService.initRemoveByFilters$.next(this.filterService.groups);
    } else {
      this.communicationService.initRemove$.next(this.selectService.selectedItems);
    }
    this.selectService.unselectAll();
  }

  hasOwnCopyProperty(item: any): item is { copy: boolean } {
    return 'copy' in item;
  }

  hasCopySelected(): boolean {
    const { selectedItems } = this.selectService;
    return selectedItems && selectedItems.some((item: ItemModel) => this.hasOwnCopyProperty(item) && item.copy === true);
  }
}
