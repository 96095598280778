import { Component, Input, OnInit } from '@angular/core';
import { ChartApexOptions } from '../../apex-chart/apex-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MonitorOrganicTaskModel } from 'src/app/shared/model/monitor.model';
import { DEFAULT_APEX_CHART_CONFIG } from 'src/app/shared/sem-chart/configuration/default-apex-chart-config';
import { merge } from 'lodash';
import { ChartType } from 'src/app/shared/sem-chart/configuration/sem-chart-config';

@Component({
  selector: 'app-pill-chart',
  templateUrl: './pill-chart.component.html',
  standalone: true,
  imports: [NgApexchartsModule],
})
export class PillChartComponent implements OnInit {
  @Input() set task(task: MonitorOrganicTaskModel) {
    if (task.position_history) {
      this.dataset = task.position_history!.map((item) => ({
        x: item.run_hour,
        y: item.rank_group,
      }));
    }
  }
  chartOptions: Partial<ChartApexOptions> = {};
  dataset: { x: string; y: number }[] = [];

  ngOnInit() {
    this.chartOptions = merge({}, DEFAULT_APEX_CHART_CONFIG, {
      series: [
        {
          data: this.dataset,
        },
      ],
      chart: {
        type: ChartType.area,
        width: 100,
        height: 30,
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        events: {
          click: (event: any) => {
            event.stopPropagation();
          },
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      fill: {
        opacity: 0.3,
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return '';
            },
          },
          formatter: function (value: number, { seriesIndex, dataPointIndex, w }: { seriesIndex: number; dataPointIndex: number; w: any }) {
            const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return `${data.x}: ${value}`;
          },
        },
        marker: {
          show: false,
        },
      },
    });
  }
}
