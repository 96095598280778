<ng-container *ngTemplateOutlet="editState ? editable : notEditable"></ng-container>

<ng-template #editable>
  <mat-form-field class="{{ column.param }}-column-display">
    <mat-select [value]="actualValue" #reference [disabled]="disabled">
      <mat-option [value]="true"> {{ 'Yes' }} </mat-option>
      <mat-option [value]="false"> {{ 'No' }} </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #notEditable>
  <span class="value" [ngClass]="{ 'not-editable': displayNotEditable }" #input>{{ actualValue ? 'Yes' : 'No' }}</span>
</ng-template>
