import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './auto-focus.directive';
import { OutsideClickDirective } from './click-outside.directive';
import { HasPermissionDirective } from './has-permission.directive';
import { LimmitLetterDirective } from './limit-letters.directive';
import { OnlyNumberDirective } from './number-only.directive';
import { OnlyArabicDirective } from './only-arabic.directive';
import { PluginDisplayHandlerDirective } from './plugin-display-handler.directive';
import { ReplaceCommaDirective } from './replace-comma.directive';
import { RouterDirective } from './router.directive';
import { SpinnerModule } from './spinner.directive';
import { TemplateWithIdDirective } from './template-with-id.directive';
import { VerticalScrollableDirective } from './vertical-scrollable.directive';
import { WhiteLabelDirective } from './white-label.directive';

@NgModule({
  declarations: [
    AutoFocusDirective,
    HasPermissionDirective,
    LimmitLetterDirective,
    OnlyArabicDirective,
    OnlyNumberDirective,
    OutsideClickDirective,
    PluginDisplayHandlerDirective,
    ReplaceCommaDirective,
    TemplateWithIdDirective,
    VerticalScrollableDirective,
    WhiteLabelDirective,
  ],
  imports: [CommonModule, SpinnerModule, RouterDirective],
  exports: [
    AutoFocusDirective,
    HasPermissionDirective,
    LimmitLetterDirective,
    OnlyArabicDirective,
    OnlyNumberDirective,
    OutsideClickDirective,
    PluginDisplayHandlerDirective,
    ReplaceCommaDirective,
    RouterDirective,
    SpinnerModule,
    TemplateWithIdDirective,
    VerticalScrollableDirective,
    WhiteLabelDirective,
  ],
})
export class DirectivesModule {}
