import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ColumnsComponentInterface } from '../editable-column.component';
import { SelectColumn } from '../select-column/SelectColumn';

@Component({
  selector: 'sem-image-column',
  templateUrl: './image-column.component.html',
  styleUrls: ['./image-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageColumnComponent implements ColumnsComponentInterface {
  @Input() column!: SelectColumn;
  @Input() value: any;
  @Input() displayNotEditable!: boolean;
  @Output() editStateExited: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() openPhotoFromColumn: EventEmitter<string> = new EventEmitter();
  @ViewChild('reference', { read: ElementRef }) input!: ElementRef;

  @HostListener('keydown', ['$event']) keydown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  @HostListener('dblclick', ['$event']) dbClick() {
    this.enterEditableMode();
  }

  exitEditableState(): void {
    this.editStateExited.emit();
  }

  enterEditableMode() {
    // if (!this.column.editable || this.displayNotEditable) {
    //   return;
    // }
    this.input.nativeElement.focus();
    this.click();
  }

  click() {
    this.openPhotoFromColumn.emit(this.column.param);
  }
}
