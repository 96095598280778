import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { appearAnimation } from '../../../animation/tool';

@Component({
  selector: 'sem-case-size-config',
  templateUrl: './case-size-config.component.html',
  styleUrls: ['./case-size-config.component.scss'],
  animations: [appearAnimation],
})
export class CaseSizeConfigComponent {
  @Input() caseCtrl!: UntypedFormControl;
}
