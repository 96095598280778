<h1 mat-dialog-title>Action</h1>
<button class="close-button" mat-dialog-close mat-icon-button tabindex="-1">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
  <sem-action-form
    (actionCreated)="submitValidForm($event)"
    [actionService]="data.actionService"
    [autocompleteService]="data.autocompleteService"
    [config]="data.config"
    [filterService]="data.filterService"
    [selectService]="data.selectService"
    [state]="data.actionPopupState"
  >
  </sem-action-form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    (click)="initSubmit(false, true)"
    *ngIf="data.actionPopupState.useAction"
    class="submitActionBtn"
    color="primary"
    mat-raised-button
    type="button"
  >
    {{ 'sem_table.save' | translate }}
  </button>

  <button
    (click)="initSubmit(true, false)"
    *ngIf="data.actionPopupState.saveRule"
    class="submitActionBtn"
    color="primary"
    mat-raised-button
    type="button"
  >
    {{ 'sem_table.use' | translate }}
  </button>
  <button
    (click)="initSubmit(true, true)"
    *ngIf="data.actionPopupState.saveAndUse"
    class="submitActionBtn"
    color="primary"
    mat-raised-button
    type="button"
  >
    {{ 'sem_table.save_and_use' | translate }}
  </button>
</mat-dialog-actions>
