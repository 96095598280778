import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { first } from 'rxjs/operators';
import { ColumnsComponentInterface } from '../editable-column.component';
import { DateColumn } from './DateColumn';

@Component({
  selector: 'sem-date-column',
  templateUrl: './date-column.component.html',
  styleUrls: ['./date-column.component.scss', '../shared-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateColumnComponent implements ColumnsComponentInterface, OnInit {
  editState = false;
  nameAttribute!: string;
  date!: string;
  @Input() disabled!: boolean;
  @Output() editStateExited: EventEmitter<null> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('input') input!: ElementRef;
  @ViewChild('dt1') datePicker!: OwlDateTimeComponent<any>;
  @Input() displayNotEditable!: boolean;
  @Input() displayOnlyEditable!: boolean;
  @Input() column!: DateColumn;

  private readonly cdRef = inject(ChangeDetectorRef);

  @Input() set value(val: Date | string) {
    this.date = this.column.map(val);
  }

  @HostListener('dblclick', ['$event']) dbClick(event: any) {
    event.stopPropagation();
    this.enterEditableMode();
  }

  @HostListener('keydown', ['$event']) keydown(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.exitEditableState();
    }
    if (event.key === 'Enter') {
      // this.input.toggle();
      // this.input.toggleChange.emit();
    }
  }

  ngOnInit(): void {
    this.editState = !!this.displayOnlyEditable;
    this.updateNameAttribute();
  }

  exitEditableState(): void {
    this.editState = false;
    this.cdRef.detectChanges();
    this.editStateExited.emit();
  }

  enterEditableMode() {
    if (!this.column.editable) {
      return;
    }
    this.editState = true;
    this.cdRef.detectChanges();
    this.input.nativeElement.click();
    this.datePicker.afterPickerClosed.pipe(first()).subscribe(() => this.exitEditableState());
  }

  click() {}

  setDateValue(date: { value: Date }) {
    this.valueChanged.emit(date.value);
    this.exitEditableState();
  }

  //TODO zmiana date do stringa i wyswietlanie w inputie -> [name]="nameAttribute"
  protected updateNameAttribute(): void {
    if (this.date) {
      this.nameAttribute = `dateInput-${this.date}`;
    }
  }
}
